import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { FacilityService } from 'src/app/@core/services/facility.service';
import { PrescriptionManagerService } from 'src/app/@core/services/prescription-manager.service';
import { CommonService } from 'src/app/@core/utils';

@Component({
  selector: 'ngx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  year = new Date().getFullYear();
  facilityChangesSubscription: Subscription;
  facilityLogo: string = '';
  hasEnCulture: boolean;

  constructor(private commonService: CommonService, private facilityService: FacilityService) {
    // change Facility
    this.facilityChangesSubscription = this.commonService.facilityChanges.subscribe((response) => {
      if (response)
        this.getFacilityLogo();
    });
    this.hasEnCulture = this.commonService.getLanguage() === 'en';
  }
  
  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.facilityChangesSubscription.unsubscribe();
  }

  getFacilityLogo() {
    this.facilityService.getFacilityLogo().subscribe(res => {
      this.facilityLogo = res;
    })
  }
}
