import { Observable } from "rxjs";

export interface MusicAlbum {
    id: number;
    category: string;
    facilityId: number
    thumbnail: string
    title: string;
    videoId: string
    playlist:boolean;
}
export interface MusicAlbums {
    count: number;
    data: Array<MusicAlbum>;
}
export abstract class MusicData {
    abstract getMusicAlbums(ceFacilityId: number, pageSize: number, pageIndex: number, Album: string): Observable<any>;
    abstract getRecommendedMusicAlbums(ceFacilityId: number): Observable<Array<MusicAlbum>>;
    abstract getMusicPlayList(videoIds: any, ceFacilityId: number): Observable<Array<MusicAlbum>>
}
