import { Observable } from "rxjs";
import { Facility } from "./facility";
import { Videos } from "./media";
import { MediaAttributes } from "./mediaAttributes";
import { PrescriptionItem } from "./prescriptionItem";
import { Terminal } from "./terminal";
import { UserInfo } from "./userInfo";
export interface Prescription {
    id: string;
    prescriptionNumber: string;
    toTerminal: Terminal;
    toPatientUser?: any;
    user: UserInfo;
    dateCreated: Date;
    dateModified: Date;
    timeLimit: Date;
    prescriptionType: any;
    prescriptionStatus: any;
    prescriptionCancelReason?: any;
    prescriptionCancelReasonOther?: any;
    prescriptionItems: PrescriptionItem[];
    prescriptionAlert?: any;
    userObjId?: any;
    facility: Facility;
    isPasswordProtected: boolean;
    prescriptionTimestamp: number;
    parentPrescriptionNumber?: any;

}

export abstract class PrescriptionData {
    abstract getPatientPrescriptions(): Observable<Prescription[]>;
    abstract createPrescriptionExecuted(prescriptionId: any, mediaId: any, surveyId: any, documentId: any): Observable<boolean>;
    abstract setFollowupStatus(prescriptionId: string, videoId: string, surveyId: string, statusId: string): Observable<boolean>;
    abstract setdocumentFollowupStatus(prescriptionId: string, documentId: string, surveyId: string, statusId: string): Observable<boolean>;
    abstract setPrescribedSurveyStatus(prescriptionId: string, surveyId: string, statusId: string): Observable<boolean>;
    abstract getOnDemandVideos(facilityCode: string, pageIndex: number, pageSize: number, filters: string): Observable<Videos>;
    abstract getMediaById(videoId: any): Observable<MediaAttributes>;
    abstract setPrescriptionVideoEnded(prescriptionId: string, mediaId: string): Observable<boolean>;
    abstract setPrescriptionVideoProgress(prescriptionId: string, mediaId: string, value: number): Observable<boolean>;
    abstract getTerminals(pageIndex: any, pageSize: any, facilityCode: string): Observable<any>;
    abstract getMediaGroup(facilityCode: string, pageIndex: any, pageSize: any): Observable<any>;
    abstract getOnDemanMediaByMediaGroup(id: string, pageIndex: any, pageSize: any): Observable<any>;
    abstract getDocument(facilityCode: string, documentName:string): Observable<any> 
    abstract updatePatient(data: any): Observable<boolean>;
}


