<nb-card>
    <nb-card-header class="d-flex justify-content-between">
            <h5><span i18n="@@header.service">My Service</span><span class="text-lowercase">(s)</span></h5>
            <a href="javascript:void(0)" (click)="closePopup()">
                    <nb-icon icon="close-outline"></nb-icon>
            </a>
    </nb-card-header>
    <nb-card-body class="example-items-rows service-popup-width">
            <label *ngIf="rentedServices?.length==0" class="d-flex justify-content-center"
                    i18n="@@header.no-rent-service">
                    No Rented Service
            </label>
            <label class="p-1" *ngIf="rentedServices.length > 0" class="service-text-color"
                    i18n="@@header.your-current-service">Your current
                    service(s) are:</label>
            <ul *ngFor="let service of rentedServices let pIdx=index">
                    <li>
                            <span
                                    style="font-size: 14px;">{{hasEnCulture?service.serviceDisplayNameEn:service.serviceDisplayNameFr}}</span>
                            <span class="pl-1 pr-1 service-text-color" i18n="@@header.from">: From</span>
                            <span style="font-size: 14px;">{{service.startingDate | date :'medium'}}</span>
                            <span class="pl-1 pr-1 service-text-color" i18n="@@header.to">To</span>
                            <span style="font-size: 14px;">{{service.expiringDate | date :'medium'}}</span>
                    </li>
            </ul>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
            <div>
                    <button nbButton required type="submit" status="success" size="small"
                            *ngIf="rentedServices?.length==0" (click)="openRentalMenu()"
                            i18n="@@header.rental-menu">Rental
                            Menu</button>
            </div>
            <button nbButton required type="submit" status="success" size="small"
                    (click)="closePopup()" i18n="@@header.ok">OK</button>
    </nb-card-footer>
</nb-card>
