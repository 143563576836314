import { Observable } from "rxjs";

export interface Movie {
    id: number;
    title: string;
    videoId: string;
    trailerId: string;
    synopsis: string;
    thumbnailPath: string;
    thumbnailName: string;
    rating: string;
    duration: number;
    publishYear: string;
    language: string;
    ageRating: string;
    videoQuality: string;
    videoType: string;
    genres: string;
    actors: string;
    director: string;
    profileId_movie: string;
    profileId_trailer: string;
    sig_movie: string;
    sig_trailer: string;
    videoTagSrc: string;
    videoTagSrcTrailer: string;
    genreNames: string;
    playlist:boolean;
    displayPriority:string
}

export interface Movies {
    count: number;
    data: Movie[];
}

export abstract class MovieData {
    abstract getMovies(orderBy: string, ceFacilityId: number, pageIndex: number, pageSize: number, filters: string): Observable<Movies>;
    abstract getRecommendedMovies(ceFacilityId: number,langCode:string): Observable<Array<Movie>>;
    abstract getMovieDetailsById(id: number): Observable<Movie>;
    abstract getRecommendedRelaxationVideos(ceFacilityId: number): Observable<any>;
    abstract getRelaxationVideos(orderBy: string, ceFacilityId: number, pageIndex: number, pageSize: number, filters: string): Observable<Movies>;
    abstract getMoviePlayList(videoIds: any,ceFacilityId:number,forMovie: boolean): Observable<Array<Movie>>;
}



