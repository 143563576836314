<div class="row" *ngIf="!isForCarousel">
    <ng-container [ngTemplateOutlet]="relaxationNoRecords" [ngTemplateOutletContext]="{relaxationMovies:relaxationMovies}"></ng-container>
    <div class="grid-lg-width col-md-3 col-sm-4 col-6" *ngFor="let relaxationMovie of relaxationMovies" [hidden]="relaxationMovies.length==0">
        <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{relaxationMovie:relaxationMovie}"></ng-container>
    </div>
 </div>
 
 <div class="carousel" *ngIf="isForCarousel">
    <ng-container [ngTemplateOutlet]="relaxationNoRecords" [ngTemplateOutletContext]="{relaxationMovies:relaxationMovies}"></ng-container>
    <owl-carousel-o [options]="customOptions" *ngIf="relaxationMovies.length>0">
        <ng-container *ngFor="let relaxationMovie of relaxationMovies">
            <ng-template carouselSlide [width]="170" [id]="relaxationMovie.title">
                <div class="relaxation type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                    <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{relaxationMovie:relaxationMovie}"></ng-container>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
 </div>
 
 <ng-template #relaxationNoRecords let-movie='relaxationMovies'>
    <strong *ngIf="relaxationMovies?.length==0" i18n="@@relaxation-card.noRelaxation">No Relaxation to display!</strong>
 </ng-template>
 
 <ng-template #cardRef let-relaxationMovie='relaxationMovie'>
    <div class="gen-carousel-movies-style-2 movie-grid style-2">
        <div class="gen-movie-contain">
            <div class="gen-movie-img">
                <img [src]="relaxationMovie?.thumbnailName?movieBlobStorage+relaxationMovie?.thumbnailName:defaultImagePath"
                    alt="owl-carousel-video-image">
                    <ngx-icons [video]="relaxationMovie" [patientPlaylistItem]="patientPlaylistItem" (newPlayList)="getUpdatedPlayList($event)"></ngx-icons>
                <div class="gen-movie-action">
                    <a href="javascript:void(0)" class="gen-button relaxationbg"
                        (click)="watchMovie(relaxationMovie)">
                        <i class="fa fa-play"></i>
                    </a>
                </div>
            </div>
            <div class="gen-info-contain">
                <div class="gen-movie-info">
                    <h3><a href="javascript:void(0)" (click)="watchMovie(relaxationMovie)">{{relaxationMovie?.title}}</a>
                    </h3>
                </div>
                <div class="gen-movie-meta-holder">
                    <ul>
                        <li>{{relaxationMovie?.duration|timing}}</li>
                        <li>
                            <a href="javascript:void(0)">
                                <span class="relaxation" style="cursor: default;">{{relaxationMovie?.genreNames}}</span></a>
                        </li>
                    </ul>
                    <br>
                    <div
                        style="width:70px;height:39px;background-image: url('../../../assets/images/preview/channel-ico.png');">
                    </div>
                </div>
            </div>
        </div>
    </div>
 </ng-template>