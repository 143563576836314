import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { AudiobookService } from 'src/app/@core/services/audiobook.service';
import { Audiobook } from 'src/app/@core/data/audiobook';
import { CommonService } from 'src/app/@core/utils';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PatientPlaylistItem } from 'src/app/@core/data/patientPlaylistItem';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-audio-book-card',
  templateUrl: './audio-book-card.component.html',
  styleUrls: ['./audio-book-card.component.scss']
})
export class AudioBookCardComponent implements OnInit {
  @Input() audiobooks: Array<Audiobook> = [];
  @Input() isForCarousel: boolean = false;
  @Input() fromPlayList: boolean = false;
  audiobook: Audiobook = {} as Audiobook;
  defaultImagePath: string;
  customOptions: OwlOptions = {
    rtl: false,
    items: 6,
    loop: false,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,
    navText: ["<i class='ion-ios-arrow-back'></i>", "<i class='ion-ios-arrow-forward'></i>"],
    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: false
      },
      480: {
        items: 4,
        nav: true,
        dots: false
      },
      786: {
        items: 4
      },
      1023: {
        items: 5
      },
      1199: {
        items: 6
      }
    }
  };
  patientPlaylistItem: PatientPlaylistItem = {} as PatientPlaylistItem;
  userChangeSubscription: Subscription;
  audiobookModalRef: any;

  constructor(private audioBookService: AudiobookService,
    private router: Router, private dialogService: NbDialogService,
    private commonService: CommonService) {
    this.defaultImagePath = this.commonService.defaultImage();
    this.patientPlaylistItem.forAudiobook = true;
    // rebindPlayList
    this.userChangeSubscription = this.commonService.rebindPlayList.subscribe((res: any) => {
      if (res) {
        const ceFacilityId = this.commonService.getLSKey("ceFacilityId");
        this.audiobooks = this.audioBookService.setPatientPlaylist(this.audiobooks, ceFacilityId);
        this.commonService.rebindPlayList.next(false)
      }
    });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.userChangeSubscription?.unsubscribe();
    this.audiobookModalRef?.close();
  }

  open(dialog: TemplateRef<any>) {
    this.audiobookModalRef = this.dialogService.open(dialog, { autoFocus: false });
  }

  showSynopsys(audiobookId: number, dialog: TemplateRef<any>) {
    this.audioBookService.getAudiobookById(audiobookId)
      .subscribe(result => {
        this.audiobook = result
        this.open(dialog);
      })
  }

  startListening(dialog: any): void {
    dialog.close();
    if (this.router.url.includes('my-playlist'))
      this.commonService.setLSKey('purl', { key: 'MY_PLAYLIST' });
    else if (this.isForCarousel)
      this.commonService.setLSKey('purl', { key: 'LANDING_CARD' });
    else
      this.commonService.setLSKey('purl', { key: 'AUDIOBOOK_TRAILER' });
    const url = `/home/infotainment/audio-book/viewer/${this.audiobook.playlistNbr}/${this.audiobook.token}`;
    this.router.navigate([url])
  }

  getUpdatedPlayList(video: any) {
    if (this.fromPlayList) {
      const index = this.audiobooks.findIndex((x: any) => x.id == video.id);
      if (index >= 0)
        this.audiobooks.splice(index, 1);
    }
  }
}