<nb-card>
  <nb-card-header class="d-flex justify-content-between">
    <h4 i18n="@@service.title">HôpiFlix</h4>
    <a href="javascript:void(0)" (click)="close()"> <nb-icon icon="close-outline"></nb-icon>
    </a>
  </nb-card-header>
  <nb-card-body class="dialogImage">
    <div class="cardBody"  i18n="@@service.paragraph">
        To access and self rent this service, just click on the RENT SERVICE button below or on the top right-hand
        corner of the web page or call 1-866-MEDITEL (633-4835) from 9:00 am to 10:00 pm 7 days per week.</div>
    <img id="myimage" src='../../../assets/images/preview/Edu.jpg' />
  </nb-card-body>
  <nb-card-footer style="text-align: end;">
    <button nbButton status="success" size="small" (click)="gotoRentalMenu()" i18n="@@service.rent">RENT SERVICE</button>
  </nb-card-footer>
</nb-card>