<div class="row" *ngIf="!isForCarousel">
   <ng-container [ngTemplateOutlet]="hopitvNoRecords" [ngTemplateOutletContext]="{hopitvVideos:hopitvVideos}"></ng-container>
   <div class="grid-lg-width col-md-3 col-sm-4 col-6" *ngFor="let hopitvVideo of hopitvVideos" [hidden]="hopitvVideos.length==0">
       <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{hopitvVideo:hopitvVideo}"></ng-container>
   </div>
</div>

<div class="carousel" *ngIf="isForCarousel">
   <ng-container [ngTemplateOutlet]="hopitvNoRecords" [ngTemplateOutletContext]="{hopitvVideos:hopitvVideos}"></ng-container>
   <owl-carousel-o [options]="customOptions" *ngIf="hopitvVideos.length>0">
       <ng-container *ngFor="let hopitvVideo of hopitvVideos">
           <ng-template carouselSlide [width]="170">
               <div class="movie type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                   <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{hopitvVideo:hopitvVideo}"></ng-container>
               </div>
           </ng-template>
       </ng-container>
   </owl-carousel-o>
</div>

<ng-template #hopitvNoRecords let-movie='hopitvVideos'>
   <strong *ngIf="hopitvVideos?.length==0" i18n="@@hopiTvCard.noHopitv">No HopiTv to display!</strong>
</ng-template>

<ng-template #cardRef let-hopitvVideo='hopitvVideo'>
   <div class="gen-carousel-movies-style-2 movie-grid style-2">
      <div class="gen-movie-contain">
         <div class="gen-movie-img">
            <img [src]="hopitvVideo.thumbnailUrl" alt="owl-carousel-video-image">
            <ngx-icons></ngx-icons>
            <div class="gen-movie-action">
               <a href="javascript:void(0)" class="gen-button">
                  <i class="fa fa-play"></i>
               </a>
            </div>
         </div>
         <div class="gen-info-contain">
            <div class="gen-movie-info">
               <h3><a href="javascript:void(0)">{{hopitvVideo.title}}</a>
               </h3>
            </div>
            <div class="gen-movie-meta-holder">
               <ul>
                  <li>{{hopitvVideo.duration}}</li>
               </ul>
               <p style="line-height:18px;font-size:14px;color:#3f9fff;"><B>{{hopitvVideo.type}}</B></p>
               <div
                  style="width:70px;height:39px;background-image: url('../../../../../assets/images/preview/channel-ico.png');">
               </div>
            </div>
         </div>
      </div>
   </div>
</ng-template>