import { Observable } from "rxjs";


export interface IptvChannel {
    id:number;
    link:string;
    label:string;
    thumbnail:string;
    description:string
    isFree:string;
    facilityId:number;
    playlist:boolean
}
export abstract class IptvChannelData {
    abstract getAllIptvChannel(ceFacilityId:number):Observable<IptvChannel[]>;
    abstract getRecommendedIptvChannels(ceFacilityId: number): Observable<IptvChannel[]>;
    abstract getIptvPlayList(videoIds: any, ceFacilityId: number): Observable<Array<IptvChannel>>
}
