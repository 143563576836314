import { Observable } from "rxjs";

export interface HospitalInfo {
    id: number;
    bedId: number;
    content: string;
    createdBy: string;
    description: string | null;
    displayName: string;
    displayType: string;
    documentThumbnail: string;
    documentType: string;
    duration: number | null;
    facilityId: number;
    folder: string;
    iFrameTagSrc: string | null;
    isPublished: boolean;
    languageContext: string;
    linkUrl: string | null;
    mediaId: number;
    modifiedOn: string;
    name: string;
    parentFolder: string;
    profileId_movie: number | null;
    publishedBy: string;
    roomId: number;
    sig_movie: string | null;
    thumbnail: string | null;
    videoId: string | null;
    videoTagSrc: string | null;
    totalFilteredRows:number;
}
export abstract class HospitalInfoData {
    abstract getAllHospitalInfo(ceFacilityId: number, folderName: string,pageIndex:number,pageSize:number): Observable<Array<HospitalInfo>>;
    abstract getWebPageById(id: number): Observable<any>
}
