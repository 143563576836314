import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/@core/utils';

@Component({
  selector: 'ngx-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() pages: number[] = [];
  @Input() totalPages: number = 0;
  @Input() pageArray: number[] = [];
  @Input() totaItems: number = 0;
  paginationSize: number = 4;
  activePage: number = 1;
  paginationIndex: number = 1;
  @Output()
  getGridDataByPagination: EventEmitter<number> = new EventEmitter<number>();
  facilityChangesSubscription: Subscription;

  constructor(private commonService: CommonService) {
    this.facilityChangesSubscription = this.commonService.facilityChanges.subscribe((response) => {
      if (response) {
        this.paginationSize = 4;
        this.activePage = 1;
        this.paginationIndex = 1;
      }
    });
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.facilityChangesSubscription.unsubscribe();
  }
  onClickPage(pageNumber: number) {
    if (pageNumber >= 1 && pageNumber <= this.pageArray.length) {
      this.activePage = pageNumber;
      /*******/
      //TODO: Need to call the parent component function to get next page records
      /*******/
      this.getGridData(pageNumber - 1);
    }
  }
  getGridData(pageNumber: number) {
    window.scrollTo(0, 0);
    this.getGridDataByPagination.emit(pageNumber);
  }

  gotoNext(pagination: number) {
    if (pagination == 4 * this.paginationIndex) {
      this.paginationIndex++;
      this.paginationSize = 4 * this.paginationIndex;
      if (this.totalPages > pagination) {
        this.pages = [];
        this.pages = this.pageArray.slice(pagination, this.paginationSize)
      }
    }
    pagination++;
    this.onClickPage(pagination)
  }

  gotoBack(pagination: number) {
    pagination--;
    if (pagination == 4 * (this.paginationIndex - 1)) {
      this.paginationIndex--;
      this.paginationSize = 4 * this.paginationIndex;
      let slicePage = 4 * (this.paginationIndex - 1);
      this.pages = [];
      this.pages = this.pageArray.slice(slicePage, this.paginationSize);
    }
    this.onClickPage(pagination)
  }

  gotoLastPage(totalPages: number) {
    this.paginationIndex = Math.ceil(totalPages / 4)
    this.paginationSize = totalPages;
    let slicePage = 4 * (this.paginationIndex - 1);
    this.pages = [];
    this.pages = this.pageArray.slice(slicePage, totalPages)
    this.activePage = totalPages;
    this.getGridData(totalPages - 1);
  }
}
