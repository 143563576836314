import { Observable } from "rxjs";
import { Terminal } from "./terminal";

export interface MediaScheduler {
    duration: number;
    facilityCode: string;
    mediaId: string;
    startDateTime: string
    terminal: Terminal;
    vimeoProfileId_movie: string;
    vimeoSig_movie: string;
    vimeoVideoClipId: string;
}

export abstract class MediaSchedulerData {
    abstract  getScheduleForDay(date: string, facilityCode: string, terminal: string): Observable<MediaScheduler>;
}