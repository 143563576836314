import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiUrlService, CommonService, ConstantsService, EncryptDecryptService } from './utils';
import { MovieData } from './data/movie';
import { ServicesModule } from './services/services.module';
import { MovieService } from './services/movie.service';
import { AudiobookData } from './data/audiobook';
import { AudiobookService } from './services/audiobook.service';
import { GameService } from './services/game.service';
import { GameSiteData } from './data/gameSite';
import { RadioService } from './services/radio.service';
import { RadioChannelData } from './data/radioChannel';
import { PrescriptionManagerService } from './services/prescription-manager.service';
import { PrescriptionData } from './data/prescription';
import { SurveyService } from './services/survey.service';
import { SurveyQuestionnaireData } from './data/SurveyQuestionnaire';
import { MediaSchedulerService } from './services/media-scheduler.service';
import { MediaSchedulerData } from './data/media-scheduler';
import { HospitalInfoService } from './services/hospital-info.service';
import { HospitalInfoData } from './data/hospital-Info';
import { MusicService } from './services/music.service';
import { MusicData } from './data/musicAlbum';
import { RentalService } from './services/rental.service';
import { RentalData } from './data/rental';
import { IptvService } from './services/iptv.service';
import { IptvChannelData } from './data/iptvChannel';
import { KidsService } from './services/kids.service';
import { KidsData } from './data/Kids';
import { PatientPlaylistService } from './services/patient-playlist.service';
import { PatientPlaylistData } from './data/patientPlaylist';
import { FacilityData } from './data/facility';
import { FacilityService } from './services/facility.service';

const DATA_SERVICES = [
  { provide: MovieData, useClass: MovieService },
  { provide: AudiobookData, useClass: AudiobookService },
  { provide: GameSiteData, useClass: GameService },
  { provide: RadioChannelData, useClass: RadioService },
  { provide: PrescriptionData, useClass: PrescriptionManagerService },
  { provide: SurveyQuestionnaireData, useClass: SurveyService },
  { provide: MediaSchedulerData, useClass: MediaSchedulerService },
  { provide: HospitalInfoData, useClass: HospitalInfoService },
  { provide: MusicData, useClass: MusicService },
  { provide: RentalData, useClass: RentalService },
  { provide: IptvChannelData, useClass: IptvService },
  { provide: KidsData, useClass: KidsService },
  { provide: PatientPlaylistData, useClass: PatientPlaylistService },
  { provide: FacilityData, useClass: FacilityService }
];
export const NB_CORE_PROVIDERS = [
  ServicesModule.forRoot().providers,
  DATA_SERVICES,
  ApiUrlService,
  CommonService,
  EncryptDecryptService,
  ConstantsService
];



@NgModule({
  imports: [
    CommonModule,
    ServicesModule
  ],
  exports: [
  ],
  declarations: [],
})
export class CoreModule {

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        NB_CORE_PROVIDERS,
      ],
    };
  }
}
