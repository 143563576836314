import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiUrlService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class AzFunctionsService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private httpClient: HttpClient, private urlService: ApiUrlService) { }

  public getSignalRInfo(userId: string): Observable<any> {
    const url = this.urlService.azFctUrl;
    this.httpOptions.headers = this.httpOptions.headers.set('x-ms-signalr-userid', userId);
    return this.httpClient.get(url, this.httpOptions)
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError2)
      );
  }

  private handleError2(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

}

