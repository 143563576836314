import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { PatientPlaylistItem } from 'src/app/@core/data/patientPlaylistItem';
import { PatientPlaylistService } from 'src/app/@core/services/patient-playlist.service';
import { CommonService } from 'src/app/@core/utils';

@Component({
  selector: 'ngx-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent implements OnInit {
  @Input() video: any;
  @Input() patientPlaylistItem: PatientPlaylistItem = {} as PatientPlaylistItem;
  @Output()
  newPlayList: EventEmitter<number> = new EventEmitter<number>();
  constructor(private patientPlaylistService: PatientPlaylistService,
    private commonService: CommonService, private authService: MsalService,) {
     }

  ngOnInit(): void {
  }


  selectFavourite(video: any) {
    const account = this.authService.instance.getAllAccounts()[0];
    if (account) {
      this.patientPlaylistItem.videoId = video.id;
      let patientPlaylist = JSON.parse(this.commonService.getLSKey('patientPlaylist'));
      const ceFacilityId = this.commonService.getLSKey("ceFacilityId");
      this.patientPlaylistService.createPatientPlaylist(this.patientPlaylistItem).subscribe(res => {
        if (res) {
          if (!video.playlist)
            patientPlaylist = this.addItemInPlayList(patientPlaylist, video, ceFacilityId);
          else
            patientPlaylist = this.removeItemFromPlayList(patientPlaylist, video, ceFacilityId);
          this.commonService.setLSKey('patientPlaylist', JSON.stringify(patientPlaylist));
            this.newPlayList.emit(video);
        }
      })
    }
  }

  addItemInPlayList(patientPlaylist: any, video: any, ceFacilityId: number) {
    let playlistItem = { videoId: Number(video.id), ceFacilityId: ceFacilityId }
    video.playlist = true;
    switch (true) {
      case this.patientPlaylistItem.forMovie:
        patientPlaylist.movies.push(playlistItem)
        break;
      case this.patientPlaylistItem.forAudiobook:
        patientPlaylist.audiobooks.push(playlistItem);
        break;
      case this.patientPlaylistItem.forIptv:
        patientPlaylist.iptvs.push(playlistItem);
        break;
      case this.patientPlaylistItem.forMusic:
        patientPlaylist.music.push(playlistItem);
        break;
      case this.patientPlaylistItem.forKids:
        patientPlaylist.kidsVideos.push(playlistItem);
        break;
      case this.patientPlaylistItem.forRelaxation:
        patientPlaylist.relaxationVideos.push(playlistItem);
        break;
      case this.patientPlaylistItem.forRadio:
        patientPlaylist.radios.push(playlistItem);
        break;
    }
    return patientPlaylist;
  }

  removeItemFromPlayList(patientPlaylist: any, video: any, ceFacilityId: number) {
    video.playlist = false;
    let index;
    switch (true) {
      case this.patientPlaylistItem.forMovie:
        index = patientPlaylist.movies.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == video.id)
        patientPlaylist.movies.splice(index, 1);
        break;
      case this.patientPlaylistItem.forAudiobook:
        index = patientPlaylist.audiobooks.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == video.id)
        patientPlaylist.audiobooks.splice(index, 1);
        break;
      case this.patientPlaylistItem.forIptv:
        index = patientPlaylist.iptvs.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == video.id)
        patientPlaylist.iptvs.splice(index, 1);
        break;
      case this.patientPlaylistItem.forMusic:
        index = patientPlaylist.music.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == video.id)
        patientPlaylist.music.splice(index, 1);
        break;
      case this.patientPlaylistItem.forKids:
        index = patientPlaylist.kidsVideos.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == video.id)
        patientPlaylist.kidsVideos.splice(index, 1);
        break;
      case this.patientPlaylistItem.forRelaxation:
        index = patientPlaylist.relaxationVideos.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == video.id)
        patientPlaylist.relaxationVideos.splice(index, 1);
        break;
      case this.patientPlaylistItem.forRadio:
        index = patientPlaylist.radios.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == video.id)
        patientPlaylist.radios.splice(index, 1);
        break;
    }
    return patientPlaylist;
  }
}
