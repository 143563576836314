import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { Movie } from 'src/app/@core/data/movie';
import { PatientPlaylistItem } from 'src/app/@core/data/patientPlaylistItem';
import { MovieService } from 'src/app/@core/services/movie.service';
import { ApiUrlService, CommonService } from 'src/app/@core/utils'

@Component({
  selector: 'ngx-relaxation-card',
  templateUrl: './relaxation-card.component.html',
  styleUrls: ['./relaxation-card.component.scss']
})
export class RelaxationCardComponent implements OnInit {
  @Input() relaxationMovies: Array<Movie> = [];
  @Input() isForCarousel: boolean = false;
  @Input() fromPlayList: boolean = false;
  defaultImagePath: string;
  movieBlobStorage: string;
  patientPlaylistItem: PatientPlaylistItem = {} as PatientPlaylistItem;
  customOptions: OwlOptions = {
    rtl: false,
    items: 6,
    loop: false,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,
    navText: ["<i class='ion-ios-arrow-back'></i>", "<i class='ion-ios-arrow-forward'></i>"],
    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: false
      },
      480: {
        items: 4,
        nav: true,
        dots: false
      },
      786: {
        items: 4
      },
      1023: {
        items: 5
      },
      1199: {
        items: 6
      }
    }
  }
  userChangeSubscription: Subscription;
  constructor(private commonService: CommonService, private router: Router,
    private apiUrlServices: ApiUrlService, private movieService: MovieService) {
    this.defaultImagePath = this.commonService.defaultImage();
    this.movieBlobStorage = this.apiUrlServices.movieBlobStorage;
    this.patientPlaylistItem.forRelaxation = true;
    // rebindPlayList
    this.userChangeSubscription = this.commonService.rebindPlayList.subscribe((res: any) => {
      if (res) {
        const ceFacilityId = this.commonService.getLSKey("ceFacilityId");
        this.relaxationMovies = this.movieService.setPatientPlaylist(this.relaxationMovies, ceFacilityId, false);
        this.commonService.rebindPlayList.next(false);
      }
    });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.userChangeSubscription.unsubscribe();
  }

  watchMovie(movie: Movie): void {
    let oauthTokenId = null;
    if (movie.videoTagSrc) {
      oauthTokenId = movie.videoTagSrc.split("oauth2_token_id=")[1].split("&")[0];
      this.commonService.setLSKey('videoUrl', movie.videoTagSrc);
    }
    if (this.router.url.includes('my-playlist'))
      this.commonService.setLSKey('purl', { key: 'MY_PLAYLIST' });
    else if (this.isForCarousel)
      this.commonService.setLSKey('purl', { key: 'LANDING_CARD' });
    else
      this.commonService.setLSKey('purl', { key: 'RELAXATION_TRAILER' });
    var url = `/home/infotainment/relaxation/viewer/${movie.videoId}/${movie.sig_movie}/${movie.profileId_movie}/${oauthTokenId}`;
    this.router.navigate([url]);
  }

  getUpdatedPlayList(video: any) {
    if (this.fromPlayList) {
      const index = this.relaxationMovies.findIndex((x: any) => x.id == video.id);
      if (index >= 0)
        this.relaxationMovies.splice(index, 1);
    }
  }
}
