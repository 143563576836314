import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiUrlService } from '../utils/api-url.service';
import { RentalData } from '../data/rental';
import { ToasterService } from '../utils/toaster.service';
import { CommonService, ConstantsService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class RentalService implements RentalData {

  constructor(private httpClient: HttpClient, private urlServices: ApiUrlService, private commonService: CommonService,
    private toasterService: ToasterService, private constantService: ConstantsService) { }

  getServices(ceFacilityId: number): Observable<any> {
    const url = `${this.urlServices.serviceUrl}/${ceFacilityId}/byod-services`;
    return this.httpClient.get<any>(url)
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError<any>('getServices', []))
      );
  }

  getServicePrices(ceFacilityId: number): Observable<any> {
    const url = `${this.urlServices.serviceUrl}/${ceFacilityId}/service-prices`;
    return this.httpClient.get<any>(url)
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError<any>('getServicePrices', []))
      );
  }

  getServiceUnits(ceFacilityId: number): Observable<any> {
    const url = `${this.urlServices.serviceUnit}/facilities/${ceFacilityId}/byod-service-units`;
    return this.httpClient.get<any>(url)
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError<any>('getServiceUnits', []))
      );
  }

  getServicePackages(ceFacilityId: number): Observable<any> {
    const url = `${this.urlServices.serviceUrl}/${ceFacilityId}/service-packages`;
    return this.httpClient.get<any>(url)
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError<any>('getServicePackages', []))
      );
  }

  getRentalPrice(rentalPrice: any): Observable<any> {
    const url = `${this.urlServices.serviceUnit}/byod-rental-price`;
    return this.httpClient.post<any>(url, rentalPrice)
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError<any>('getRentalPrice', []))
      );
  }

  submitOrder(order: any): Observable<any> {
    const url = `${this.urlServices.serviceUnit}/submit-byod-order`;
    const jsonOrder = JSON.stringify(order)
    return this.httpClient.post<any>(url, "'" + jsonOrder + "'", { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError<any>('submitOrder', []))
      );
  }

  getRentedServices(ceFacilityId: number, userEmail: string): Observable<Array<any>> {
    const url = `${this.urlServices.serviceUrl}/${ceFacilityId}/byod-customers/${userEmail}/rented-services`;
    return this.httpClient.get<Array<any>>(url)
      .pipe(
        map((returnData: Array<any>) => {
          return returnData;
        }),
        catchError(this.handleError<Array<any>>('getRentedServices', []))
      );
  }

  getRentedServicesBody(userEmail: string): Observable<any> {
    const facilityId = this.commonService.getLSKey('ceFacilityId');
    const url = `${this.urlServices.serviceUrl}/${facilityId}/byod-customers/${userEmail}/rented-services`;
    return this.httpClient.get<any>(url, { observe: 'response' })
    .pipe(catchError(this.handleError<any>(`getRentedServicesBody url=${url}`)));
  }
  sendEmail(emailDto: any): Observable<any> {
    const url = this.urlServices.sendEmailUrl;
    return this.httpClient.post<any>(url, emailDto)
      .pipe(
        map((returnData: any) => {
          if (returnData) {
            this.toasterService.showSuccessMessage('EmailSuccess')
            return returnData;
          }
        }),
        catchError(this.handleError<any>('sendEmail', []))
      );
  }

  getUserTransactions(userName: string, pageIndex: number, pageSize: number): Observable<any> {
    let langCode = this.commonService.getLanguage();
    const url = `${this.urlServices.serviceUnit}/byod-transaction-list/byod-customers/${userName}?pageSize=${pageSize}&pageIndex=${pageIndex}`;
    return this.httpClient.get<any>(url)
      .pipe(
        map((returnData: any) => {
          returnData.data.map((x: any) =>
            x.transactionTypeName = this.constantService.getTransactionType(x.transactionType, langCode)
          );
          returnData.data = returnData.data.sort((a: any, b: any) => ((a.id) < (b.id)) ? 1 : -1);
          return returnData;
        }),
        catchError(this.handleError<any>('getUserTransactions', []))
      );
  }

  getAllRentedServices(userEmail: string): Observable<Array<any>> {
    const url = `${this.urlServices.rentedServiceUrl}/${userEmail}/rented-services`;
    return this.httpClient.get<Array<any>>(url)
      .pipe(
        map((returnData: Array<any>) => {
          return returnData;
        }),
        catchError(this.handleError<Array<any>>('getAllRentedServices', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
