import { Injectable } from '@angular/core';
import { NbIconConfig, NbToastrService } from '@nebular/theme';
import { CommonService } from './common.service';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastrService: NbToastrService, private commonService: CommonService,
    private constantsService: ConstantsService) { }

  showSuccess(message: string, title: string = 'Success!', status: string = 'success') {
    const iconConfig: NbIconConfig = { icon: 'checkmark-circle-2', pack: 'eva', status };
    this.toastrService.show(message, title, iconConfig);
  }

  showInfo(message: string, title: string = 'Info!', status: string = 'info') {
    const iconConfig: NbIconConfig = { icon: 'info', pack: 'eva', status };
    this.toastrService.show(message, title, iconConfig);
  }

  showWarning(message: string, title: string = 'Warning!', status: string = 'warning') {
    const iconConfig: NbIconConfig = { icon: 'alert-triangle', pack: 'eva', status };
    this.toastrService.show(message, title, iconConfig);
  }

  showError(message: string, title: string = 'Error!', status: string = 'danger') {
    const iconConfig: NbIconConfig = { icon: 'close-square', pack: 'eva', status };
    this.toastrService.show(message, title, iconConfig);
  }

  showSuccessMessage(errorCode: string, array: any[] = [], stitle: string = '') {
    const filterArray = array.length > 0 ? array : this.constantsService.validationResult.general;
    const validation = filterArray.find((x: any) => x.code === errorCode);
    if (!(validation == null || validation == undefined)) {
      const code = this.commonService.getLanguage();
      const message = validation.message.find((x: any) => x.languageCode === code);
      const title = this.constantsService.validationResult.general.find((x: any) => x.code === (stitle == '' ? 'Success' : stitle));
      const toastrTitle = title.message.find((x: any) => x.languageCode === code);
      this.showSuccess(message.text, toastrTitle.text);
    }
  }

  showInfoMessage(errorCode: string, array: any[] = [], title: string = '') {
    const filterArray = array.length > 0 ? array : this.constantsService.validationResult.general;
    const validation = filterArray.find((x: any) => x.code === errorCode);
    if (!(validation == null || validation == undefined)) {
      const code = this.commonService.getLanguage();
      const message = validation.message.find((x: any) => x.languageCode === code);

      let toastrTitle: string = title;
      if (toastrTitle == '') {
        const title = this.constantsService.validationResult.general.find((x: any) => x.code === 'Info');
        toastrTitle = title.message.find((x: any) => x.languageCode === code).text;
      }
      this.showInfo(message.text, toastrTitle);
    }
  }

  showErrorMessage(errorCode: string, array: any[] = [], stitle: string = '') {
    const filterArray = array.length > 0 ? array : this.constantsService.validationResult.general;
    const validation = filterArray.find((x: any) => x.code === errorCode);
    if (!(validation == null || validation == undefined)) {
      const code = this.commonService.getLanguage();
      const message = validation.message.find((x: any) => x.languageCode === code);
      const title = this.constantsService.validationResult.general.find((x: any) => x.code === (stitle == '' ? 'Error' : stitle));
      const toastrTitle = title.message.find((x: any) => x.languageCode === code);
      this.showError(message.text, toastrTitle.text);
    }
  }
}

