import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {
  apiVersion = 'v1';
  moviesUrl = '';
  gameslUrl = '';
  radioUrl = '';
  entertainmentApiUrl = '';
  serviceUrl = '';
  serviceUnit = '';
  musicAlbumUrl = '';
  gamesBlobStorage = '';
  movieBlobStorage = '';
  phoenixUrl = '';
  hospitalInfoUrl = '';
  genresMovieUrl = '';
  phoenixBlobStorage = '';
  azFctUrl = '';
  ocelotSelectorSurvey = '';
  ocelotSelectorLocation = '';
  ocelotSelectorMultimedia = '';
  ocelotSelectorPrescription = '';
  getFacilityUrl = '';
  iptvUrl = '';
  hospitalUrl = '';
  connectionUrl = '';
  sendEmailUrl = '';
  rentedServiceUrl='';
  advertisementUrl='';

  constructor() {
    this.moviesUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/e/movies`;
    this.gameslUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/e/games`;
    this.radioUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/e/radio`;
    this.entertainmentApiUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/e`;
    this.serviceUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/s/facilities`;
    this.phoenixUrl = environment.phoenixGatewayAPIUrl + `/api/${this.apiVersion}`;
    this.hospitalInfoUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/edu`;
    this.genresMovieUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/e/movie-genres`;
    this.musicAlbumUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/e/facilities`;
    this.azFctUrl = `${environment.azFctUrl}/api/signalrinfo`;
    this.serviceUnit = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/p`;
    this.gamesBlobStorage = environment.gamesBlobStorage;
    this.phoenixBlobStorage = environment.phoenixBlobStorage
    this.movieBlobStorage = environment.movieBlobStorage;
    this.getFacilityUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/l`;
    this.iptvUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/e/Iptv`;
    this.ocelotSelectorSurvey = environment.ocelotSelectorSurvey;
    this.ocelotSelectorLocation = environment.ocelotSelectorLocation;
    this.ocelotSelectorMultimedia = environment.ocelotSelectorMultimedia;
    this.ocelotSelectorPrescription = environment.ocelotSelectorPrescription;
    this.hospitalUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/m/facilities`;
    this.connectionUrl = environment.cronosEdgeAPIUrl + `/hub/api/v1`;
    this.sendEmailUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/r/send-email`;
    this.rentedServiceUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/s/byod-customers`;
    this.advertisementUrl = environment.cronosEdgeAPIUrl + `/api/${this.apiVersion}/adv`;
  }
}