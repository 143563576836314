import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Media } from 'src/app/@core/data/media';
import { CommonService } from 'src/app/@core/utils';
import { PrescriptionManagerService } from 'src/app/@core/services/prescription-manager.service';
import { MsalService } from '@azure/msal-angular';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'ngx-health-education-card',
  templateUrl: './health-education-card.component.html',
  styleUrls: ['./health-education-card.component.scss']
})
export class HealthEducationCardComponent implements OnInit {
  @Input() healthEducationMedias: Array<any> = [];
  @Input() isForCarousel: boolean = false;
  customOptions: OwlOptions = {
    rtl: false,
    items: 6,
    loop: false,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,
    navText: ["<i class='ion-ios-arrow-back'></i>", "<i class='ion-ios-arrow-forward'></i>"],
    responsive: {
      0: {
          items: 2,
          nav: true,
          dots: false
      },
      480: {
          items: 4,
          nav: true,
          dots: false
      },
      786: {
          items: 4
      },
      1023: {
          items: 5
      },
      1199: {
          items: 6
      }
    }
  }
  defaultImagePath:any="../../../../../assets/images/media-group.png";
  constructor(private router: Router, private prescriptionManagerService: PrescriptionManagerService,
    private commonService: CommonService, private authService: MsalService) {
  }

  ngOnInit(): void { }

  gotoMediaGroup(groupName:string,id: number) {
    this.router.navigate(["home/info-health/health-education",groupName, id]);
  }

}
