import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/@core/utils';

@Component({
  selector: 'ngx-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  prescribedEducationCount: number = 0;
  followUpQuestionnaireCount: number = 0;
  prescribedSurveyCount: number = 0;
  mediaOnDemandCount: number = 0;
  documentFollowUpQuestionnaireCount: number = 0;
  prescribedDocumentCount: number = 0;
  rebindStatsSubscription: Subscription;
  constructor(private commonService: CommonService, private dialogRef: NbDialogRef<NotificationsComponent>) {

    this.rebindStatsSubscription = this.commonService.rebindStats.subscribe((res: any) => {
      if (res) {
        const counts = this.CountPrescriptions();
        this.prescribedEducationCount = counts.prescribedEducationCount;
        this.prescribedSurveyCount = counts.prescribedSurveyCount;
        this.followUpQuestionnaireCount = counts.followUpQuestionnaireCount;
        this.prescribedDocumentCount = counts.prescribedDocumentCount;
        this.followUpQuestionnaireCount += counts.documentFollowUpQuestionnaireCount;
        const totalUnseenPrescription = this.followUpQuestionnaireCount + this.prescribedEducationCount + this.prescribedSurveyCount + this.prescribedDocumentCount;
        localStorage.setItem('totalUnseenPrescription', totalUnseenPrescription.toString());
      }
    });
  }

  ngOnInit(): void {
    this.commonService.hasNotificationPopup = true;;
  }

  closePopup() {
    this.dialogRef.close();
    this.commonService.hasNotificationPopup = false;
  }

  CountPrescriptions() {
    let prescribedEducationCount = 0;
    let followUpQuestionnaireCount = 0;
    let prescribedSurveyCount = 0;
    let documentFollowUpQuestionnaireCount = 0;
    let prescribedDocumentCount = 0;
    let lsPrescriptions = this.commonService.getLSKey("Prescrption");
    if (lsPrescriptions != '' && lsPrescriptions != undefined) {
      const prescrptionList = JSON.parse(lsPrescriptions);
      for (let i = 0; i < prescrptionList.length; i++) {
        const prescription = prescrptionList[i];
        for (let j = 0; j < prescription.prescriptionItems.length; j++) {
          const item = prescription.prescriptionItems[j];
          if (item.media && !item.mediaWatched) {
            prescribedEducationCount = prescribedEducationCount + 1;

          }
          else if (item.media && item.mediaWatched && item.followUpQuestionnaire && !item.followUpAnswered) {
            followUpQuestionnaireCount = followUpQuestionnaireCount + 1;
          }
          else if (item.survey && !item.surveyAnswered) {
            prescribedSurveyCount = prescribedSurveyCount + 1;
          }
          else if (item.document && item.documentRead && item.documentFollowUpQuestionnaire && !item.documentFollowUpAnswered) {
            documentFollowUpQuestionnaireCount = documentFollowUpQuestionnaireCount + 1;
          }
          else if (item.document && !item.documentRead) {
            prescribedDocumentCount = prescribedDocumentCount + 1;
          }
        }
      }
    }
    return {
      prescribedEducationCount: prescribedEducationCount,
      followUpQuestionnaireCount: followUpQuestionnaireCount,
      prescribedSurveyCount: prescribedSurveyCount,
      documentFollowUpQuestionnaireCount: documentFollowUpQuestionnaireCount,
      prescribedDocumentCount: prescribedDocumentCount,
    }
  }
}