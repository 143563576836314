import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Videos } from '../data/media';
import { MediaAttributes } from '../data/mediaAttributes';
import { Prescription, PrescriptionData } from '../data/prescription';
import { ApiUrlService, CommonService } from '../utils';
import { ToasterService } from '../utils/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionManagerService implements PrescriptionData {

  constructor(private apiUrlService: ApiUrlService, private httpClient: HttpClient, private commonService: CommonService,
    private authService: MsalService, private toasterService: ToasterService) { }

  getPatientPrescriptions(): Observable<any> {
    const account = this.authService.instance.getAllAccounts()[0];
    const email: string = account.username; //"gurpreet.singh@mailinator.com" 
    let params = new HttpParams();
    if (email != undefined && email != null) {
      params = params.set('email', email.toString());
    }
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorPrescription}/Prescription/getPrescriptionForPatient`;
    return this.httpClient.get<Prescription[]>(url, { params: params })
      .pipe(
        map((returnData: Prescription[]) => {
          const prescriptions: any = [];
          const defaultLanguage = "en";
          returnData.forEach((item: any) => {
            const date = new Date(item.dateCreated);
            const limitDate = new Date(item.timeLimit);

            const terminal = !(item.toTerminal == null || item.toTerminal == undefined) ? item.toTerminal.name.split('-') : null;

            prescriptions.push({
              id: item.id,
              prescriptionNumber: item.prescriptionNumber,
              prescriptionItems: item.prescriptionItems,
              prescriptionType: item.prescriptionType,
              prescriptionStatus: item.prescriptionStatus,
              floorName: !(terminal == null || terminal == undefined) ? terminal[0] : '',
              roomName: !(terminal == null || terminal == undefined) ? terminal[1] : '',
              bedName: !(terminal == null || terminal == undefined) ? terminal[2] : '',

              patientId: !(item.toPatientUser == null || item.toPatientUser == undefined) ? item.toPatientUser.id : '',
              firstName: !(item.toPatientUser == null || item.toPatientUser == undefined) ? item.toPatientUser.firstName : '',
              lastName: !(item.toPatientUser == null || item.toPatientUser == undefined) ? item.toPatientUser.lastName : '',
              email: !(item.toPatientUser == null || item.toPatientUser == undefined) ? item.toPatientUser.userName : '',
              dateCreated: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
              toTerminal: item.toTerminal,
              toPatientUser: item.toPatientUser,
              terminal: !(item.toTerminal == null || item.toTerminal == undefined) ? item.toTerminal.name : null,
              patientName: !(item.toPatientUser == null || item.toPatientUser == undefined) ? item.toPatientUser.userName : '',
              timeLimit: new Date(limitDate.getFullYear(), limitDate.getMonth(), limitDate.getDate()),
              user: item.user,
              createdBy: !(item.user == null || item.user == undefined) ? item.user.userName : '',
              parentPrescriptionNumber: item.parentPrescriptionNumber,
              facility: item.facility
            });
          });
          this.commonService.setLSKey("Prescrption", JSON.stringify(prescriptions));
          this.commonService.rebindStats.next(true);
          return prescriptions;
        }),
        catchError(this.handleError<any[]>('getPatientPrescriptions'))
      );
  }

  createPrescriptionExecuted(prescriptionId: any, mediaId: any, surveyId: any, documentId: any): Observable<boolean> {
    const payload = {
      prescriptionId: prescriptionId,
      mediaId: mediaId,
      surveyId: surveyId,
      documentId: documentId
    };
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorPrescription}/PrescriptionExecuted/create`; //'http://localhost:9083/api/v1/PrescriptionExecuted/create' //`${this.phoenixUrl}/${this.apiUrlService.ocelotSelectorPrescription}/PrescriptionExecuted/create`;

    return this.httpClient.post<boolean>(url, payload)
      .pipe(
        map((returnData: boolean) => {
          return returnData;
        }),
        catchError(this.handleError<boolean>(`createPrescriptionExecuted url=${url}`))
      );
  }

  setFollowupStatus(prescriptionId: string, videoId: string, followupId: string, statusId: string): Observable<boolean> {
    const payload = {
      prescriptionId: prescriptionId,
      videoId: videoId,
      followupId: followupId,
      statusId: statusId
    };
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorPrescription}/PrescriptionExecuted/setFollowupStatus`;
    return this.httpClient.post<boolean>(url, payload)
      .pipe(
        map((returnData: boolean) => {
          return returnData;
        }),
        catchError(this.handleError<boolean>(`setFollowupStatus url=${url}`))
      );
  }

  setdocumentFollowupStatus(prescriptionId: string, documentId: string, documentFollowupId: string, statusId: string): Observable<boolean> {
    const payload = {
      prescriptionId: prescriptionId,
      documentId: documentId,
      documentFollowupId: documentFollowupId,
      statusId: statusId
    };
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorPrescription}/PrescriptionExecuted/setDocumentFollowupStatus`;

    return this.httpClient.post<boolean>(url, payload)
      .pipe(
        map((returnData: boolean) => {
          return returnData;
        }),
        catchError(this.handleError<boolean>(`setDocumentFollowupStatus url=${url}`))
      );
  }

  setPrescribedSurveyStatus(prescriptionId: string, surveyId: string, statusId: string): Observable<boolean> {
    const payload = {
      prescriptionId: prescriptionId,
      surveyId: surveyId,
      statusId: statusId
    };
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorPrescription}/PrescriptionExecuted/setSurveyStatus`;

    return this.httpClient.post<boolean>(url, payload)
      .pipe(
        map((returnData: boolean) => {
          return returnData;
        }),
        catchError(this.handleError<boolean>(`setPrescribedSurveyStatus url=${url}`))
      );
  }

  setPrescriptionDocumentRead(prescriptionId: string, documentId: string): Observable<boolean> {
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorPrescription}/PrescriptionExecuted/setDocumentRead`;
    const payload = {
      prescriptionId: prescriptionId,
      documentId: documentId,
    };
    return this.httpClient.post<boolean>(url, payload)
      .pipe(
        map((returnData: boolean) => {
          return returnData;
        }),
        catchError(this.handleError<boolean>(`setPrescriptionDocumentRead`))
      );
  }
  getOnDemandVideos(facilityCode: string, pageIndex: number, pageSize: number, filters: string): Observable<Videos> {
    let eFilter = escape(filters);
    let url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorMultimedia}/MediaGroup/getMediaForOnDemandBySearch?facilityCode=${facilityCode}&isOnDemandOnly=true&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    if (eFilter != "")
      url += `&searchName=${eFilter}`;
    return this.httpClient.get<Videos>(url)
      .pipe(
        map((returnData: Videos) => {
          return returnData;
        }),
        catchError(this.handleError<Videos>('getOnDemandVideos'))
      );
  }

  getMediaById(videoId: any): Observable<MediaAttributes> {
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorMultimedia}/Media/getMediaIdentifiersById/${videoId}`;

    return this.httpClient.get<MediaAttributes>(url)
      .pipe(
        map((returnData: MediaAttributes) => {
          return returnData;
        }),
        catchError(this.handleError<MediaAttributes>('getMediaById'))
      );
  }

  setPrescriptionVideoEnded(prescriptionId: string, mediaId: string): Observable<boolean> {
    const payload = {
      prescriptionId: prescriptionId,
      videoId: mediaId
    }
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorPrescription}/PrescriptionExecuted/setVideoEnded`;
    return this.httpClient.post<boolean>(url, payload)
      .pipe(
        map((returnData: boolean) => {
          return returnData;
        }),
        catchError(this.handleError<boolean>(`setPrescriptionVideoEnded url=${url}`))
      );
  }

  setPrescriptionVideoProgress(prescriptionId: string, mediaId: string, value: number): Observable<boolean> {
    const payload = {
      prescriptionId: prescriptionId,
      videoId: mediaId,
      value: value
    }
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorPrescription}/PrescriptionExecuted/setVideoProgress`;

    return this.httpClient.post<boolean>(url, payload)
      .pipe(
        map((returnData: boolean) => {
          return returnData;
        }),
        catchError(this.handleError<boolean>(`setPrescriptionVideoProgress url=${url}`))
      );
  }

  getTerminals(pageIndex: any, pageSize: any, facilityCode: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('facilityCode', facilityCode);
    if (pageIndex != undefined && pageIndex != null) {
      params = params.set('pageIndex', pageIndex.toString()).set('pageSize', pageSize.toString());
    }
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorLocation}/terminal/getTerminalForFacility`
    return this.httpClient.get(url, { params: params })
      .pipe(
        map((returnData: any) => {
          const terminal: any[] = [];
          returnData.data.forEach((item: any) => {
            terminal.push({
              id: item.id,
              name: item.name,
              bed: item.bed,
              bedName: !(item.bed) ? item.bed.name : null,
              floor: item.floor,
              floorName: (item.floor != undefined && item.floor != null) ? item.floor.name : null,
              room: item.room,
              roomName: (item.room != undefined && item.room != null) ? item.room.name : null,
              active: item.active,
              macAddress: item.macAddress,
              ipAddress: item.ipAddress,
              ceTerminalId: item.ceTerminalId
            });
          });
          return { total: returnData.total, data: terminal };
        }),
        catchError(this.handleError<any>('getTerminals'))
      );
  }

  getMediaGroup(facilityCode: string, pageIndex: any = null, pageSize: any = null): Observable<any> {
    let url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorMultimedia}/MediaGroup/getOnDemandMedia?facilityCode=${facilityCode}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    return this.httpClient.get<any>(url)
      .pipe(
        map((returnData: any) => {
          returnData.data = returnData.data.map((media: any) => {
            media.i18nTitle = this.commonService.getI18nString(media.names);
            return media
          });
          returnData.data.sort((a: any, b: any) => ((a.i18nTitle) < (b.i18nTitle)) ? 1 : -1);
          const priorityGroup = returnData.data.filter((x: any) => x.displayPriority != null).sort((a: any, b: any) => ((a.displayPriority) > (b.displayPriority)) ? 1 : -1);
          const nonPriorityGroup = returnData.data.filter((x: any) => x.displayPriority == null).sort((a: any, b: any) => ((a.displayPriority) > (b.displayPriority)) ? 1 : -1)
          returnData.data = priorityGroup.concat(nonPriorityGroup);
          return returnData;

        }),
        catchError(this.handleError<any>('getMediaGroups'))
      );
  }

  getOnDemanMediaByMediaGroup(id: string, pageIndex: any = null, pageSize: any = null): Observable<any> {
    let url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorMultimedia}/MediaGroup/getOnDemanMediaByMediaGroup?id=${id}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    return this.httpClient.get(url)
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError<any>('getOnDemanMediaByMediaGroup'))
      );
  }

  getDocument(facilityCode: string, documentName: string): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    let url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorMultimedia}/documentmanager/getDocument/${facilityCode}/${documentName}`;
    return this.httpClient.get<any>(url, httpOptions)
      .pipe(
        map((returnData: any) => {
          // var downloadURL = URL.createObjectURL(returnData);
          var downloadURL = (window.URL ? URL : webkitURL).createObjectURL(returnData);
          return downloadURL;
        }),
        catchError(this.handleError<any>('getDocument'))
      );
  }

  getPatientUserInfo(userEmail: string): Observable<any> {
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorPrescription}/patientuser/get/${userEmail}`;
    return this.httpClient.get(url)
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError<any>('getPatientUserInfo'))
      );
  }

  private setThumbnaileUrlForMediaGroup(index: any): string {
    let url: any;
    switch (index % 9) {
      case 0:
        return url = "../../../assets/images/preview/hopi1.jpg";
      case 1:
        return url = '../../../assets/images/preview/hopi2.jpg';
      case 2:
        return url = '../../../assets/images/preview/hopi3.jpg';
      case 3:
        return url = '../../../assets/images/preview/hopi4.jpg';
      case 4:
        return url = '../../../assets/images/preview/hopi5.jpg';
      case 5:
        return url = "../../../assets/images/preview/hopi16.jpg";
      case 6:
        return url = '../../../assets/images/preview/hopi13.jpg';
      case 7:
        return url = '../../../assets/images/preview/hopi14.jpg';
      case 8:
        return url = '../../../assets/images/preview/hopi15.jpg';
    }
    return url
  }

  updatePatient(data: any): Observable<boolean> {
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorPrescription}/patientuser/replace`;
    return this.httpClient.put(url, data)
      .pipe(
        map((returnData: any) => {
          if (returnData) {
            this.toasterService.showSuccessMessage('UpdateSuccess')
            return returnData;
          }
        }),
        catchError(this.handleError<boolean>('updatePatient'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}