export const environment = {
  production: false,

  b2c_clientId: "d4c540b2-71c2-4c49-b0f2-19f0efcca604",
  names_signUpSignIn: "b2c_1_signupsignin1",
  names_forgotPassword: "b2c_1_reset",
  names_editProfile: "b2c_1_edit_profile",
  authority_signUpSignIn: "https://phoenixcustomerb2cdev.b2clogin.com/phoenixcustomerb2cdev.onmicrosoft.com/b2c_1_signupsignin1",
  authority_forgotPassword: "https://phoenixcustomerb2cdevb2clogin.com/phoenixcustomerb2cdev.onmicrosoft.com/b2c_1_reset",
  authority_editProfile: "https://phoenixcustomerb2cdev.b2clogin.com/phoenixcustomerb2cdev.onmicrosoft.com/b2c_1_edit_profile",
  authorityDomain: "phoenixcustomerb2cdev.b2clogin.com",
  b2c_api_scopes: 'https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read',
  b2c_api_uri: 'https://fabrikamb2chello.azurewebsites.net/hello',
  clientsecret: '',

  ocelotSelectorSurvey: "/s",
  ocelotSelectorLocation: "/l",
  ocelotSelectorMultimedia: "/m",
  ocelotSelectorPrescription: "/p",
  ocelotSelectorVimeo: "/v",

  gatewayAPIUrl: "https://mediastudio-dev.patientlogix.com/apigateway",//"http://localhost:9020",
  apiConfig_webApi: "https://mediastudio-dev.patientlogix.com/apigateway",//"http://localhost:9020/apigateway", 

  auth_redirectUri:"https://pilot.hopiflix.com/",// "http://localhost:4200", 
  auth_postLogoutRedirectUri:"https://pilot.hopiflix.com/",// "http://localhost:4200",

  azFctUrl: "https://phoenixazfctrecurring.azurewebsites.net",

  pdf_assets: "pdf_assets",

  cronosEdgeAPIUrl: "https://cronosedge-test.hopitel.com/mobileapigw-api",
  phoenixGatewayAPIUrl: "https://mediastudio-dev.patientlogix.com/apigateway",// "http://localhost:9020",

  radioBlobStorage: "https://cronosshellstorage.blob.core.windows.net/radio-thumbnail/",
  phoenixBlobStorage: "https://phoenixstorageaccountdev.blob.core.windows.net/",
  gamesBlobStorage: "https://cronosshellstorage.blob.core.windows.net/games-thumbnail/",
  movieBlobStorage: "https://cronosshellstorage.blob.core.windows.net/movie-thumbnails/",
  cronosEdgeDocumentUrl: "https://cronosedge-test.hopitel.com/assets/lgPdfViewer/generic_v2.2.228/web/viewer.html?file=/assets/pdf_asset",
  demoModeEnabled: true,
  patientlogixDocumentUrl: "https://mediastudio-dev.patientlogix.com/assets/pdf_assets",
  recaptcha: {
    siteKey: '6Lcmm28hAAAAAOCYFj2wi_e06Dw5i-HX-mirM2FS',
  },
  contactReceiver: 'dusong999@gmail.com',
  forgotPassword_url: 'https://phoenixcustomerb2cdev.b2clogin.com/phoenixcustomerb2cdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1__reset&client_id=d4c540b2-71c2-4c49-b0f2-19f0efcca604&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fpilot.hopiflix.com&scope=openid&response_type=code&prompt=login&code_challenge_method=S256&code_challenge=efZD47h_zbDEueDTh4NoJ2_pTnv-t01hyPRyB9bIDTE',
  rentalServiceTimeoutTime: 30,
  facilityTickerInterval: 300000,  //5 mintes??,
  advertisementPopupIntervalTime: 45 , //45 second  
  advertisementToastIntervalTime: 25 , //25 second  
  advertisementPopupCloseTime: 30 , //30 second  
  advertisementToastCloseTime: 20 , //20 second 
  advertisementInterval: 60 , //60 second 
  advertisementBannerInterval: 10 , //10 second
  updateBannerAdvertisementInterval: 3600000 , //60 minutes
  IpAddressInterval: 15000, // 15second  
}
