<div class="row" *ngIf="!isForCarousel">
   <ng-container [ngTemplateOutlet]="gameNoRecords" [ngTemplateOutletContext]="{gameSites:gameSites}"></ng-container>
   <div class="grid-lg-width col-md-3 col-sm-4 col-6" *ngFor="let gameSite of gameSites" [hidden]="gameSites.length==0">
       <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{gameSite:gameSite}"></ng-container>
   </div>
</div>

<div class="carousel" *ngIf="isForCarousel">
   <ng-container [ngTemplateOutlet]="gameNoRecords" [ngTemplateOutletContext]="{gameSites:gameSites}"></ng-container>
   <owl-carousel-o [options]="customOptions" *ngIf="gameSites.length>0">
       <ng-container *ngFor="let gameSite of gameSites">
           <ng-template carouselSlide [width]="170">
               <div class="movie type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                   <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{gameSite:gameSite}"></ng-container>
               </div>
           </ng-template>
       </ng-container>
   </owl-carousel-o>
</div>

<ng-template #gameNoRecords let-movie='gameSites'>
   <strong *ngIf="gameSites?.length==0">No Game to display!</strong>
</ng-template>

<ng-template #cardRef let-gameSite='gameSite'>
   <div class="gen-carousel-movies-style-2 movie-grid style-2">
      <div class="gen-movie-contain">
         <div class="gen-movie-img">
            <img [src]="gameSite?.thumbnailUrl?gamesBlobStorage+gameSite?.thumbnailUrl:defaultImagePath" alt="img">
            <ngx-icons [video]="gameSite"></ngx-icons>
            <div class="gen-movie-action">
               <a href="javascript:void(0)" (click)="checkUserLogInForPlayGames(gameSite.url,gameSite.isOnline)" class="gen-button gamebg">
                  <i class="fa fa-play"></i>
               </a>
            </div>
         </div>
         <div class="gen-info-contain">
            <div class="gen-movie-info">
               <h3><a href="javascript:void(0)">{{gameSite?.label}}</a>
               </h3>
            </div>
            <div class="gen-movie-meta-holder">
               <ul>
                  <li>35mins</li>
                  <li>
                     <a href="javascript:void(0)"><span class="game">Action</span></a>
                  </li>
               </ul>
               <br>
               <div
                  style="width:70px;height:39px;background-image: url('../../../assets/images/preview/channel-ico.png');">
               </div>
            </div>
         </div>
      </div>
   </div>
</ng-template>