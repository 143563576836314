import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GameSite, GameSiteData } from '../data/gameSite';
import { ApiUrlService } from '../utils/api-url.service';

@Injectable({
  providedIn: 'root'
})
export class GameService implements  GameSiteData {

  constructor(private httpClient: HttpClient, private urlServices: ApiUrlService) {}

  getAllGames(ceFacilityId:number): Observable<GameSite[]> {
    const url = `${this.urlServices.gameslUrl}/facilities/${ceFacilityId}/list`;
    return this.httpClient.get<GameSite[]>(url)
      .pipe(
        map((returnData: GameSite[]) => {
          return returnData;
        }),
        catchError(this.handleError<GameSite[]>('getAllGames', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
