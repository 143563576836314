import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiUrlService } from '../utils/api-url.service';
import { IptvChannel, IptvChannelData } from '../data/iptvChannel';
import { CommonService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class IptvService implements IptvChannelData {

  constructor(private httpClient: HttpClient, private urlServices: ApiUrlService, private commonService: CommonService) { }

  getRecommendedIptvChannels(ceFacilityId: number): Observable<IptvChannel[]> {
    const url = `${this.urlServices.iptvUrl}/facilities/${ceFacilityId}/recommended-iptv-channels`;
    return this.httpClient.get<IptvChannel[]>(url)
      .pipe(
        map((returnData: IptvChannel[]) => {
          let iptvChannel: Array<IptvChannel> = []
          if (returnData != null || returnData != undefined) {
            iptvChannel = this.setPatientPlaylist(returnData, ceFacilityId);
            const freeIptvChannels = iptvChannel.filter((x: any) => x.isFree).sort((a: any, b: any) => ((a.label) > (b.label)) ? 1 : -1);
            const notFreeIptvChannels = iptvChannel.filter((x: any) => !x.isFree).sort((a: any, b: any) => ((a.label) > (b.label)) ? 1 : -1);
            iptvChannel = freeIptvChannels.concat(notFreeIptvChannels);
          }
          return iptvChannel;
        }),
        catchError(this.handleError<IptvChannel[]>('getAllIptvChannel', []))
      );
  }

  getAllIptvChannel(ceFacilityId: number): Observable<IptvChannel[]> {
    const url = `${this.urlServices.iptvUrl}/facilities/${ceFacilityId}/iptv-channels`;
    return this.httpClient.get<IptvChannel[]>(url)
      .pipe(
        map((returnData: IptvChannel[]) => {
          returnData = this.setPatientPlaylist(returnData, ceFacilityId);
          let IptvChannels: Array<any> = [];
          const freeIptvChannels = returnData.filter((x: any) => x.isFree).sort((a: any, b: any) => ((a.label) > (b.label)) ? 1 : -1);
          const notFreeIptvChannels = returnData.filter((x: any) => !x.isFree).sort((a: any, b: any) => ((a.label) > (b.label)) ? 1 : -1);
          IptvChannels = freeIptvChannels.concat(notFreeIptvChannels);
          return IptvChannels;
        }),
        catchError(this.handleError<IptvChannel[]>('getAllIptvChannel', []))
      );
  }

  getIptvPlayList(videoIds: any, ceFacilityId: number): Observable<Array<IptvChannel>> {
    const url = `${this.urlServices.iptvUrl}/facilities/${ceFacilityId}/iptv-channel-list?idList=[${videoIds}]`
    return this.httpClient.get<Array<IptvChannel>>(url)
      .pipe(
        map((returnData: Array<IptvChannel>) => {
          let IptvChannels: Array<IptvChannel> = []
          if (returnData != null || undefined != null)
            IptvChannels = this.setPatientPlaylist(returnData, ceFacilityId);
          return IptvChannels;
        }),
        catchError(this.handleError<Array<IptvChannel>>(`getIptvPlayList`))
      );
  }

  setPatientPlaylist(IptvChannels: Array<IptvChannel>, ceFacilityId: number) {
    let jsonpatientPlaylist = this.commonService.getLSKey("patientPlaylist");
    if (jsonpatientPlaylist != '' && jsonpatientPlaylist != undefined) {
      const patientPlaylist = JSON.parse(jsonpatientPlaylist);
      if (patientPlaylist?.iptvs.length > 0) {
        IptvChannels.map(Iptv => {
          let index = patientPlaylist.iptvs.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == Iptv.id);
          if (index >= 0)
            Iptv.playlist = true;
          else
            Iptv.playlist = false;
        });
      }
    }
    return IptvChannels;
  }
 

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
