import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Movie } from 'src/app/@core/data/movie';
import { ApiUrlService, CommonService } from 'src/app/@core/utils';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PatientPlaylistItem } from 'src/app/@core/data/patientPlaylistItem';
import { Subscription } from 'rxjs';
import { MovieService } from 'src/app/@core/services/movie.service';

@Component({
  selector: 'ngx-movie-card',
  templateUrl: './movie-card.component.html',
})
export class MovieCardComponent implements OnInit {
  @Input() movies: Array<Movie> = [];
  @Input() isForCarousel: boolean = false;
  @Input() fromPlayList: boolean = false;
  defaultImagePath: string;
  movieBlobStorage: string;
  patientPlaylistItem: PatientPlaylistItem = {} as PatientPlaylistItem;
  customOptions: OwlOptions = {
    rtl: false,
    items: 6,
    loop: false,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,
    navText: ["<i class='ion-ios-arrow-back'></i>", "<i class='ion-ios-arrow-forward'></i>"],
    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: false
      },
      480: {
        items: 4,
        nav: true,
        dots: false
      },
      786: {
        items: 4
      },
      1023: {
        items: 5
      },
      1199: {
        items: 6
      }
    }
  }
  userChangeSubscription: Subscription;
  constructor(private router: Router, private commonService: CommonService, private apiUrlServices: ApiUrlService, private movieService: MovieService) {
    this.defaultImagePath = this.commonService.defaultImage();
    this.movieBlobStorage = this.apiUrlServices.movieBlobStorage;
    this.patientPlaylistItem.forMovie = true;
    // rebindPlayList
    this.userChangeSubscription = this.commonService.rebindPlayList.subscribe((res: any) => {
      if (res) {
        const ceFacilityId = this.commonService.getLSKey("ceFacilityId");
        this.movies = this.movieService.setPatientPlaylist(this.movies, ceFacilityId, true);
        this.commonService.rebindPlayList.next(false)
      }
    });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.userChangeSubscription.unsubscribe();
  }
  gotoMovieDetail(id: number) {
    this.router.navigate(['home/infotainment/movie/trailer', id]);
  }

  getUpdatedPlayList(video: any) {
    if (this.fromPlayList) {
      const index = this.movies.findIndex((x: any) => x.id == video.id);
      if (index >= 0)
        this.movies.splice(index, 1);
    }
  }
}
