import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Audiobook, AudiobookData, Audiobooks } from '../data/audiobook';
import { ApiUrlService } from '../utils/api-url.service';
import { CommonService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class AudiobookService implements AudiobookData {

  constructor(private httpClient: HttpClient, private urlServices: ApiUrlService, private commonService: CommonService) { }

  getAllAudiobooks(orderBy: string, ceFacilityId: number, pageIndex: number, pageSize: number, filters: string): Observable<any> {
    const langCode = this.commonService.getLanguage();
    const url = `${this.urlServices.entertainmentApiUrl}/AudiobookList/facilities/${ceFacilityId}/paged-items?sortBy=${orderBy}&pageSize=${pageSize}&pageIndex=${pageIndex}&filters=${filters}`;
    return this.httpClient.get<any>(url)
      .pipe(
        map((returnData: any) => {
          let audiobooks = this.setPatientPlaylist(returnData.data, ceFacilityId);
          const currentLanguageMovies = audiobooks?.filter((x: any) => x.lang.toLocaleLowerCase() == langCode.toLocaleLowerCase());
          const notCurrentLanguageMovies = audiobooks?.filter((x: any) => x.lang.toLocaleLowerCase() != langCode.toLocaleLowerCase());
          audiobooks = currentLanguageMovies.concat(notCurrentLanguageMovies);
          returnData.data = audiobooks;
          return returnData;
        }),
        catchError(this.handleError<Audiobooks>('getAllAudiobooks'))
      );
  }

  getRecommendedAudiobooks(ceFacilityId: number): Observable<Array<Audiobook>> {
    const langCode = this.commonService.getLanguage();
    const url = `${this.urlServices.entertainmentApiUrl}/AudiobookList/facilities/${ceFacilityId}/recommended-audiobooks`;
    return this.httpClient.get<Array<Audiobook>>(url)
      .pipe(
        map((returnData: Array<Audiobook>) => {
          let audiobooks: Array<Audiobook> = []
          if (returnData != null || returnData != undefined) {
            audiobooks = this.setPatientPlaylist(returnData, ceFacilityId);
            const currentLanguageMovies = audiobooks?.filter((x: any) => x.lang.toLocaleLowerCase() == langCode.toLocaleLowerCase());
            const notCurrentLanguageMovies = audiobooks?.filter((x: any) => x.lang.toLocaleLowerCase() != langCode.toLocaleLowerCase());
            audiobooks = currentLanguageMovies.concat(notCurrentLanguageMovies);
          }
          return audiobooks;
        }),
        catchError(this.handleError<Array<Audiobook>>('getRecommendedAudiobooks'))
      );
  }

  getAudiobookById(id: number): Observable<Audiobook> {
    const url = `${this.urlServices.entertainmentApiUrl}/AudiobookList/AudiobookList/${id}`;
    return this.httpClient.get<Audiobook>(url).pipe(
      catchError(this.handleError<Audiobook>(`getAudiobookById id=${id}`))
    );
  }

  getAudiobookPlayList(videoIds: any, ceFacilityId: number): Observable<Array<Audiobook>> {
    const url = `${this.urlServices.entertainmentApiUrl}/AudiobookList/facilities/${ceFacilityId}/audiobooklist-list?idList=[${videoIds}]`
    return this.httpClient.get<Array<Audiobook>>(url)
      .pipe(
        map((returnData: Array<Audiobook>) => {
          let audiobooks: Array<Audiobook> = []
          if (returnData != null || undefined != null)
          audiobooks = this.setPatientPlaylist(returnData, ceFacilityId);
          return audiobooks;
        }),
        catchError(this.handleError<Array<Audiobook>>(`getAudiobookPlayList`))
      );
  }

  setPatientPlaylist(audiobooks: Array<Audiobook>, ceFacilityId: number) {
    let jsonpatientPlaylist = this.commonService.getLSKey("patientPlaylist");
    if (jsonpatientPlaylist != '' && jsonpatientPlaylist != undefined) {
      const patientPlaylist = JSON.parse(jsonpatientPlaylist);
      if (patientPlaylist?.audiobooks.length > 0) {
        audiobooks.map(audiobook => {
          let index = patientPlaylist.audiobooks.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == audiobook.id);
          if (index >= 0)
            audiobook.playlist = true;
          else
            audiobook.playlist = false;
        });
      }
    }
    return audiobooks;
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
