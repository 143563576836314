<div class="gen-breadcrumb-container">
    <ol class="breadcrumb">
        <ng-container class="breadcrumb-item" *ngFor="let breadcrumb of bcLoadedData ;let i = index">
            <li class="breadcrumb-item" *ngIf="breadcrumb.code=='kids'">
                <span *ngIf="!hasEnCulture">
                    <span class="red">E</span><span class="blue">n</span><span class="green">f</span><span
                        class="pink">a</span><span class="orange">n</span><span class="white">t</span><span
                        class="yellow">s</span>
                </span>
                <span *ngIf="hasEnCulture">
                    <span class="red">K</span><span class="blue">i</span><span class="green">d</span><span
                        class="yellow">s</span>
                </span>
            </li>
            <li class="breadcrumb-item" *ngIf="!isTileview">
                <a *ngIf="bcLoadedData[0].text==breadcrumb.text" [routerLink]="['/home/landing']"
                    routerLinkActive="active"><i class="fas fa-home mr-2"></i>{{breadcrumb.text}}</a>
                <a *ngIf="breadcrumb.code=='meal-menu'"
                    [style.color]="bcLoadedData[bcLoadedData.length-1].text==breadcrumb.text? dynamicColor: ''"
                    [routerLink]="['/home/infotainment/music']">{{breadcrumb.text}}</a>
                <a *ngIf="breadcrumb.code=='music'"
                    [style.color]="bcLoadedData[bcLoadedData.length-1].text==breadcrumb.text? dynamicColor: ''"
                    [routerLink]="['/home/infotainment/music']">{{breadcrumb.text}}</a>
                <span [style.color]="bcLoadedData[bcLoadedData.length-1].text==breadcrumb.text? dynamicColor: ''"
                    *ngIf="bcLoadedData[0].text!=breadcrumb.text && breadcrumb.code!='meal-menu' && breadcrumb.code!='music' && breadcrumb.code!='kids'">
                    {{breadcrumb.text}}</span>
            </li>

            <li class="breadcrumb-item" *ngIf="isTileview && breadcrumb.code!='kids'">
                <a routerLinkActive="active" [routerLink]="breadcrumb?.routerUrl"
                    *ngIf="bcLoadedData?.length-1!=i"><i class="fas fa-home mr-2"
                        *ngIf="bcLoadedData[0].text==breadcrumb.text"></i>{{breadcrumb.text}}</a>
                <span *ngIf="bcLoadedData?.length-1==i" [style.color]="dynamicColor"><i class="fas fa-home mr-2"
                        *ngIf="bcLoadedData[0].text==breadcrumb.text"></i>
                    {{breadcrumb.text}}</span>
            </li>
        </ng-container>
    </ol>
</div>