import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'ngx-patient-info-card',
  templateUrl: './patient-info-card.component.html',
  styleUrls: ['./patient-info-card.component.scss']
})
export class PatientInfoCardComponent implements OnInit {
  @Input() patientInfos: Array<any> = new Array<any>();
  @Input() isForCarousel: boolean = false;
  customOptions: OwlOptions = {
    rtl: false,
    items: 6,
    loop: false,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,
    navText: ["<i class='ion-ios-arrow-back'></i>", "<i class='ion-ios-arrow-forward'></i>"],
    responsive: {
      0: {
          items: 2,
          nav: true,
          dots: false
      },
      480: {
          items: 4,
          nav: true,
          dots: false
      },
      786: {
          items: 4
      },
      1023: {
          items: 5
      },
      1199: {
          items: 6
      }
    }
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}
