import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NbDialogService } from '@nebular/theme';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { IptvChannel } from 'src/app/@core/data/iptvChannel';
import { PatientPlaylistItem } from 'src/app/@core/data/patientPlaylistItem';
import { IptvService } from 'src/app/@core/services/iptv.service';
import { CommonService } from 'src/app/@core/utils';
import { ServiceNotAccessComponent } from 'src/app/pages/service-not-access/service-not-access.component';

@Component({
  selector: 'ngx-iptv-card',
  templateUrl: './iptv-card.component.html',
  styleUrls: ['./iptv-card.component.scss']
})
export class IptvCardComponent implements OnInit {
  @Input() IptvChannels: Array<IptvChannel> = [];
  @Input() isForCarousel: boolean = false;
  @Input() fromPlayList: boolean = false;
  popupModal: any;
  customOptions: OwlOptions = {
    rtl: false,
    items: 6,
    loop: false,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,
    navText: ["<i class='ion-ios-arrow-back'></i>", "<i class='ion-ios-arrow-forward'></i>"],
    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: false
      },
      480: {
        items: 4,
        nav: true,
        dots: false
      },
      786: {
        items: 4
      },
      1023: {
        items: 5
      },
      1199: {
        items: 6
      }
    }
  }
  patientPlaylistItem: PatientPlaylistItem = {} as PatientPlaylistItem;
  userChangeSubscription: Subscription;

  constructor(private commonService: CommonService, private authService: MsalService, private dialogService: NbDialogService, private iptvService: IptvService, private router: Router) {
    this.patientPlaylistItem.forIptv = true;
    // rebindPlayList
    this.userChangeSubscription = this.commonService.rebindPlayList.subscribe((res: any) => {
      if (res) {
        const ceFacilityId = this.commonService.getLSKey("ceFacilityId");
        this.IptvChannels = this.iptvService.setPatientPlaylist(this.IptvChannels, ceFacilityId);
        this.commonService.rebindPlayList.next(false)
      }
    });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.userChangeSubscription?.unsubscribe();
    this.closePopup();
  }

  checkUserLogInForIptvChannel(video: any): void {
    const account = this.authService.instance.getAllAccounts()[0];
    if (account)
      this.openIptvChannel(video);
    else {
      this.commonService.setLSKey('purl', { key: 'IPTV_TRAILER', id: video });
      this.authService.loginRedirect();
    }
  }

  openIptvChannel(video: any): void {
    this.commonService.isFreeIptvChannel = video?.isFree;
    this.setLSKey(video);
    // if (video?.isFree)
    //   this.setLSKey(video);
    // else {
    //   this.commonService.checkRentedService("TV_BYOD").subscribe(data => {
    //     if (data.body)
    //       this.setLSKey(video);
    //     else
    //       this.servceNotAccess();
    //   });
    // }
  }

  servceNotAccess() {
    this.popupModal = this.dialogService.open(ServiceNotAccessComponent, { hasBackdrop: true, closeOnBackdropClick: false, autoFocus: false });
  }

  getUpdatedPlayList(video: any) {
    if (this.fromPlayList) {
      const index = this.IptvChannels.findIndex((x: any) => x.id == video.id);
      if (index >= 0)
        this.IptvChannels.splice(index, 1);
    }
  }

  closePopup() {
    this.popupModal?.close();
  }

  setLSKey(video: any) {
    if (this.fromPlayList)
      this.commonService.setLSKey('purl', { key: 'MY_PLAYLIST' });
    else if (this.isForCarousel)
      this.commonService.setLSKey('purl', { key: 'LANDING_CARD' });
    else
      this.commonService.setLSKey('purl', { key: 'IPTV_TRAILER' });
    this.router.navigate(['/home/infotainment/iptv/viewer', { iptvVideo: JSON.stringify(video) }]);
  }
}
