import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MediaScheduler, MediaSchedulerData } from '../data/media-scheduler';
import { ApiUrlService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class MediaSchedulerService implements MediaSchedulerData {

  constructor(private apiUrlService: ApiUrlService, private httpClient: HttpClient) { }

  getScheduleForDay(date: string, facilityCode: string, terminal: string): Observable<MediaScheduler> {

    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorMultimedia}/MediaWeeklySchedule/getScheduleForDay?date=${date}&facilityCode=${facilityCode}&terminal=${terminal}`;
    return this.httpClient.get<MediaScheduler>(url)
      .pipe(
        map((returnData: MediaScheduler) => {
          return returnData;
        }),
        catchError(this.handleError<MediaScheduler>('getScheduleForDay'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
