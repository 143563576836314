<!-- Log-in  -->
<section class="position-relative pb-0">
    <div class="gen-login-page-background" style="background-image: url('../../../../assets/images/backgrounds/general.jpg');"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center">
                    <form name="pms_login" id="pms_login" action="#" method="post">
                        <div align="right"><IMG SRC="../../../../assets/images/backgrounds/logo-1xs.png" BORDER="0" ALT=""></div>
                        <h4>Sign In</h4>
                        <p class="login-username">
                            <label for="user_login">Username (Email Address)</label>
                            <input type="text" name="log" id="user_login" class="input" value="" size="20">
                        </p>
                        <p class="login-password">
                            <label for="user_pass">Password</label>
                            <input type="password" name="pwd" id="user_pass" class="input" value="" size="20">
                        </p>
                        <p class="login-remember">
                            <label>
                                <input name="rememberme" type="checkbox" id="rememberme" value="forever"> Remember
                                Me </label>
                        </p>
                        <p class="login-submit">

                            <input type="button" action="action" onclick="window.history.go(-1); return false;"
                                name="wp-submit" id="wp-submit" class="button button-primary" value="BACK"
                                style="float:left;">

                            <input type="submit" name="wp-submit" id="wp-submit" class="button button-primary"
                                value="Sign In" style="float:right;">

                        </p>
                        <a [routerLink]="['/sign-up']" routerLinkActive="true" >Sign Up</a> | <a href="javascript:void(0)">Lost your
                            password?</a>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Log-in  -->