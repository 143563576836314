import { Observable } from "rxjs";

export interface GameSite {
    id: number;
    url: string;
    thumbnailUrl: string;
    description: string;
    label: string;
    labelFr: string;
    isOnline: boolean;
    facilityId: number;
    playlist:boolean;
}

export abstract class GameSiteData {
    abstract getAllGames(ceFacilityId:number): Observable<GameSite[]>
}
