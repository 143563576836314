import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './pages/landing/landing.component';

const routes: Routes = [
  { path: 'home', loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule)},
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "**", redirectTo: "", pathMatch: "full" },
  { path: "error", component: LandingComponent },// Needed for hash routing
  { path: "state", component: LandingComponent },// Needed for hash routing
  { path: "code", component: LandingComponent } // Needed for hash routing
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
