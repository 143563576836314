import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/@core/utils';

@Component({
  selector: 'ngx-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
    this.commonService.setHeaderState(false);
    this.commonService.setFooterState(false);
  }
}
