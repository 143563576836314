import { Observable } from "rxjs";
import { Facility } from "./facility";
import { I18nString } from "./i18nString";
import { Language } from "./language";
import { Page } from "./page";
import { Patient } from "./patient";
import { SurveyCategory } from "./surveyCategory";
import { SurveyType } from "./surveyType";

export interface SurveyQuestionnaire {
    id: string;
    titleText: string;
    surveyTitle: I18nString;
    title: I18nString[];
    surveyDescription: I18nString;
    description: I18nString[];
    surveyCategoryId: SurveyCategory;
    surveyTypeId: SurveyType;
    surveyCategory: SurveyCategory;
    typeText: string;
    surveyType: SurveyType;
    statusText: string;
    surveyStatus: any;
    facility: Facility;
    accessCode: string;
    confirmAccessCode: string;
    defaultLanguageId: Language;
    defaultLanguage: Language;
    surveyPage: Page;
    page: Page[];
    accessPermission: any;
    modifiedDate: Date;
    publishDate: Date;
    closeDate: Date;
    startDate: Date;
    endDate: Date;
    menuItems: any[];
    showGlobe: boolean;
    languageCode: string;
    surveyScored: boolean;
    minimumScore: number;
    surveyTimestamp: any;
    surveyAnswerCount: number;
    facilityLogo: string;
    thumbnailName: string;
    patientUser: Patient;
    i18nTitle: string;
    thumbnail: string;
    mediaId: string;
    documentId: string;
    prescriptionId: string;

}

export abstract class SurveyQuestionnaireData {
    abstract surveyCreateResponse(prescriptionId: string, mediaId: string, survey: SurveyQuestionnaire, patientUserInfo: Patient, documentId: string): Observable<boolean>
    abstract surveyValidatePassword(password: any, surveyId: any): Observable<boolean>;
    abstract getSurveyQuestionnaire(id: string, isAccessible: boolean): Observable<SurveyQuestionnaire>;
}