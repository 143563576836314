<div class="row" *ngIf="!isForCarousel">
    <ng-container [ngTemplateOutlet]="iptvNoRecords"
        [ngTemplateOutletContext]="{IptvChannels:IptvChannels}"></ng-container>
    <div class="grid-lg-width col-md-3 col-sm-4 col-6" *ngFor="let iptvVideo of IptvChannels"
        [hidden]="IptvChannels.length==0">
        <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{iptvVideo:iptvVideo}"></ng-container>
    </div>
</div>

<div class="carousel" *ngIf="isForCarousel">
    <ng-container [ngTemplateOutlet]="iptvNoRecords"
        [ngTemplateOutletContext]="{IptvChannels:IptvChannels}"></ng-container>
    <owl-carousel-o [options]="customOptions" *ngIf="IptvChannels.length>0">
        <ng-container *ngFor="let iptvVideo of IptvChannels">
            <ng-template carouselSlide [width]="170" [id]="iptvVideo.label">
                <div
                    class="type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                    <ng-container [ngTemplateOutlet]="cardRef"
                        [ngTemplateOutletContext]="{iptvVideo:iptvVideo}"></ng-container>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>

<ng-template #iptvNoRecords let-movie='IptvChannels'>
    <strong *ngIf="IptvChannels?.length==0" i18n="@@iptv-card.noiptv">No IPTV to display!</strong>
</ng-template>

<ng-template #cardRef let-iptvVideo='iptvVideo'>
    <div class="gen-carousel-movies-style-2 movie-grid style-2">
        <div class="gen-movie-contain">
            <div class="gen-movie-img">
                <img [src]="iptvVideo.thumbnail" alt="owl-carousel-video-image">
                <ngx-icons [video]="iptvVideo" [patientPlaylistItem]="patientPlaylistItem" (newPlayList)="getUpdatedPlayList($event)"></ngx-icons>
                <div class="gen-movie-action">
                    <a href="javascript:void(0)" (click)="checkUserLogInForIptvChannel(iptvVideo)"
                        class="gen-button iptvbg">
                        <i class="fa fa-play"></i>
                    </a>
                </div>
            </div>
            <div class="gen-info-contain">
                <div class="gen-movie-info">
                    <h3><a href="javascript:void(0)" (click)="checkUserLogInForIptvChannel(iptvVideo)">{{iptvVideo.label}}</a>
                    </h3>
                </div>
                <div class="gen-movie-meta-holder">
                    <!-- <ul>
                        <li>
                            <a href="javascript:void(0)"><span style="cursor: default;">{{iptvVideo.description}}</span></a>
                        </li>
                    </ul>
                    <br> -->
                    <div
                        style="width:70px;height:39px;background-image: url('../../../assets/images/preview/channel-ico.png');">
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>