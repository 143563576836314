import { Observable } from "rxjs";

export interface RadioChannel {
    id: number;
    label: string;
    thumbnailUrl: string;
    genreId: number;
    facilityId: number;
    url: string;
    playlist:boolean;
}
export abstract class RadioChannelData {
    abstract getAllRadioChannels(ceFacilityId: number): Observable<RadioChannel[]>;
    abstract getRecommendedRadioChannels(ceFacilityId: number): Observable<RadioChannel[]>;
    abstract getRadioPlayList(videoIds: any, ceFacilityId: number): Observable<Array<RadioChannel>>
}
