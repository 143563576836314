import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { I18nString } from '../data/i18nString';
import { Page } from '../data/page';
import { Patient } from '../data/patient';
import { QuestionChoice } from '../data/questionChoice';
import { SurveyQuestion } from '../data/surveyQuestion';
import { SurveyQuestionnaire, SurveyQuestionnaireData } from '../data/SurveyQuestionnaire';
import { ApiUrlService } from '../utils/api-url.service';
import { CommonService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class SurveyService implements SurveyQuestionnaireData {
  langCode: string = 'en';
  constructor(private apiUrlService: ApiUrlService, private httpClient: HttpClient,private commonService:CommonService) { }

  private getMenuItems(statusId: number): any[] {
    const menuItems = [{
      text: 'Preview',
      iconClass: 'k-icon k-i-eye',
      path: 'preview',
      isShow: this.showHideOption(statusId, 'preview'),
      screenCode: true
    }, {
      text: 'View',
      iconClass: 'k-icon k-i-eye',
      path: 'view',
      isShow: this.showHideOption(statusId, 'view'),
      screenCode: true
    }, {
      text: 'Duplicate',
      iconClass: 'k-icon k-i-windows',
      path: 'duplicate',
      isShow: true,
      screenCode: ''
    }, {
      text: 'Delete',
      iconClass: 'k-icon k-i-delete',
      path: 'delete',
      isShow: true,
      screenCode: ''
    }, {
      text: 'Edit',
      iconClass: 'k-icon k-i-pencil',
      path: 'edit',
      isShow: this.showHideOption(statusId, 'edit'),
      screenCode: true
    }, {
      text: 'Publish',
      iconClass: 'k-icon k-i-upload',
      path: 'publish',
      isShow: this.showHideOption(statusId, 'publish'),
      screenCode: ''
    }, {
      text: 'Close',
      iconClass: 'k-icon k-i-hyperlink-open-sm',
      path: 'close',
      isShow: this.showHideOption(statusId, 'close'),
      screenCode: ''
    }, {
      text: 'Translate',
      iconClass: 'k-icon k-i-mdb',
      path: 'translate',
      isShow: true,
      screenCode: true
    }];
    return menuItems;
  }

  public showHideOption(statusId: number, path: string): boolean {
    switch (path) {
      case 'view':
        if (statusId !== 1) {
          return true;
        } else {
          return false;
        }
        break;
      case 'duplicate':
      case 'delete':
      case 'translate':
        return true;
      case 'publish':
      case 'close':
      case 'edit':
      case 'preview':
        if (statusId === 1) {
          return true;
        } else {
          return false;
        }
        break;
      default:
        return false;
    }
  }

  surveyCreateResponse(prescriptionId: string, mediaId: string, survey: SurveyQuestionnaire, patientUserInfo: Patient, documentId: string): Observable<boolean> {
 
    let payload = {
      prescriptionId: prescriptionId,
      mediaId: mediaId,
      survey: survey,
      documentId: documentId
    };

    if (prescriptionId != null) {
      const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorPrescription}/PrescriptionExecuted/createResponse`;
      //survey.patient = patientUserInfo;

      return this.httpClient.post<boolean>(url, payload)
        .pipe(
          map((returnData: boolean) => {
            return returnData;
          }),
          catchError(this.handleError<any>(`SurveyCreateResponse url=${url}`))
        );
    }

    else { //Submit General Survey
      const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorSurvey}/Response/create`;
      let payload: any;

      payload.surveyId = survey.id;
      payload.patientUser = patientUserInfo;

      for (let i = 0; i < survey.page.length; i++) {
        const page = survey.page[i];
        for (let j = 0; j < page.question.length; j++) {
          const question = page.question[j];
          const questionType = question.questionType.inputType;
          for (let k = 0; k < question.choice.length; k++) {
            const choice = question.choice[k];
            if ((questionType == 'radio' || questionType == 'checkbox') && choice.responseChoiceSelected) {
              let choiceResponse: any;
              choiceResponse.pageId = page.id;
              choiceResponse.questionId = question.id;
              choiceResponse.choiceId = choice.id;
              choiceResponse.choiceBooleanValue = choice.responseChoiceSelected;
              payload.reponses.push(choiceResponse);
            }
            else if (questionType == 'text' && choice.responseTextEntered && choice.responseTextEntered.length > 0) {
              let choiceResponse: any;
              choiceResponse.pageId = page.id;
              choiceResponse.questionId = question.id;
              choiceResponse.choiceId = choice.id;
              choiceResponse.choiceTextValue = choice.responseTextEntered;

              payload.reponses.push(choiceResponse);
            }
          }
        }
      }

      return this.httpClient.post(url, payload, { responseType: 'text' })
        .pipe(
          map((returnData: any) => {
            return returnData;
          }),
          catchError(this.handleError<any>(`SurveyCreateResponse url=${url}`))
        );
    }
  }

  surveyValidatePassword(password: any, surveyId: any): Observable<boolean> {
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorSurvey}/SurveyQuestionnaire/validatePassword?password=${password}&surveyId=${surveyId}`;

    return this.httpClient.get<boolean>(url)
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError<boolean>('SurveyValidatePassword', false))
      );
  }

  getSurveyQuestionnaire(id: string, isAccessible: boolean): Observable<SurveyQuestionnaire> {
    this.langCode=this.commonService.getLanguage();
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorSurvey}/surveyquestionnaire/getByIdWithLogo/${id}`;

    return this.httpClient.get(url)
      .pipe(
        map((response: any) => {
          let returnData = response.survey;
          let survey: SurveyQuestionnaire = {} as SurveyQuestionnaire;
          let surveyTitle:I18nString = {} as I18nString;
          survey.surveyTitle=surveyTitle;
          let surveyDescription:I18nString = {} as I18nString;
          survey.surveyDescription=surveyDescription;
          survey.facilityLogo = response.facilityLogo;

          const defaultLanguage = "en"; //this.commonService.getDefaultLanguage();

          //Get 
          let sType = returnData.surveyType.names.filter((x: any) => x.languageCode === this.langCode);
          if (sType.length === 0) {
            sType = returnData.surveyType.names.filter((x: any) => x.languageCode === defaultLanguage);
            sType = sType.length === 0 ? returnData.surveyType.names[0] : sType;
          }

          let sStatus = returnData.surveyStatus.name.filter((x: any) => x.languageCode === this.langCode);
          if (sStatus.length === 0) {
            sStatus = returnData.surveyStatus.name.filter((x: any) => x.languageCode === defaultLanguage);
            sStatus = sStatus.length === 0 ? returnData.surveyStatus.name[0] : sStatus;
          }

          survey.id = returnData.id;
          survey.title = returnData.title;
          survey.description = returnData.description;
          survey.surveyCategoryId = returnData.surveyCategory.id;
          survey.surveyCategory = returnData.surveyCategory;
          survey.surveyTypeId = returnData.surveyType.id;
          survey.surveyType = returnData.surveyType;
          survey.typeText = (sType[0] == '' || sType[0] == null) ? '' : sType[0].text;
          survey.surveyStatus = returnData.surveyStatus;
          survey.statusText = (sStatus[0] == '' || sStatus[0] == null) ? '' : sStatus[0].text;
          survey.facility = returnData.facility;
          survey.accessCode = returnData.accessCode;
          survey.confirmAccessCode = returnData.accessCode;
          survey.defaultLanguageId = (returnData.defaultLanguage == '' || returnData.defaultLanguage == null) ? '' : returnData.defaultLanguage.id;

          survey.defaultLanguage = returnData.defaultLanguage;
          survey.accessPermission = returnData.accessPermission;
          survey.modifiedDate = returnData.modifiedDate;
          survey.endDate = returnData.endDate;
          survey.publishDate = returnData.publishDate;
          survey.closeDate = returnData.closeDate;
          survey.startDate = returnData.startDate;
          survey.endDate = returnData.endDate;
          survey.minimumScore = returnData.minimumScore;
          survey.surveyScored = returnData.surveyScored;
          survey.surveyTimestamp = returnData.surveyTimestamp;
          survey.surveyAnswerCount = returnData.surveyAnswerCount;
          survey.page = this.mapPageDetail(returnData.page);
          survey.menuItems = this.getMenuItems(returnData.surveyStatus.id);

          if (returnData.title.length > 0) {
            let filteredName = returnData.title.filter((x: any) => x.languageCode === this.langCode);
            if (filteredName.length) {
              survey.surveyTitle.languageCode = filteredName[0].languageCode;
              survey.surveyTitle.text = filteredName[0].text;
            } else {
              survey.surveyTitle.languageCode = returnData.title[0].languageCode;
              survey.surveyTitle.text = returnData.title[0].text;
            }
          }

          if (returnData.description.length > 0) {
            let filteredName = returnData.description.filter((x: any) => x.languageCode === this.langCode);
            if (filteredName.length) {
              survey.surveyDescription.languageCode = filteredName[0].languageCode;
              survey.surveyDescription.text = filteredName[0].text;
            } else {
              survey.surveyDescription.languageCode = returnData.title[0].languageCode;
              survey.surveyDescription.text = returnData.title[0].text;
            }
          }
          return survey;
          // }
        }),
        catchError(this.handleError<SurveyQuestionnaire>('getSurveyQuestionnaire'))
      );
  }

  private mapPageDetail(pagis: any[]): Page[] {
    let pages: any = [];
    pagis.forEach((element, idx) => {
      let page: Page = {} as Page;
      let pageTitle:I18nString = {} as I18nString;
      page.pageTitle=pageTitle;
      let pageDescription:I18nString = {} as I18nString;
      page.pageDescription=pageDescription;
      pages.push(page);
      pages[idx].title = element.title;
      pages[idx].id = element.id;
      pages[idx].description = element.description;
      pages[idx].question = this.mapQuestionDetail(element.question);
      const filteredName = element.title.filter((x: any) => x.languageCode === this.langCode);
      if (filteredName.length) {
        pages[idx].pageTitle.languageCode = filteredName[0].languageCode;
        pages[idx].pageTitle.text = filteredName[0].text;
        pages[idx].name = idx + 1 + '. ' + filteredName[0].text;
      } else {
        pages[idx].pageTitle.languageCode = element.title[0].languageCode;
        pages[idx].pageTitle.text = element.title[0].text;
        pages[idx].name = idx + 1 + '. ' + element.title[0].text;
      }
      const filteredDescription = element.description.filter((x: any) => x.languageCode === this.langCode);
      if (filteredDescription.length) {
        pages[idx].pageDescription.languageCode = filteredDescription[0].languageCode;
        pages[idx].pageDescription.text = filteredDescription[0].text;
      } else {
        pages[idx].pageDescription.languageCode = element.description[0].languageCode;
        pages[idx].pageDescription.text = element.description[0].text;
      }
    });
    return pages;
  }

  private mapQuestionDetail(question: any[]): SurveyQuestion[] {
    let questions: any = [];
    question.forEach((element, idx) => {
      let surveyQuestion: SurveyQuestion = {} as SurveyQuestion;
      let questionTitle:I18nString = {} as I18nString;
      surveyQuestion.questionTitle=questionTitle;
      questions.push(surveyQuestion);
      questions[idx].id = element.id;
      questions[idx].title = element.title;
      questions[idx].optional = element.optional;
      questions[idx].displayOrder = element.displayOrder;
      questions[idx].questionType = element.questionType;
      questions[idx].imageUrl = element.imageUrl;
      questions[idx].questionTypeId = element.questionType.id;
      questions[idx].choice = this.mapChoiceDetail(element.choice);
      const filteredName = element.title.filter((x: any) => x.languageCode === this.langCode);
      if (filteredName.length) {
        questions[idx].questionTitle.languageCode = filteredName[0].languageCode;
        questions[idx].questionTitle.text = filteredName[0].text;
        questions[idx].name = filteredName[0].text;
      } else {
        questions[idx].questionTitle.languageCode = element.title[0].languageCode;
        questions[idx].questionTitle.text = element.title[0].text;
        questions[idx].name = element.title[0].text;
      }
      questions[idx].isAnswered = false;
    });
    return questions;
  }

  private mapChoiceDetail(choice: any[]): any {
    let choices: any = [];
    choice.forEach((element, idx) => {
    let questionChoice: QuestionChoice = {} as QuestionChoice;
     let questionTitle:I18nString = {} as I18nString;
      questionChoice.questionChoice=questionTitle;
      choices.push(questionChoice);
      choices[idx].id = element.id;
      choices[idx].isCorrect = element.isCorrect;
      choices[idx].points = element.points;
      choices[idx].choiceLabel = element.choiceLabel;
      choices[idx].imageUrl = element.imageUrl;
      const filteredName = element.choiceLabel.filter((x: any) => x.languageCode === this.langCode);
      if (filteredName.length) {
        choices[idx].questionChoice.languageCode = filteredName[0].languageCode;
        choices[idx].questionChoice.text = filteredName[0].text;
      } else {
        choices[idx].questionChoice.languageCode = element.choiceLabel[0].languageCode;
        choices[idx].questionChoice.text = element.choiceLabel[0].text;
      }
    });
    return choices;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
