import { Observable } from "rxjs";

export interface PatientPlaylist {
    id: string;
    patientUser: any;
    movies: Array<PlaylistItem>;
    audiobooks: Array<PlaylistItem>;
    music: Array<PlaylistItem>;
    kidsVideos:Array<PlaylistItem>;
    relaxationVideos: Array<PlaylistItem>;
    radios: Array<PlaylistItem>;
    iptvs:Array<PlaylistItem>;
}
export interface PlaylistItem {
    videoId: number;
    ceFacilityId: number;
}


export abstract class PatientPlaylistData {
    abstract getPatientPlaylist(): Observable<PatientPlaylist>;
    abstract createPatientPlaylist(patientPlaylistItem: any): Observable<boolean>;
}
