import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiUrlService } from '../utils/api-url.service';
import { Kids, KidsData } from '../data/Kids';
import { CommonService } from '../utils';
import { Movie, Movies } from '../data/movie';

@Injectable({
  providedIn: 'root'
})
export class KidsService implements KidsData {

  constructor(private httpClient: HttpClient, private urlServices: ApiUrlService, private commonService: CommonService) { }

  getRecommendedKidsVideos(ceFacilityId: number, langCode: string): Observable<Kids[]> {
    const url = `${this.urlServices.moviesUrl}/facilities/${ceFacilityId}/kids-movies?language=${langCode}`;
    return this.httpClient.get<Kids[]>(url)
      .pipe(
        map((returnData: Kids[]) => {
          let kidsVideos: Array<Kids> = []
          if (returnData != null || returnData != undefined) {
            kidsVideos = this.setPatientPlaylist(returnData, ceFacilityId);
            kidsVideos = kidsVideos?.sort((a: any, b: any) => ((a.title) > (b.title)) ? 1 : -1);
            kidsVideos = kidsVideos?.sort((a: any, b: any) => (Number((a.displayPriority)) < Number((b.displayPriority))) ? 1 : -1);
          }
          return kidsVideos;
        }),
        catchError(this.handleError<any[]>('getRecommendedKidsVideos', []))
      );
  }

  getKids(orderBy: string, ceFacilityId: number, pageIndex: number, pageSize: number, filters: string): Observable<Movies> {
    const url = `${this.urlServices.moviesUrl}/facilities/${ceFacilityId}/items?sortBy=${orderBy}&pageSize=${pageSize}&pageIndex=${pageIndex}&filters=${filters}`;
    return this.httpClient.get<Movies>(url)
      .pipe(
        map((returnData: Movies) => {
          let kidsVideos = this.commonService.setGenresName(returnData.data);
          kidsVideos = this.setPatientPlaylist(kidsVideos, ceFacilityId);
          kidsVideos = kidsVideos?.sort((a: any, b: any) => ((a.title) > (b.title)) ? 1 : -1);
          kidsVideos = kidsVideos?.sort((a: any, b: any) => (Number((a.displayPriority)) < Number((b.displayPriority))) ? 1 : -1);
          return { count: returnData.count, data: kidsVideos };
        }),
        catchError(this.handleError<Movies>('getMovies'))
      );
  }

  getKidsPlayList(videoIds: any, ceFacilityId: number): Observable<Array<Movie>> {
    const url = `${this.urlServices.moviesUrl}/facilities/${ceFacilityId}/movie-list?movieIdList=[${videoIds}]`
    return this.httpClient.get<Array<Movie>>(url)
      .pipe(
        map((returnData: Array<Movie>) => {
          let movies: Array<Movie> = []
          if (returnData != null || returnData != undefined)
          {
            movies = this.commonService.setGenresName(returnData);
            movies = this.setPatientPlaylist(returnData, ceFacilityId);
          }
      
          return movies;
        }),
        catchError(this.handleError<Array<Movie>>(`getKidsPlayList`))
      );
  }

  setPatientPlaylist(kidsVideos: Array<any>, ceFacilityId: number) {
    let jsonpatientPlaylist = this.commonService.getLSKey("patientPlaylist");
    if (jsonpatientPlaylist != '' && jsonpatientPlaylist != undefined) {
      const patientPlaylist = JSON.parse(jsonpatientPlaylist);
      if (patientPlaylist?.kidsVideos.length > 0) {
        kidsVideos.map(kidsVideo => {
          let index = patientPlaylist.kidsVideos.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == kidsVideo.id);
          if (index >= 0)
            kidsVideo.playlist = true;
          else
            kidsVideo.playlist = false;
        });
      }
    }
    return kidsVideos;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
