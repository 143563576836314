import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RadioChannel, RadioChannelData } from '../data/radioChannel';
import { ApiUrlService } from '../utils/api-url.service';
import { CommonService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class RadioService implements RadioChannelData {

  constructor(private httpClient: HttpClient, private urlServices: ApiUrlService, private commonService: CommonService) { }

  getAllRadioChannels(ceFacilityId: number): Observable<RadioChannel[]> {
    const url = `${this.urlServices.radioUrl}/facilities/${ceFacilityId}/list`;
    return this.httpClient.get<RadioChannel[]>(url)
      .pipe(
        map((returnData: RadioChannel[]) => {
          let radioChannel: Array<RadioChannel> = []
          if (returnData != null || returnData != undefined)
            radioChannel = this.setPatientPlaylist(returnData, ceFacilityId);
          return radioChannel;
        }),
        catchError(this.handleError<RadioChannel[]>('getAllRadioChannels', []))
      );
  }

  getRecommendedRadioChannels(ceFacilityId: number): Observable<RadioChannel[]> {
    const url = `${this.urlServices.radioUrl}/facilities/${ceFacilityId}/recommended-radio-channels`;
    return this.httpClient.get<RadioChannel[]>(url)
      .pipe(
        map((returnData: RadioChannel[]) => {
          let radioChannel: Array<RadioChannel> = []
          if (returnData != null || returnData != undefined)
            radioChannel = this.setPatientPlaylist(returnData, ceFacilityId);
          return radioChannel;
        }),
        catchError(this.handleError<RadioChannel[]>('getAllRadioChannels', []))
      );
  }

  getRadioPlayList(videoIds: any, ceFacilityId: number): Observable<Array<RadioChannel>> {
    const url = `${this.urlServices.radioUrl}/facilities/${ceFacilityId}/radio-channel-list?idList=[${videoIds}]`
    return this.httpClient.get<Array<RadioChannel>>(url)
      .pipe(
        map((returnData: Array<RadioChannel>) => {
          let radioChannel: Array<RadioChannel> = []
          if (returnData != null || undefined != null)
          radioChannel = this.setPatientPlaylist(returnData, ceFacilityId);
          return radioChannel;
        }),
        catchError(this.handleError<Array<RadioChannel>>(`getRadioPlayList`))
      );
  }

  setPatientPlaylist(radios: Array<RadioChannel>, ceFacilityId: number) {
    let jsonpatientPlaylist = this.commonService.getLSKey("patientPlaylist");
    if (jsonpatientPlaylist != '' && jsonpatientPlaylist != undefined) {
      const patientPlaylist = JSON.parse(jsonpatientPlaylist);
      if (patientPlaylist?.radios.length > 0) {
        radios.map(radio => {
          let index = patientPlaylist.radios.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == radio.id);
          if (index >= 0)
            radio.playlist = true;
          else
            radio.playlist = false;
        });
      }
    }
    return radios;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
