<div class="row" *ngIf="!isForCarousel">
   <ng-container [ngTemplateOutlet]="hospitalInfoNoRecords"
      [ngTemplateOutletContext]="{hospitalInfos:hospitalInfos}"></ng-container>
   <div class="grid-lg-width col-md-3 col-sm-4 col-6" *ngFor="let hospitalInfo of hospitalInfos"
      [hidden]="hospitalInfos.length==0">
      <ng-container [ngTemplateOutlet]="cardRefGrid"
         [ngTemplateOutletContext]="{hospitalInfo:hospitalInfo}"></ng-container>
   </div>
</div>

<div class="carousel" *ngIf="isForCarousel">
   <ng-container [ngTemplateOutlet]="hospitalInfoNoRecords"
      [ngTemplateOutletContext]="{hospitalInfos:hospitalInfos}"></ng-container>
   <owl-carousel-o [options]="customOptions" *ngIf="hospitalInfos.length>0">
      <ng-container *ngFor="let hospitalInfo of hospitalInfos">
         <ng-template carouselSlide [width]="170" [id]="hospitalInfo.title">
            <div
               class="type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
               <ng-container [ngTemplateOutlet]="cardRef"
                  [ngTemplateOutletContext]="{hospitalInfo:hospitalInfo}"></ng-container>
            </div>
         </ng-template>
      </ng-container>
   </owl-carousel-o>
</div>

<ng-template #hospitalInfoNoRecords let-movie='hospitalInfos'>
   <strong *ngIf="hospitalInfos.length==0" i18n="@@hospitalCard.noHospital">No hospital-Info to display!</strong>
</ng-template>

<ng-template #cardRef let-hospitalInfo='hospitalInfo'>
   <div class="gen-carousel-movies-style-2 movie-grid style-2">
      <div class="gen-movie-contain">
         <div class="gen-movie-img">
            <img [src]="hospitalInfo.thumbnailUrl" alt="owl-carousel-video-image">
            <div class="gen-movie-action">
               <a (click)="openViewerPopUp(hospitalInfo.LinkUrl)" class="gen-button">
                  <i class="fa fa-play"></i>
               </a>
            </div>
         </div>
         <div class="gen-info-contain">
            <div class="gen-movie-info">
               <h3><a (click)="openViewerPopUp(hospitalInfo.LinkUrl)">{{hospitalInfo.title}}</a>
               </h3>
            </div>
            <div class="gen-movie-meta-holder">
               <ul>
                  <li>
                     <a href="javascript:void(0)"><span style="cursor: default;">{{hospitalInfo.description}}</span></a>
                  </li>
               </ul>
               <br>
               <div
                  style="width:70px;height:39px;background-image: url('../../../assets/images/preview/channel-ico.png');">
               </div>
            </div>
         </div>
      </div>
   </div>
</ng-template>

<ng-template #cardRefGrid let-hospitalInfo='hospitalInfo'>
   <div class="cardCustom mb-2 shadow-sm">
      <div style="width:100%;text-align:center;">
         <a href="javascript:void(0)" (click)="viewHospitalInfo(hospitalInfo)">
            <img class="card-img-top imgCustom mt-5" [src]="hospitalInfo.documentThumbnail" alt="...">
         </a>
         <div class="gen-info-contain p-2">
            <div class="gen-movie-info">
               <h6><a href="javascript:void(0)">{{hospitalInfo.displayName}}</a>
               </h6>
            </div>
            <div class="d-flex justify-content-between gap-5">
               <a href="javascript:void(0)">
                  <span>{{hospitalInfo?.description}}</span></a>
               <span style="min-width: 27px; width: 27px" >
                  <img *ngIf="hospitalInfo.displayType == 'PDF'" src="../../../assets/images/new-pdf-icon.png">
                  <img *ngIf="hospitalInfo.displayType == 'Video'" src="../../../assets/images/video-file-icon.png">
               </span>
            </div>
         </div>
      </div>
   </div>
</ng-template>