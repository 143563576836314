import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Movie, MovieData, Movies } from '../data/movie';
import { ApiUrlService } from '../utils/api-url.service';
import { CommonService } from '../utils/common.service';

@Injectable({
  providedIn: 'root'
})

export class MovieService implements MovieData {
  constructor(private httpClient: HttpClient, private urlServices: ApiUrlService,
    private commonService: CommonService) {
  }

  getMovies(orderBy: string, ceFacilityId: number, pageIndex: number, pageSize: number, filters: string): Observable<Movies> {
    const url = `${this.urlServices.moviesUrl}/facilities/${ceFacilityId}/items?sortBy=${orderBy}&pageSize=${pageSize}&pageIndex=${pageIndex}&filters=${filters}`;
    return this.httpClient.get<Movies>(url)
      .pipe(
        map((returnData: Movies) => {
          let movies = this.commonService.setGenresName(returnData.data);
          movies = this.setPatientPlaylist(movies, ceFacilityId, true);
          movies = movies?.sort((a: any, b: any) => ((a.title) > (b.title)) ? 1 : -1);
          movies = movies?.sort((a: any, b: any) => (Number((a.displayPriority)) < Number((b.displayPriority))) ? 1 : -1);
          return { count: returnData.count, data: movies };
        }),
        catchError(this.handleError<Movies>('getMovies'))
      );
  }

  getRecommendedMovies(ceFacilityId: number, langCode: string): Observable<Array<Movie>> {
    const url = `${this.urlServices.moviesUrl}/facilities/${ceFacilityId}/recommended-movies?language=${langCode}`;
    return this.httpClient.get<Array<Movie>>(url)
      .pipe(
        map((returnData: Array<Movie>) => {
          let movies: Array<Movie> = []
          if (returnData != null || returnData != undefined) {
            movies = this.commonService.setGenresName(returnData);
            movies = this.setPatientPlaylist(returnData, ceFacilityId, true);
            movies = movies?.sort((a: any, b: any) => ((a.title) > (b.title)) ? 1 : -1);
            movies = movies?.sort((a: any, b: any) => (Number((a.displayPriority)) < Number((b.displayPriority))) ? 1 : -1);
          }
          return movies;
        }),
        catchError(this.handleError<Array<Movie>>('getRecommendedMovies'))
      );
  }

  /** GET movie by id. Return `undefined` when id not found */
  getMovieDetailsById(id: number): Observable<Movie> {
    const ceFacilityId = this.commonService.getLSKey("ceFacilityId");
    const url = `${this.urlServices.moviesUrl}/facilities/${ceFacilityId}/${id}`;
    return this.httpClient.get<Movie>(url)
      .pipe(
        map((returnData: Movie) => {
          return returnData;
        }),
        catchError(this.handleError<Movie>(`getMovieDetailsById`))
      );
  }

  getRelaxationVideos(orderBy: string, ceFacilityId: number, pageIndex: number, pageSize: number, filters: string): Observable<Movies> {
    //  let eFilter = escape(filters);
    const langCode = this.commonService.getLanguage();
    const url = `${this.urlServices.moviesUrl}/facilities/${ceFacilityId}/relaxation-videos?sortBy=${orderBy}&pageSize=${pageSize}&pageIndex=${pageIndex}&filters=${filters}`;
    return this.httpClient.get<Movies>(url)
      .pipe(
        map((returnData: Movies) => {
          let relaxationVideos = this.commonService.setGenresName(returnData.data);
          relaxationVideos = this.setPatientPlaylist(returnData.data, ceFacilityId, false);
          let currentLanguageMovies = relaxationVideos?.filter((x: any) => x.language.toLocaleLowerCase() == langCode).sort((a: any, b: any) => ((a.title) > (b.title)) ? 1 : -1);
          let notCurrentLanguageMovies = relaxationVideos?.filter((x: any) => x.language.toLocaleLowerCase() != langCode).sort((a: any, b: any) => ((a.title) > (b.title)) ? 1 : -1);
          currentLanguageMovies = currentLanguageMovies?.sort((a: any, b: any) => (Number((a.displayPriority)) < Number((b.displayPriority))) ? 1 : -1);
          notCurrentLanguageMovies = notCurrentLanguageMovies?.sort((a: any, b: any) => (Number((a.displayPriority)) < Number((b.displayPriority))) ? 1 : -1);
          relaxationVideos = currentLanguageMovies.concat(notCurrentLanguageMovies);
          return { count: returnData.count, data: relaxationVideos };
        }),
        catchError(this.handleError<Movies>(`getRelaxationVideos`))
      );
  }

  getRecommendedRelaxationVideos(ceFacilityId: number): Observable<Array<Movie>> {
    const langCode = this.commonService.getLanguage();
    const url = `${this.urlServices.moviesUrl}/facilities/${ceFacilityId}/recommended-relaxation-videos`
    return this.httpClient.get<Array<Movie>>(url)
      .pipe(
        map((returnData: Array<Movie>) => {
          let relaxationVideos: Array<Movie> = []
          if (returnData != null || undefined != null) {
            relaxationVideos = this.commonService.setGenresName(returnData);
            relaxationVideos = this.setPatientPlaylist(returnData, ceFacilityId, false);
            let currentLanguageMovies = relaxationVideos?.filter((x: any) => x.language.toLocaleLowerCase() == langCode.toLocaleLowerCase()).sort((a: any, b: any) => ((a.title) > (b.title)) ? 1 : -1);
            let notCurrentLanguageMovies = relaxationVideos?.filter((x: any) => x.language.toLocaleLowerCase() != langCode.toLocaleLowerCase()).sort((a: any, b: any) => ((a.title) > (b.title)) ? 1 : -1);
            currentLanguageMovies = currentLanguageMovies?.sort((a: any, b: any) => (Number((a.displayPriority)) < Number((b.displayPriority))) ? 1 : -1);
            notCurrentLanguageMovies = notCurrentLanguageMovies?.sort((a: any, b: any) => (Number((a.displayPriority)) < Number((b.displayPriority))) ? 1 : -1);
            relaxationVideos = currentLanguageMovies.concat(notCurrentLanguageMovies);
          }
          return relaxationVideos;
        }),
        catchError(this.handleError<Array<Movie>>(`getRecommendedRelaxationVideos`))
      );
  }

  setPatientPlaylist(movies: Array<Movie>, ceFacilityId: number, forMovie: boolean) {
    let jsonpatientPlaylist = this.commonService.getLSKey("patientPlaylist");
    if (jsonpatientPlaylist != '' && jsonpatientPlaylist != undefined) {
      const patientPlaylist = JSON.parse(jsonpatientPlaylist);
      if (forMovie && patientPlaylist?.movies.length > 0) {
        movies.map(movie => {
          let index = patientPlaylist.movies.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == movie.id);
          if (index >= 0)
            movie.playlist = true;
          else
            movie.playlist = false;
        });
      }
      else if (!forMovie && patientPlaylist?.relaxationVideos.length > 0) {
        movies.map(movie => {
          let index = patientPlaylist.relaxationVideos.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == movie.id);
          if (index >= 0)
            movie.playlist = true;
          else
            movie.playlist = false;
        });
      }
    }
    return movies;

  }

  getMoviePlayList(videoIds: any, ceFacilityId: number, forMovie: boolean): Observable<Array<Movie>> {
    const url = `${this.urlServices.moviesUrl}/facilities/${ceFacilityId}/movie-list?movieIdList=[${videoIds}]`
    return this.httpClient.get<Array<Movie>>(url)
      .pipe(
        map((returnData: Array<Movie>) => {
          let movies: Array<Movie> = []
          if (returnData != null || undefined != null)
          {
            movies = this.commonService.setGenresName(returnData);
            movies = this.setPatientPlaylist(returnData, ceFacilityId, forMovie);
          }
          
          return movies;
        }),
        catchError(this.handleError<Array<Movie>>(`getMoviePlayList`))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
