import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NbDialogService } from '@nebular/theme';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { GameSite } from 'src/app/@core/data/gameSite';
import { CommonService } from 'src/app/@core/utils';
import { ApiUrlService } from 'src/app/@core/utils/api-url.service';
import { ServiceNotAccessComponent } from 'src/app/pages/service-not-access/service-not-access.component';
@Component({
  selector: 'ngx-game-card',
  templateUrl: './game-card.component.html',
  styleUrls: ['./game-card.component.scss']
})
export class GameCardComponent implements OnInit {
  @Input() gameSites: Array<GameSite> = new Array<GameSite>();
  @Input() isForCarousel: boolean = false;
  gamesBlobStorage: any;
  defaultImagePath: string = '';
  customOptions: OwlOptions = {
    rtl: false,
    items: 6,
    loop: false,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,
    navText: ["<i class='ion-ios-arrow-back'></i>", "<i class='ion-ios-arrow-forward'></i>"],
    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: false
      },
      480: {
        items: 4,
        nav: true,
        dots: false
      },
      786: {
        items: 4
      },
      1023: {
        items: 5
      },
      1199: {
        items: 6
      }
    }
  }
  serviceNotAccessPopup: any;
  constructor(private commonService: CommonService, private router: Router,
    private dialogService: NbDialogService, private apiUrlServices: ApiUrlService, private authService: MsalService) {
    this.gamesBlobStorage = this.apiUrlServices.gamesBlobStorage
  }

  ngOnInit(): void {
    this.defaultImagePath = this.commonService.defaultImage();
  }
  ngOnDestroy() {
    this.serviceNotAccessPopup?.close();
  }

  checkUserLogInForPlayGames(gameUrl: string, isOnline: boolean): void {
    const account = this.authService.instance.getAllAccounts()[0];
    if (account)
      this.openGames(gameUrl, isOnline);
    else
      this.openloginPopup(gameUrl, isOnline);
  }

  openGames(gameUrl: string, isOnline: boolean): void {
    this.commonService.checkRentedService("PREMIUM_BYOD").subscribe(data => {
      if (data.body) {
        if (isOnline)
          window.open(gameUrl, '_blank');
        else {
          this.router.navigate(["/home/infotainment/games/flash-player", gameUrl]);
        }
      }
      else
        this.servceNotAccess();
    });
  }

  openloginPopup(gameUrl: string, isOnline: boolean) {
    const scopes = {scopes: [],
      prompt: "select_account"} 
    this.authService.loginPopup(scopes).subscribe(x => {
      this.commonService.BindUserDetail.next(true);
      this.openGames(gameUrl, isOnline);
    });
  }

  servceNotAccess() {
    this.serviceNotAccessPopup = this.dialogService.open(ServiceNotAccessComponent, { hasBackdrop: true, closeOnBackdropClick: false, autoFocus: false });
  }
}