<!-- register -->
<section class="position-relative pb-0">
 <div class="gen-register-page-background"
        style="background-image: url('../../../../assets/images/backgrounds/general.jpg');">
    </div> 
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center">
                    <form id="pms_register-form" class="pms-form" method="POST">
                        <div align="right"><IMG SRC="../../../../assets/images/backgrounds/logo-1xs.png" BORDER="0"
                                ALT=""></div>
                        <h4>Sign Up</h4>

                        <ul class="pms-form-fields-wrapper pl-0">
                            <li class="pms-field pms-user-login-field" style="width:100%">
                                <label for="pms_user_login">Username (Email Address) *</label>
                                <input id="pms_user_login" name="user_login" type="text" value="">
                            </li>
                            <li class="pms-field pms-last-name-field">
                                <label for="pms_last_name">Last Name</label>
                                <input id="pms_last_name" name="last_name" type="text" value="">
                            </li>
                            <li class="pms-field pms-first-name-field">
                                <label for="pms_first_name">First Name</label>
                                <input id="pms_first_name" name="first_name" type="text" value="">
                            </li>
                            <li class="pms-field pms-pass2-field">
                                <label for="pms_pass2">Confirm Password *</label>
                                <input id="pms_pass2" name="pass2" type="password">
                            </li>

                            <li class="pms-field pms-pass1-field">
                                <label for="pms_pass1">Password *</label>
                                <input id="pms_pass1" name="pass1" type="password">
                            </li>

                            <li class="pms-field pms-field-subscriptions ">
                                <div class="pms-subscription-plan">
                                    <label>
                                        <input type="radio" name="subscription_plans" data-price="199" data-duration="1"
                                            value="7330" checked="checked" data-default-selected="true"><span
                                            class="pms-subscription-plan-name"><B>FREE</B></span><span
                                            class="pms-subscription-plan-price"><span class="pms-divider"> -
                                            </span><span style="font-size:14px;">Hospital Info, Health Education,
                                                Questionnaires, Surveys, HopiTV </span><span
                                                class="pms-subscription-plan-trial"></span><span
                                                class="pms-subscription-plan-sign-up-fee"></span>
                                        </span>
                                    </label>
                                </div>
                                <div class="pms-subscription-plan">
                                    <label>
                                        <input type="radio" name="subscription_plans" data-price="99" data-duration="1"
                                            value="7329" data-default-checked="false"><span
                                            class="pms-subscription-plan-name"><B>IPTV</B></span><span
                                            class="pms-subscription-plan-price"><span class="pms-divider"> -
                                            </span><span style="font-size:14px;">50+ HD Channels </span> / <span
                                                class="pms-subscription-plan-price-value">$12.95 per day / $77.70 per
                                                week / $181.30 per month</span><span
                                                class="pms-subscription-plan-trial"></span><span
                                                class="pms-subscription-plan-sign-up-fee"></span>
                                        </span>
                                    </label>
                                </div>
                                <div class="pms-subscription-plan">
                                    <label>
                                        <input type="radio" name="subscription_plans" data-price="29" data-duration="1"
                                            value="7328" data-default-checked="false"><span
                                            class="pms-subscription-plan-name"><B>Premium+</B></span><span
                                            class="pms-subscription-plan-price"><span class="pms-divider"> -
                                            </span><span style="font-size:14px;">IPTV, Movies, Music, Radio, Games,
                                                Audio Books, Relaxation Videos, Video Calls</span> /
                                            <br>&nbsp;&nbsp;&nbsp;<span class="pms-subscription-plan-price-value">$16.95
                                                per day / $95.70 per week / $223.30 per month</span></span>
                                    </label>
                                </div>
                                <div id="pms-paygates-wrapper"><input type="hidden" class="pms_pay_gate" name="pay_gate"
                                        value="paypal_standard"></div>
                            </li>
                        </ul>
                        <span id="pms-submit-button-loading-placeholder-text" class="d-none">Processing. Please
                            wait...</span>
                        <input name="pms_register" action="action" onclick="window.history.go(-1); return false;"
                            type="button" value="BACK"><input name="pms_register" type="submit" value="SIGN UP"
                            style="float:right;">
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- register -->