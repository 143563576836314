import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudiobookService } from './audiobook.service';
import { GameService } from './game.service';
import { MovieService } from './movie.service';
import { RadioService } from './radio.service';
import { SurveyService } from './survey.service';
import { MediaSchedulerService } from './media-scheduler.service';
import { AzFunctionsService } from './az-functions.service';
import { HospitalInfoService } from './hospital-info.service';
import { MusicService } from './music.service';
import { RentalService } from './rental.service';
import { IptvService } from './iptv.service';
import { KidsService } from './kids.service';
import { PatientPlaylistService } from './patient-playlist.service';
import { ConnectionService } from './connection.service';
import { FacilityService } from './facility.service';

const SERVICES = [
  AudiobookService,
  GameService,
  MovieService,
  RadioService,
  SurveyService,
  MediaSchedulerService,
  AzFunctionsService,
  HospitalInfoService,
  MusicService,
  RentalService,
  IptvService,
  KidsService,
  PatientPlaylistService,
  ConnectionService,
  FacilityService
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[
    SERVICES
  ],
})

export class ServicesModule {
  static forRoot():ModuleWithProviders<ServicesModule>{
return {
  ngModule:ServicesModule,
  providers :[
    SERVICES
  ],
};
  }
 }
