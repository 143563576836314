import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';

@Injectable()
export class ConstantsService {
  readonly language: any[] = [
    { Key: 'en', Value: 'English', IsDefault: true },
    { Key: 'fr', Value: 'Français', IsDefault: false }
  ];

  readonly REDIRECT_URL: any = {
    MOVIE_TRAILER: '/home/infotainment/movie/trailer',
    KIDS_TRAILER: '/home/infotainment/kids/trailer',
    KIDS_VIEWER: '/home/infotainment/kids/viewer',
    RELAXATION_TRAILER: '/home/infotainment/relaxation',
    AUDIOBOOK_TRAILER: '/home/infotainment/audio-book',
    RADIO_TRAILER: '/home/infotainment/radio/trailer',
    MUSIC_TRAILER: '/home/infotainment/music/albums',
    LANDING_CARD: '/home/landing',
    MY_PLAYLIST: '/home/my-playlist',
    IPTV_TRAILER: '/home/infotainment/iptv',
  }

  readonly validationResult: any = {
    general: [{
      code: 'Success',
      message: [{ languageCode: 'en', text: 'Success' }, { languageCode: 'fr', text: 'Succès' }]
    }, {
      code: 'Delete',
      message: [{ languageCode: 'en', text: 'Delete' }, { languageCode: 'fr', text: 'Supprimer' }]
    }, {
      code: 'Update',
      message: [{ languageCode: 'en', text: 'Update' }, { languageCode: 'fr', text: 'Mise à jour' }]
    }, {
      code: 'Info',
      message: [{ languageCode: 'en', text: 'Info' }, { languageCode: 'fr', text: 'Info' }]
    }, {
      code: 'Warning',
      message: [{ languageCode: 'en', text: 'Warning' }, { languageCode: 'fr', text: 'Avertissement' }]
    }, {
      code: 'Error',
      message: [{ languageCode: 'en', text: 'Error' }, { languageCode: 'fr', text: 'Erreur' }]
    }, {
      code: 'BadRequest',
      message: [{ languageCode: 'en', text: 'Bad Request' }, { languageCode: 'fr', text: 'Mauvaise Demande' }]
    }, {
      code: 'NotFound',
      message: [{ languageCode: 'en', text: 'Not found' }, { languageCode: 'fr', text: 'Pas trouvé' }]
    }, {
      code: 'ServerError',
      message: [{ languageCode: 'en', text: 'Internal server error' }, { languageCode: 'fr', text: 'Erreur interne du serveur' }]
    }, {
      code: 'Notification',
      message: [{ languageCode: 'en', text: 'Notification' }, { languageCode: 'fr', text: 'Notification' }]
    }, {
      code: 'AlreadyAssociated',
      message: [{ languageCode: 'en', text: 'Row cannot be deleted. Associated with other Entities' },
      { languageCode: 'fr', text: "La rangée ne peut pas être supprimée. Associé à d'autres Entités" }]
    }, {
      code: 'DeleteSuccess',
      message: [{ languageCode: 'en', text: 'Deleted successfully' }, {
        languageCode: 'fr', text: 'Supprimé avec succès'
      }]
    }, {
      code: 'SaveSuccess',
      message: [{ languageCode: 'en', text: 'Saved successfully' }, {
        languageCode: 'fr', text: 'Sauvé avec succès'
      }]
    }, {
      code: 'UpdateSuccess',
      message: [{ languageCode: 'en', text: 'Updated successfully' }, {
        languageCode: 'fr', text: 'Mise à jour réussie'
      }]
    }, {
      code: 'EmailSuccess',
      message: [{ languageCode: 'en', text: 'Email sent successfully' }, {
        languageCode: 'fr', text: 'Courriel envoyé avec succès'
      }]
    }, {
      code: 'RequestSuccess',
      message: [{ languageCode: 'en', text: 'Request is sent successfully' }, {
        languageCode: 'fr', text: 'La demande est envoyée avec succès'
      }]
    }],
    signalR: [{
      code: 'StartingConnection',
      message: [{ languageCode: 'en', text: 'Starting Connection to Notification Hub!' }, {
        languageCode: 'fr', text: "Démarrage de la connexion au Notification Hub !"
      }]
    }, {
      code: 'NoInfo',
      message: [{ languageCode: 'en', text: 'No info returned from SignalR' }, {
        languageCode: 'fr', text: "Aucune information renvoyée par SignalR"
      }]
    }, {
      code: 'Connected',
      message: [{ languageCode: 'en', text: 'Connected to Notification Hub!' }, {
        languageCode: 'fr', text: "Connecté à Notification Hub!"
      }]
    }],
    prescription: [{
      code: 'FirstNameReq',
      message: [{ languageCode: 'en', text: 'First Name is required' }, { languageCode: 'fr', text: 'Le prénom est obligatoire' }]
    }, {
      code: 'LastNameReq',
      message: [{ languageCode: 'en', text: 'Last Name is required' }, { languageCode: 'fr', text: 'Le nom de famille est obligatoire' }]
    }, {
      code: 'NewPrescription',
      message: [{ languageCode: 'en', text: 'You have a new Prescription!' }, {
        languageCode: 'fr', text: 'Vous avez une nouvelle ordonnance!'
      }]
    }, {
      code: 'DeletedPrescription',
      message: [{ languageCode: 'en', text: 'Prescription is Deleted!' }, {
        languageCode: 'fr', text: "L'ordonnance est supprimée!"
      }]
    }, {
      code: 'UpdatedPrescription',
      message: [{ languageCode: 'en', text: 'Prescription is Updated!' }, {
        languageCode: 'fr', text: "L'ordonnance est mise à jour!"
      }]
    }],
    survey: [{
      code: 'ResponseSuccess',
      message: [{ languageCode: 'en', text: 'Survey answered successfully' }, {
        languageCode: 'fr', text: "L'enquête a été menée à bien"
      }]
    }],
    facility: [{
      code: 'NoFacilityFound',
      message: [{ languageCode: 'en', text: 'No available facilities were found, please ensure you are connected to the hospital network' }, {
        languageCode: 'fr', text: "Aucun hôpital disponible n’a été trouvé, veuillez vous assurer que vous êtes connecté au réseau de l’hôpital"
      }]
    }],
    breadcrumb: [{
      code: 'home',
      message: [{ languageCode: 'en', text: 'Home' }, { languageCode: 'fr', text: "Accueil" }],
      routerUrl: 'landing',
      children: [{
        code: 'infotainment',
        message: [{ languageCode: 'en', text: 'Infotainment' }, { languageCode: 'fr', text: 'Infodivertissement' }],
        children: [{
          code: 'iptv',
          message: [{ languageCode: 'en', text: 'IPTV' }, { languageCode: 'fr', text: 'TVIP' }]
        }, {
          code: 'movie',
          message: [{ languageCode: 'en', text: 'Movies' }, { languageCode: 'fr', text: 'Films' }]
        }, {
          code: 'music',
          message: [{ languageCode: 'en', text: 'Music' }, { languageCode: 'fr', text: 'Musique' }],
          routerUrl: 'infotainment/music',
          children: [{
            code: 'albums',
            message: [{ languageCode: 'en', text: 'Albums' }, { languageCode: 'fr', text: 'Albums' }]
          }]
        }, {
          code: 'radio',
          message: [{ languageCode: 'en', text: 'Radio' }, { languageCode: 'fr', text: 'Radio' }]
        }, {
          code: 'games',
          message: [{ languageCode: 'en', text: 'Games' }, { languageCode: 'fr', text: 'Jeux' }]
        }, {
          code: 'audio-book',
          message: [{ languageCode: 'en', text: 'Audio Books' }, { languageCode: 'fr', text: 'Livres audios' }]
        }, {
          code: 'relaxation',
          message: [{ languageCode: 'en', text: 'Relaxation' }, { languageCode: 'fr', text: 'Relaxation' }]
        }, {
          code: 'kids',
          message: [{ languageCode: 'en', text: 'Kids' }, { languageCode: 'fr', text: 'Enfants' }]
        },
        {
          code: 'streaming-services',
          message: [{ languageCode: 'en', text: 'Streaming Services' }, { languageCode: 'fr', text: 'Services de diffusion en continu' }]
        }]
      }, {
        code: 'my-playlist',
        message: [{ languageCode: 'en', text: 'My Playlist' }, { languageCode: 'fr', text: 'Ma liste' }],
      }, {
        code: 'communications',
        message: [{ languageCode: 'en', text: 'Communications' }, { languageCode: 'fr', text: 'Communications' }],
        children: [{
          code: 'interactive-whiteboard',
          message: [{ languageCode: 'en', text: 'Interactive Whiteboard' }, { languageCode: 'fr', text: 'Tableau blanc interactif' }]
        }]
      }, {
        code: 'info-health',
        message: [{ languageCode: 'en', text: 'Info-health' }, { languageCode: 'fr', text: 'InfoSanté' }],
        children: [{
          code: 'health-education',
          message: [{ languageCode: 'en', text: 'Health Education - VOD Groups' }, { languageCode: 'fr', text: 'Groupes VAD' }],
          routerUrl: 'info-health/health-education',
          children: [{
            code: 'media-group',
            message: [{ languageCode: 'en', text: 'Media-group' }, { languageCode: 'fr', text: 'groupe de médias' }],
          }]
        }, {
          code: 'video-on-demand',
          message: [{ languageCode: 'en', text: 'Health Education - VOD' }, { languageCode: 'fr', text: 'VAD' }]
        },
        {
          code: 'scheduled-education',
          message: [{ languageCode: 'en', text: 'Health Education - Scheduled' }, { languageCode: 'fr', text: 'Programmation' }]
        }]
      }, {
        code: 'prescriptions',
        message: [{ languageCode: 'en', text: 'Prescriptions' }, { languageCode: 'fr', text: 'Prescriptions' }],
        children: [{
          code: 'prescribed-video',
          message: [{ languageCode: 'en', text: 'Prescribed video' }, { languageCode: 'fr', text: 'Vidéos prescrites' }]
        }, {
          code: 'followup',
          message: [{ languageCode: 'en', text: 'Video Follow-Up' }, { languageCode: 'fr', text: 'Questionnaires de suivi' }]
        },
        {
          code: 'document',
          message: [{ languageCode: 'en', text: 'Documents' }, { languageCode: 'fr', text: 'Documents prescrits' }]
        },
        {
          code: 'survey',
          message: [{ languageCode: 'en', text: ' Prescribed Surveys' }, { languageCode: 'fr', text: 'Sondages de satisfaction' }]
        }
        ]
      }, {
        code: 'hospital-Info',
        message: [{ languageCode: 'en', text: 'Hospital-Info' }, { languageCode: 'fr', text: 'Information Hôpital' }],
      }, {
        code: 'contact',
        message: [{ languageCode: 'en', text: 'Contact' }, { languageCode: 'fr', text: 'Contacter' }]
      }, {
        code: 'about',
        message: [{ languageCode: 'en', text: 'About' }, { languageCode: 'fr', text: 'À propos' }]
      }, {
        code: 'account',
        message: [{ languageCode: 'en', text: 'My account' }, { languageCode: 'fr', text: 'Mon compte' }]
      }, {
        code: 'prescribed-video',
        message: [{ languageCode: 'en', text: 'Prescribed-video' }, { languageCode: 'fr', text: 'Vidéos prescrites' }]
      }, {
        code: 'followup',
        message: [{ languageCode: 'en', text: 'Followup' }, { languageCode: 'fr', text: 'Suivi de' }]
      }, {
        code: 'survey',
        message: [{ languageCode: 'en', text: 'Survey' }, { languageCode: 'fr', text: 'Enquête' }]
      }, {
        code: 'document',
        message: [{ languageCode: 'en', text: 'Document' }, { languageCode: 'fr', text: 'Document' }]
      }, {
        code: 'call-centre',
        message: [{ languageCode: 'en', text: 'Call centre' }, { languageCode: 'fr', text: "Centre d'appel" }]
      }, {
        code: 'privacy-policy',
        message: [{ languageCode: 'en', text: 'Privacy-policy' }, { languageCode: 'fr', text: 'Politique de confidentialité' }]
      }, {
        code: 'terms-of-use',
        message: [{ languageCode: 'en', text: 'Terms-of-use' }, { languageCode: 'fr', text: 'Conditions d utilisation' }]
      },
      {
        code: 'hopi-tv',
        message: [{ languageCode: 'en', text: 'Hopi-tv' }, { languageCode: 'fr', text: 'Hopi-tv' }]
      },
      {
        code: 'room-service',
        message: [{ languageCode: 'en', text: 'Room Services' }, { languageCode: 'fr', text: 'Services de chambres' }],
        children: [{
          code: 'nurse-call',
          message: [{ languageCode: 'en', text: 'Nurse Call' }, { languageCode: 'fr', text: 'Service d’appel à l’infirmière' }]
        },
        {
          code: 'meal-menu',
          message: [{ languageCode: 'en', text: 'Meal menu' }, { languageCode: 'fr', text: ' Menu de repas' }],
          routerUrl: 'room-service/meal-menu',
          children: [
            {
              code: 'monday',
              message: [{ languageCode: 'en', text: 'Monday' }, { languageCode: 'fr', text: 'Lundi' }],
            },
            {
              code: 'tuesday',
              message: [{ languageCode: 'en', text: 'Tuesday' }, { languageCode: 'fr', text: 'Mardi' }],
            },
            {
              code: 'wednesday',
              message: [{ languageCode: 'en', text: 'Wednesday' }, { languageCode: 'fr', text: 'Mercredi' }],
            },
            {
              code: 'thursday',
              message: [{ languageCode: 'en', text: 'Thursday' }, { languageCode: 'fr', text: 'Jeudi' }],
            },
            {
              code: 'friday',
              message: [{ languageCode: 'en', text: 'Friday' }, { languageCode: 'fr', text: 'Vendredi' }],
            },
            {
              code: 'saturday',
              message: [{ languageCode: 'en', text: 'Saturday' }, { languageCode: 'fr', text: 'Samedi' }],
            },
            {
              code: 'sunday',
              message: [{ languageCode: 'en', text: 'Sunday' }, { languageCode: 'fr', text: 'Dimanche' }],
            },
          ]
        },
        {
          code: 'concierge-service',
          message: [{ languageCode: 'en', text: 'Concierge Service' }, { languageCode: 'fr', text: 'Service de conciergerie' }]
        },
        {
          code: 'room-control',
          message: [{ languageCode: 'en', text: 'Room Controls' }, { languageCode: 'fr', text: 'Salle de contrôle' }]
        }]
      },
      ]
    }],
    rental: [{
      code: 'thankyou',
      message: [{ languageCode: 'en', text: 'THANK-YOU!' }, {
        languageCode: 'fr', text: "MERCI-VOUS!"
      }]
    },
    {
      code: 'submittedSuccessfully',
      message: [{ languageCode: 'en', text: 'Your transaction has been successfully submitted, to start enjoying your HopiFlix service press OK.' }, {
        languageCode: 'fr', text: "Votre transaction a été envoyée avec succès, pour commencer à profiter de votre service HopiFlix, appuyez sur OK."
      }]
    },
    {
      code: 'submittingError',
      message: [{ languageCode: 'en', text: 'Error while submitting the order' }, {
        languageCode: 'fr', text: "Erreur lors de la soumission de la commande"
      }]
    }, {
      code: 'nonExpiredServiceOfAnotherPatientMsg',
      message: [{ languageCode: 'en', text: 'Non expired service Of another patient message' }, {
        languageCode: 'fr', text: "Service non expiré Message d'un autre patient"
      }]
    },
    {
      code: 'credit',
      message: [{ languageCode: 'en', text: 'Credit' }, {
        languageCode: 'fr', text: "Crédit"
      }]
    }, {
      code: 'noRented',
      message: [{ languageCode: 'en', text: 'No rented services for this terminal' }, {
        languageCode: 'fr', text: "Pas de services loués pour ce terminal"
      }]
    },],
    connection: [{
      code: 'connectionLimit',
      message: [{ languageCode: 'en', text: 'You have reached the hospital license connection limit, please contact the support team for assistance.' }, {
        languageCode: 'fr', text: "Vous avez atteint la limite de connexion de la licence d'hôpital, veuillez contacter l'équipe d'assistance pour obtenir de l'aide."
      }]
    }],
  }

  getTransactionType(code: any, langCode: any): any {
    switch (code) {
      case 'RS':
        return langCode == 'en' ? 'New Rental' : 'Nouvelle location';
      case 'CS':
        return langCode == 'en' ? 'Cancel Service' : 'Annuler le service';
      case 'ES':
        return langCode == 'en' ? 'Renewal' : 'Renouvellement';
      case 'OH':
        return langCode == 'en' ? 'On-hold Service' : 'Service en attente';
      case 'AH':
        return langCode == 'en' ? 'Re-activate Service' : 'Réactiver le service';
      case 'US':
        return langCode == 'en' ? 'Upgrade' : 'Mise à niveau';
    }
  }
}

