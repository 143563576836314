import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PatientPlaylistItem } from 'src/app/@core/data/patientPlaylistItem';
import { KidsService } from 'src/app/@core/services/kids.service';
import { ApiUrlService, CommonService } from 'src/app/@core/utils';

@Component({
  selector: 'ngx-kids-card',
  templateUrl: './kids-card.component.html',
  styleUrls: ['./kids-card.component.scss']
})
export class KidsCardComponent implements OnInit {
  @Input() kidsVideos: Array<any> = new Array<any>();
  @Input() isForCarousel: boolean = false;
  @Input() fromPlayList: boolean = false;
  defaultImagePath: string;
  movieBlobStorage: string;
  customOptions: OwlOptions = {
    rtl: false,
    items: 6,
    loop: false,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,
    navText: ["<i class='ion-ios-arrow-back'></i>", "<i class='ion-ios-arrow-forward'></i>"],
    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: false
      },
      480: {
        items: 4,
        nav: true,
        dots: false
      },
      786: {
        items: 4
      },
      1023: {
        items: 5
      },
      1199: {
        items: 6
      }
    }
  };
  userChangeSubscription: Subscription;
  patientPlaylistItem: PatientPlaylistItem = {} as PatientPlaylistItem;

  constructor(private router: Router, private commonService: CommonService, private kidsService: KidsService,
    private authService: MsalService, private apiUrlServices: ApiUrlService) {
    this.defaultImagePath = this.commonService.defaultImage();
    this.movieBlobStorage = this.apiUrlServices.movieBlobStorage;
    this.patientPlaylistItem.forKids = true;
    // rebindPlayList
    this.userChangeSubscription = this.commonService.rebindPlayList.subscribe((res: any) => {
      if (res) {
        const ceFacilityId = this.commonService.getLSKey("ceFacilityId");
        this.kidsVideos = this.kidsService.setPatientPlaylist(this.kidsVideos, ceFacilityId);
        this.commonService.rebindPlayList.next(false)
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {}

  watchMovie(movie: any): void {
    const account = this.authService.instance.getAllAccounts()[0];
    if (account) {
      this.commonService.checkRentedService("PREMIUM_BYOD").subscribe(data => {
        if (data.body) {
          let oauthTokenId = null;
          if (movie.videoTagSrc) {
            oauthTokenId = movie.videoTagSrc.split("oauth2_token_id=")[1].split("&")[0];
            this.commonService.setLSKey('videoUrl', movie.videoTagSrc);
          }
          const url = `/home/infotainment/kids/viewer/${movie.videoId}/${movie.sig_movie}/${movie.profileId_movie}/${oauthTokenId}`;
          this.router.navigate([url]);
        }
        else {
          this.router.navigate(['home/infotainment/kids/trailer', movie.id]);
          this.commonService.setLSKey('purl', { key: 'KIDS_TRAILER', id: movie.id });
        }
      });
    }
    else {
      this.commonService.setLSKey('purl', { key: 'KIDS_TRAILER', id: movie });
      this.authService.loginRedirect();
    }

  }

  getUpdatedPlayList(video: any) {
    if (this.fromPlayList) {
      const index = this.kidsVideos.findIndex((x: any) => x.id == video.id);
      if (index >= 0)
        this.kidsVideos.splice(index, 1);
    }
  }
}
