import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommonService } from 'src/app/@core/utils';

@Component({
  selector: 'ngx-hospital-info-card',
  templateUrl: './hospital-info-card.component.html',
  styleUrls: ['./hospital-info-card.component.scss']
})
export class HospitalInfoCardComponent implements OnInit {
  @Input() hospitalInfos: Array<any> = new Array<any>();
  @Input() isForCarousel: boolean = false;
  facilityDocument: any = {};
  @Output() changeFolderName: EventEmitter<any> = new EventEmitter<any>();
  customOptions: OwlOptions = {
    rtl: false,
    items: 6,
    loop: false,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,
    navText: ["<i class='ion-ios-arrow-back'></i>", "<i class='ion-ios-arrow-forward'></i>"],
    responsive: {
      0: {
          items: 2,
          nav: true,
          dots: false
      },
      480: {
          items: 4,
          nav: true,
          dots: false
      },
      786: {
          items: 4
      },
      1023: {
          items: 5
      },
      1199: {
          items: 6
      }
    }
  }


  constructor(private router: Router, private commonService: CommonService) { }

  ngOnInit(): void {
  }

  viewHospitalInfo(data: any) {
    if (data.displayType == "PDF") {
      let fileShareName = data.content;
      const ceFacilityId = this.commonService.getLSKey("ceFacilityId");
      const url = `/home/document/viewer/${ceFacilityId}/${null}/${null}/${fileShareName}`;
      this.router.navigate([url]);
    }
    else if (data.displayType == "Video") {
      const profile_id = data.profileId_movie;
      const clipId = data.videoId;
      const sig = data.sig_movie;
      const videoUrl = data.videoTagSrc;
      let videoUrlProvided = false;
      if (videoUrl && videoUrl.length > 0) {
        this.commonService.setLSKey('videoUrl', videoUrl);
        videoUrlProvided = true;
      }
      const url = `home/video-viewer/${clipId}/${sig}/${profile_id}/false/0000/0000/${videoUrlProvided}`;
      this.router.navigate([url]);
    }
    else if (data.displayType == "Folder")
      this.changeFolderName.emit(data.folder);
    else if (data.displayType == "WebPage")
      this.router.navigate([this.router.url + "/htmlviewer", data.id]);
  }

  openViewerPopUp(url:any): void {
    window.open(url,'popup','width=600,height=400')
  }
}
