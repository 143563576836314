<div class="row" *ngIf="!isForCarousel">
   <ng-container [ngTemplateOutlet]="audiobookNoRecords" [ngTemplateOutletContext]="{audiobooks:audiobooks}"></ng-container>
   <div class="grid-lg-width col-md-3 col-sm-4 col-6" *ngFor="let audiobook of audiobooks" [hidden]="audiobooks?.length==0">
       <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{audiobook:audiobook}"></ng-container>
   </div>
</div>

<div class="carousel" *ngIf="isForCarousel">
   <ng-container [ngTemplateOutlet]="audiobookNoRecords" [ngTemplateOutletContext]="{audiobooks:audiobooks}"></ng-container>
   <owl-carousel-o [options]="customOptions" *ngIf="audiobooks.length>0">
       <ng-container *ngFor="let audiobook of audiobooks">
           <ng-template carouselSlide [width]="170">
               <div class="movie type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                   <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{audiobook:audiobook}"></ng-container>
               </div>
           </ng-template>
       </ng-container>
   </owl-carousel-o>
</div>

<ng-template #audiobookNoRecords let-movie='audiobooks'>
   <strong *ngIf="audiobooks?.length==0" i18n="@@audiobook-card.noAudioBook">No Audio-book to display!</strong>
</ng-template>

 <ng-template #cardRef let-audiobook='audiobook'>
   <div class="gen-carousel-movies-style-2 movie-grid style-2">
      <div class="gen-movie-contain">
         <div class="gen-movie-img">
            <img [src]="audiobook?.thumbnailUrl?audiobook?.thumbnailUrl:defaultImagePath" alt="hopiflix">
            <ngx-icons [video]="audiobook" [patientPlaylistItem]="patientPlaylistItem" (newPlayList)="getUpdatedPlayList($event)"></ngx-icons>
            <div class="gen-movie-action">
               <a href="javascript:void(0)" class="gen-button audiobg" (click)="showSynopsys(audiobook.id,dialog)">
                  <i class="fa fa-play"></i>
               </a>
            </div>
         </div>
         <div
               style="width:70px;height:39px;background-image: url('../../../assets/images/preview/channel-ico.png');">
           </div>
      </div>
   </div>
</ng-template> 


<span style="font-size:11px;color:#ffffff;"><I i18n="@@audiobook-card.streaming">*Audio Book streaming service may require starting a
   Free subscription.</I></span>
<br><br>


<ng-template #dialog let-data let-ref="dialogRef">
   <div class="container">
   <nb-card class="m-2" >
      <nb-card-header class="d-flex justify-content-between" i18n="@@audiobook-card.details">Audiobook Details</nb-card-header>
      <nb-card-body>
         <div class="audio-book-card-height" >
            <div class="container-fliud m-2" >
                <div class="wrapper row">
                    <div class="preview col-md-3">
                            <div class="preview-pic tab-content">
                                <div class="tab-pane active" id="pic-1">
                                    <img [src]="audiobook.thumbnailUrl" />
                                </div>
                        </div>
                    </div>
                    <div class="details col-md-9">
                        <h5 class="text-uppercase text-primary">{{audiobook.label}}</h5>
                        <h6 class="text-uppercase" i18n="@@audiobook-card.synopsis">Synopsis</h6>
                        <p class="my-3" >
                            <span> {{audiobook.synopsis}} </span>
                        </p>
                    </div>
                </div>
                <div class="mb-4" style="text-align:center">
                    <button nbButton status="info" class="mr-2 mt-2" (click)="ref.close()" i18n="@@audiobook-card.cancel"> Cancel</button>
                    <button nbButton status="success"  class="mt-2" (click)="startListening(ref)">
                        <i class="fas fa-volume-up fa-fw"></i><span i18n="@@audiobook-card.listening">Start Listening</span>
                          </button>
                </div>
            </div>
        </div>
      </nb-card-body>
     
   </nb-card>
   </div>
</ng-template>
