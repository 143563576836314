import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-service-not-access',
  templateUrl: './service-not-access.component.html',
  styleUrls: ['./service-not-access.component.scss'],
})
export class ServiceNotAccessComponent implements OnInit {

  constructor(private dialogRef: NbDialogRef<ServiceNotAccessComponent>, private router: Router) { }

  ngOnInit(): void { }

  close() {
    this.dialogRef.close()
  }

  gotoRentalMenu() {
    this.close();
    this.router.navigate(['/home/rental'])
  }

}
