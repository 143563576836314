import { Observable } from "rxjs";
import { I18nString } from "./i18nString";
import { FacilityAlert } from "./facilityAlert";
import { FacilityTicker } from "./facilityTicker";
import { ConciergeService } from "./conciergeService";

export interface Facility {
    id: string;
    text: string;
    names: I18nString[];
    facilityCode: string;
    ceFacilityId: number;
}

export abstract class FacilityData {
    abstract getFacilities(): Observable<any>;
    abstract getFacilityLogo(): Observable<any>;
    abstract getActivatedFacilityTickers(facilityId: number): Observable<FacilityTicker>;
    abstract getActivatedFacilityAlerts(facilityId: number): Observable<FacilityAlert>;
    abstract getAdvertisementForFacility(facilityCode: string): Observable<any>;
    abstract getInteractiveWhiteboardsForFacility(facilityId: number,cardNumber:string): Observable<any>;
    abstract setGeneralCommentForWhiteboard(data: any): Observable<any>
    abstract setConciergeRequest(data: any): Observable<any>
    abstract setPainScaleSubmition(data: any): Observable<any>
    abstract getConciergeService(facilityId: number): Observable<Array<ConciergeService>>;
}