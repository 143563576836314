<div class="row" *ngIf="!isForCarousel">
   <ng-container [ngTemplateOutlet]="musicNoRecords" [ngTemplateOutletContext]="{musicAlbums:musicAlbums}"></ng-container>
   <div class="grid-lg-width col-md-3 col-sm-4 col-6" *ngFor="let musicAlbum of musicAlbums" [hidden]="musicAlbums.length==0">
       <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{musicAlbum:musicAlbum}"></ng-container>
   </div>
</div>

<div class="carousel" *ngIf="isForCarousel">
   <ng-container [ngTemplateOutlet]="musicNoRecords" [ngTemplateOutletContext]="{musicAlbums:musicAlbums}"></ng-container>
   <owl-carousel-o [options]="customOptions" *ngIf="musicAlbums.length>0">
       <ng-container *ngFor="let musicAlbum of musicAlbums">
           <ng-template carouselSlide [width]="170" [id]="musicAlbum.title">
               <div class="movie type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                   <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{musicAlbum:musicAlbum}"></ng-container>
               </div>
           </ng-template>
       </ng-container>
   </owl-carousel-o>
</div>

<ng-template #musicNoRecords let-movie='musicAlbums'>
   <strong *ngIf="musicAlbums?.length==0" i18n="@@musicCard.noMusic">No Music Album to display!</strong>
</ng-template>

<ng-template #cardRef let-musicAlbum='musicAlbum'>
   <div class="gen-carousel-movies-style-2 movie-grid style-2">
      <div class="gen-movie-contain">
         <div class="gen-movie-img">
            <img [src]="musicAlbum?.thumbnail?musicAlbum.thumbnail:defaultImagePath" alt="...">
            <ngx-icons [video]="musicAlbum" [patientPlaylistItem]="patientPlaylistItem" (newPlayList)="getUpdatedPlayList($event)"></ngx-icons>
            <div class="gen-movie-action">
               <a href="javascript:void(0)" (click)="watchMusic(musicAlbum)"
                  class="gen-button musicbg">
                  <i class="fa fa-play"></i>
               </a>
            </div>
         </div>
         <div class="gen-info-contain">
            <div class="gen-movie-info">
                <h3><a href="javascript:void(0)" (click)="watchMusic(musicAlbum)">{{musicAlbum?.title}}</a>
                </h3>
                <div
                style="width:70px;height:39px;background-image: url('../../../assets/images/preview/channel-ico.png');">
            </div>
            </div>
        </div>
      </div>
   </div>
</ng-template>
<span style="font-size:11px;color:#ffffff;"><I  i18n="@@musicCard.streaming">*Music streaming service may require starting a Free
   subscription.</I></span>
<br><br>