import { environment } from "src/environments/environment";

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
 export const b2cPolicies = {
    names: {
        signUpSignIn: environment.names_signUpSignIn,
        forgotPassword: environment.names_forgotPassword,
        editProfile: environment.names_editProfile
    },
    authorities: {
        signUpSignIn: {
            authority: environment.authority_signUpSignIn,
        },
        forgotPassword: {
            authority: environment.authority_forgotPassword,
        },
        editProfile: {
            authority: environment.authority_editProfile
        }
    },
    authorityDomain: environment.authorityDomain
};


/**
 * Enter here the coordinates of your Web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig: {scopes: string[]; uri: string} = {
    scopes: [environment.b2c_api_scopes],
    uri: environment.b2c_api_uri
};
