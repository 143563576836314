<div class="row" *ngIf="!isForCarousel">
   <ng-container [ngTemplateOutlet]="radioNoRecords" [ngTemplateOutletContext]="{radioChannels:radioChannels}"></ng-container>
   <div class="grid-lg-width col-md-3 col-sm-4 col-6" *ngFor="let radioChannel of radioChannels" [hidden]="radioChannels.length==0">
       <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{radioChannel:radioChannel}"></ng-container>
   </div>
</div>

<div class="carousel" *ngIf="isForCarousel">
   <ng-container [ngTemplateOutlet]="radioNoRecords" [ngTemplateOutletContext]="{radioChannels:radioChannels}"></ng-container>
   <owl-carousel-o [options]="customOptions" *ngIf="radioChannels.length>0">
       <ng-container *ngFor="let radioChannel of radioChannels">
           <ng-template carouselSlide [width]="170">
               <div class="movie type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                   <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{radioChannel:radioChannel}"></ng-container>
               </div>
           </ng-template>
       </ng-container>
   </owl-carousel-o>
</div>

<ng-template #radioNoRecords let-movie='radioChannels'>
   <strong *ngIf="radioChannels?.length==0"  i18n="@@radioCard.noRadio">No Radio to display!</strong>
</ng-template>

<ng-template #cardRef let-radioChannel='radioChannel'>
   <div class="gen-carousel-movies-style-2 movie-grid style-2">
      <div class="gen-movie-contain">
         <div class="gen-movie-img">
            <img [src]="radioChannel.thumbnailUrl?radioChannel.thumbnailUrl:defaultImagePath"
               alt="hopiflix">
               <ngx-icons [video]="radioChannel" [patientPlaylistItem]="patientPlaylistItem" (newPlayList)="getUpdatedPlayList($event)"></ngx-icons>
            <div class="gen-movie-action">
               <a  href="javascript:void(0)" (click)="checkUserLogInForRadioPlayer(radioChannel.url)" class="gen-button radiobg">
                  <i class="fa fa-play"></i>
               </a>
            </div>
         </div>
         <div
         style="width:70px;height:39px;background-image: url('../../../assets/images/preview/channel-ico.png');">
     </div>
      </div>
   </div>
</ng-template>