import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiUrlService } from '../utils';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  constructor(private apiUrlService: ApiUrlService, private httpClient: HttpClient) { }

  createConnection(username:any,clientIp:any,facilityId:any): Observable<any> {
    const payload = {
      byodUsername: username,
      ipAddress: clientIp,
      facilityId: facilityId,
      tag: "test"
    };
    const url = `${this.apiUrlService.connectionUrl}/byod-connections`;
    return this.httpClient.post<any>(url, payload, { observe: 'response' })
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError<any>(`createConnection url=${url}`))
      );
  }

  updateConnection(connectionId:any,username:any,clientIp:any,facilityId:any): Observable<any> {
    const payload = {
      connectionId:connectionId,
      byodUsername: username,
      ipAddress: clientIp,
      facilityId: facilityId,
      tag: "test"
    };
    const url = `${this.apiUrlService.connectionUrl}/facilities/${facilityId}/byod-connections `;
    return this.httpClient.put<any>(url, payload, { observe: 'response' })
      .pipe(catchError(this.handleError<any>(`updateConnection url=${url}`))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
