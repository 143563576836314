import { Observable } from "rxjs";
import { Movie, Movies } from "./movie";


export interface Kids {
    id: string;
    title: string;
    videoId: string;
    trailerId: string;
    synopsis: string;
    thumbnailPath?: string;
    thumbnailName: string;
    rating: number;
    duration: string;
    publishYear: string;
    addedDate: string;
    language: string;
    ageRating: string;
    videoQuality: string;
    videoType: string;
    genres?: string;
    genreNames?: string;
    actors?: string;
    director?: string;
    profileId_movie?: string;
    profileId_trailer?: string;
    sig_movie?: string;
    sig_trailer?: string;
    iFrameTagSrc?: string;
    videoTagSrc?: string;
    videoTagSrcTrailer?: string;
    playlist:boolean;
    displayPriority:string
}
export abstract class KidsData {
    abstract getRecommendedKidsVideos(ceFacilityId: number,langCode:string): Observable<Kids[]>;
    abstract getKids(orderBy: string, ceFacilityId: number, pageIndex: number, pageSize: number, filters: string): Observable<Movies>;
    abstract getKidsPlayList(videoIds: any, ceFacilityId: number): Observable<Array<Movie>>;
}
