<div class="row" *ngIf="!isForCarousel">
   <ng-container [ngTemplateOutlet]="healthEducationNoRecords" [ngTemplateOutletContext]="{healthEducationMedias:healthEducationMedias}"></ng-container>
   <div class="grid-lg-width col-md-3 col-sm-4 col-6" *ngFor="let healthEducationMedia of healthEducationMedias" [hidden]="healthEducationMedias.length==0">
       <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{healthEducationMedia:healthEducationMedia}"></ng-container>
   </div>
</div>

<div class="carousel" *ngIf="isForCarousel">
   <ng-container [ngTemplateOutlet]="healthEducationNoRecords" [ngTemplateOutletContext]="{healthEducationMedias:healthEducationMedias}"></ng-container>
   <owl-carousel-o [options]="customOptions" *ngIf="healthEducationMedias.length>0">
       <ng-container *ngFor="let healthEducationMedia of healthEducationMedias">
           <ng-template carouselSlide [width]="170">
               <div class="movie type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                   <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{healthEducationMedia:healthEducationMedia}"></ng-container>
               </div>
           </ng-template>
       </ng-container>
   </owl-carousel-o>
</div>

<ng-template #healthEducationNoRecords let-movie='healthEducationMedias'>
   <strong *ngIf="healthEducationMedias?.length==0" i18n="@@healthEducationCard.noHealthEducation">No Health Education to display!</strong>
</ng-template>

<ng-template #cardRef let-healthEducationMedia='healthEducationMedia'>
   <div class="gen-carousel-movies-style-2 movie-grid style-2">
      <div class="gen-movie-contain">
         <div class="gen-movie-img">
            <img  src="{{healthEducationMedia.thumbnail}}" onerror="this.src='../../../../../assets/images/media-group.png'" alt="owl-carousel"> 
            <div class="gen-movie-action">
               <a href="javascript:void(0)" (click)="gotoMediaGroup(healthEducationMedia?.i18nTitle,healthEducationMedia.id)" class="gen-button">
                  <i class="fa fa-play"></i>
               </a>
            </div>
         </div>
         <!-- <div class="gen-movie-img" style="background: grey">
            <a href="javascript:void(0)">
               <img class="imgCustom" [src]="defaultImagePath"
                  (click)="gotoMediaGroup(healthEducationMedia.id)"></a>
            <ngx-icons></ngx-icons>
         </div>  -->
         <div class="gen-info-contain">
            <div class="gen-movie-info">
               <h3><a href="javascript:void(0)" (click)="gotoMediaGroup(healthEducationMedia?.i18nTitle,healthEducationMedia.id)">{{healthEducationMedia?.i18nTitle?.substring(0,30)}}</a>
               </h3>
            </div>
            <div class="gen-movie-meta-holder">
               <ul>
                  <li>
                     <span style="font-weight:bold" >
                       <span i18n="@@healthEducationCard.category">Category</span>
                        <span>:</span></span> <a href="javascript:void(0)" style="cursor: default;"><span
                           class="px-2">{{healthEducationMedia?.mediaGroupCategory?.name}}</span></a>
                  </li>
               </ul>
               <br>
               <div
                   style="width:70px;height:39px;background-image: url('../../../assets/images/preview/channel-ico.png');">
               </div>
            </div>
         </div>
      </div>
   </div>
</ng-template>