<header id="gen-header" class="gen-header-style-1 gen-has-sticky">
        <div class="gen-bottom-header">
                <div class="container">
                        <!-- topnavbar -->

                        <div id="forLargeScreen" class="row" style="height:auto;">
                                <div class="col-sm-12 d-flex justify-content-between text-left">

                                        <span class="navbar-brand pt-3">
                                                <a [routerLink]="['/home']">
                                                        <img class="img-fluid logo header-logo"
                                                                [src]="hasEnCulture?'../../../../assets/images/logo-1.png' : '../../../../assets/images/hopiflix-french-logo.png'"
                                                             width="210" height="115" alt="hopiflix">
                                                </a> <br>
                                                <span class="d-flex justify-content-center text-danger"
                                                        style="font-size: 13px;"
                                                        i18n="@@footer.confidential">CONFIDENTIAL</span>

                                        </span>


                                        <div class="headerTopRight d-flex justify-content-end">
                                                <div>
                                                        <nav class="navbar navbar-expand-lg navbar-light">
                                                                <a (click)="changeUserView()"
                                                                class="gen-button-topB"
                                                                style="background-color: #3f9fff;">
                                                                <span *ngIf="!isTileview" i18n="@@header.tiled-view">TILED VIEW</span>
                                                                <span *ngIf="isTileview" i18n="@@header.web-view">WEB VIEW</span></a>
                                                                <a [routerLink]="['/home/rental']"
                                                                        class="gen-button-topB rent-service-color"
                                                                        i18n="@@header.rentService">RENT
                                                                        SERVICES</a>
                                                                <ul id="gen-main-menu p-2" class="navbar-nav ml-auto">
                                                                        <li class="menu-item"
                                                                                (mouseover)="showFacilityDropDown=true"
                                                                                (mouseout)="showFacilityDropDown=false">
                                                                                <a href="javascript:void(0)"
                                                                                        *ngIf="!selectedFacility"><span
                                                                                                *ngIf="demoModeEnabled"
                                                                                                i18n="@@header.select-facility">Select
                                                                                                Facility</span>
                                                                                        <span *ngIf="!demoModeEnabled"
                                                                                                i18n="@@header.unknown-hospital">Unknown
                                                                                                Hospital</span>
                                                                                </a>
                                                                                <a href="javascript:void(0)"
                                                                                        *ngIf="selectedFacility"
                                                                                        class="facility-width">
                                                                                        {{selectedFacility?.name}}</a>
                                                                                <i
                                                                                        class="fa fa-chevron-down gen-submenu-icon pr-0"></i>
                                                                                <div
                                                                                        [ngClass]="{'show-dropdown': showFacilityDropDown ,'close-dropdown': !showFacilityDropDown}">
                                                                                        <ul class="sub-menu"
                                                                                                style="max-height: 21rem;overflow-y: auto;text-align: left;">
                                                                                                <li class="menu-item"
                                                                                                        *ngIf="facilities.length>0">
                                                                                                        <a
                                                                                                                i18n="@@header.select-facility">Select
                                                                                                                Facility</a>
                                                                                                </li>
                                                                                                <li class="menu-item"
                                                                                                        *ngIf="facilities.length==0">
                                                                                                        <a
                                                                                                                i18n="@@header.noFacility">No
                                                                                                                Facility
                                                                                                                to
                                                                                                                display!</a>
                                                                                                </li>
                                                                                                <li class="menu-item"
                                                                                                        *ngFor="let facility of facilities">
                                                                                                        <a href="javascript:void(0)"
                                                                                                                (click)="!demoModeEnabled && facility.id!= selectedFacility?.id? false : onChangeFacility(facility)"
                                                                                                                [ngClass]="{'disabled': !demoModeEnabled && facility.id!=selectedFacility?.id}">{{hasEnCulture?facility.fullName:facility.fullNameFr}}
                                                                                                        </a>
                                                                                                </li>
                                                                                        </ul>
                                                                                </div>
                                                                        </li>
                                                                </ul>
                                                        </nav>
                                                </div>
                                                <div *ngIf="userName!=''" class="p-2">
                                                        <a [routerLink]="['/home/account']"
                                                                class="gen-user-holder mr-1">{{userName}}</a>

                                                        <span class="gen-account-holder mr-1" (mouseover)="dropdownShow=true"
                                                                (mouseout)="dropdownShow=false">
                                                                <a href="javascript:void(0)" id="gen-user-btn"><i
                                                                                class="fa fa-user"></i></a>
                                                                <div class="gen-account-menu"
                                                                        [ngClass]="{'show-dropdown': dropdownShow ,'close-dropdown': !dropdownShow}">
                                                                        <ul class="gen-account-menu"
                                                                                [ngClass]="{'show-dropdown': dropdownShow ,'close-dropdown': !dropdownShow}">
                                                                                <li><a [routerLink]="['/home/account']"><i
                                                                                                        class="fa fa-user"></i><span
                                                                                                        i18n="@@header.account">My
                                                                                                        Account</span></a>
                                                                                </li>
                                                                                <li>
                                                                                        <a href="javascript:void(0)"
                                                                                                (click)="openServicePopup()"><i
                                                                                                        class="fa fa-cogs"></i>
                                                                                                <span
                                                                                                        i18n="@@header.myService">My
                                                                                                        Service(s)</span></a>
                                                                                </li>
                                                                                <li>
                                                                                        <a
                                                                                                [routerLink]="['/home/my-playlist']"><i
                                                                                                        class="fa fa-list"></i>
                                                                                                <span
                                                                                                        i18n="@@header.my-playlist">My
                                                                                                        Playlist
                                                                                                </span></a>
                                                                                </li>
                                                                                <li><a [routerLink]="['/home/call-centre']"><i
                                                                                                        class="fa fa-question"></i>
                                                                                                <span
                                                                                                        i18n="@@header.call-centre">Call
                                                                                                        Centre</span></a>
                                                                                </li>
                                                                                <li>
                                                                                        <a href="javascript:void(0)"
                                                                                                (click)="logout()"><i
                                                                                                        class="fa fa-sign-out-alt"></i>
                                                                                                <span
                                                                                                        i18n="@@header.signOut">Sign
                                                                                                        Out</span></a>
                                                                                </li>
                                                                        </ul>
                                                                </div>
                                                        </span>
                                                </div>
                                                <div *ngIf="userName==''" class="p-2">
                                                        <a href="javascript:void(0)" (click)="openloginRedirect()"
                                                                target="_top" class="gen-button-topB"
                                                                i18n="@@header.signIn">Sign
                                                                In</a>

                                                        <!-- <a *ngIf="userName!=''" href="javascript:void(0)" (click)="logout()"
                                                        class="gen-button-topB" i18n="@@header.signOut">Sign Out</a> -->
                                                </div>
                                                <div class="p-1 display-language-dropdown">
                                                        <nav class="navbar navbar-expand-lg navbar-light p-2">
                                                                <ul id="gen-main-menu " class="navbar-nav ml-auto"
                                                                        (mouseover)="showLanguageDropdown=true"
                                                                        (mouseout)="showLanguageDropdown=false">
                                                                        <li class="menu-item">
                                                                                <a
                                                                                        href="javascript:void(0)">{{language}}</a>
                                                                                <i
                                                                                        class="fa fa-chevron-down gen-submenu-icon pr-0"></i>
                                                                                <div
                                                                                        [ngClass]="{'show-dropdown': showLanguageDropdown ,'close-dropdown': !showLanguageDropdown}">
                                                                                        <ul class="sub-menu"
                                                                                                style="max-height: 21rem;overflow-y: auto;text-align: left;">
                                                                                                <li class="menu-item">
                                                                                                        <a href="javascript:void(0)"
                                                                                                                (click)="switchLanguage('en')">English
                                                                                                        </a>
                                                                                                </li>
                                                                                                <li class="menu-item">
                                                                                                        <a href="javascript:void(0)"
                                                                                                                (click)="switchLanguage('fr')">Français
                                                                                                        </a>
                                                                                                </li>
                                                                                        </ul>
                                                                                </div>
                                                                </ul>
                                                        </nav>
                                                </div>
                                                <button *ngIf="!isTileview" class="navbar-toggler" type="button" data-toggle="collapse"
                                                        data-target="#navbarSupportedContent"
                                                        aria-controls="navbarSupportedContent" aria-expanded="false"
                                                        aria-label="Toggle navigation">
                                                        <i class="fas fa-bars"></i>
                                                </button>
                                        </div>

                                </div>
                        </div>

                        <div id="forSmallScreen" class="row">
                                <div class="col-lg-12" [ngClass]="{'text-right':userName!=''}">
                                        <span *ngIf="userName!=''">
                                                <a [routerLink]="['/home/account']"
                                                        class="gen-user-holder mr-1">{{userName}}</a>

                                                <span class="gen-account-holder mr-1">
                                                        <a href="javascript:void(0)"
                                                                (click)="showDropDown(dropdownShow)"
                                                                id="gen-user-btn"><i class="fa fa-user"></i></a>
                                                        <div class="gen-account-menu"
                                                                [ngClass]="{'show-dropdown': dropdownShow ,'close-dropdown': !dropdownShow}">
                                                                <ul class="gen-account-menu"
                                                                        [ngClass]="{'show-dropdown': dropdownShow , 'close-dropdown': !dropdownShow}">
                                                                        <li>
                                                                                <a [routerLink]="['/home/my-playlist']"><i
                                                                                                class="fa fa-list"></i>
                                                                                        <span
                                                                                                i18n="@@header.my-playlist">My
                                                                                                Playlist </span></a>
                                                                        </li>
                                                                        <li><a [routerLink]="['/home/account']"><i
                                                                                                class="fa fa-user"></i><span
                                                                                                i18n="@@header.account">My
                                                                                                Account</span></a>
                                                                        </li>
                                                                        <li>
                                                                                <a href="javascript:void(0)"
                                                                                        (click)="openServicePopup()"><i
                                                                                                class="fa fa-cogs"></i>
                                                                                        <span i18n="@@header.myService">My
                                                                                                Service(s)</span></a>
                                                                        </li>
                                                                        <li><a [routerLink]="['/home/call-centre']"><i
                                                                                                class="fa fa-question"></i>
                                                                                        <span
                                                                                                i18n="@@header.call-centre">Call
                                                                                                Centre</span></a></li>
                                                                        <li>
                                                                                <a href="javascript:void(0)"
                                                                                        (click)="logout()"><i
                                                                                                class="fa fa-sign-out-alt"></i>
                                                                                        <span i18n="@@header.signOut">Sign
                                                                                                Out</span></a>
                                                                        </li>
                                                                </ul>
                                                        </div>
                                                </span>
                                        </span>
                                        <span *ngIf="userName==''"><span class="navbar-brand">
                                                        <a [routerLink]="['/home']">
                                                                <img class="img-fluid logo"
                                                                [src]="hasEnCulture?'../../../../assets/images/logo-1.png' : '../../../../assets/images/hopiflix-french-logo.png'"
                                                                        width="160" height="115" alt="hopiflix">
                                                        </a> <br>
                                                        <span class="d-flex justify-content-center text-danger"
                                                                style="font-size: 12px;"
                                                                i18n="@@footer.confidential">CONFIDENTIAL</span>

                                                </span></span>
                                        <button  *ngIf="!isTileview" class="navbar-toggler" type="button" data-toggle="collapse"
                                                data-target="#navbarSupportedContent"
                                                aria-controls="navbarSupportedContent" aria-expanded="false"
                                                aria-label="Toggle navigation">
                                                <i class="fas fa-bars"></i>
                                        </button>
                                </div>
                                <div class="width-full">
                                        <div class="d-flex justify-content-between p-2">
                                                <span *ngIf="userName!=''" class="navbar-brand">
                                                        <a [routerLink]="['/home']">
                                                                <img class="img-fluid logo"
                                                                [src]="hasEnCulture?'../../../../assets/images/logo-1.png' : '../../../../assets/images/hopiflix-french-logo.png'"
                                                                        width="160" height="115" alt="hopiflix">
                                                        </a> <br>
                                                        <span class="d-flex justify-content-center text-danger"
                                                                style="font-size: 12px;"
                                                                i18n="@@footer.confidential">CONFIDENTIAL</span>

                                                </span>
                                                <span *ngIf="userName==''">
                                                        <a href="javascript:void(0)" target="_top"
                                                                (click)="openloginRedirect()" class="gen-button-topB"
                                                                i18n="@@header.signIn">Sign
                                                                In</a>
                                                </span>
                                                <span class="menu-item">
                                                      <a (click)="changeUserView()"
                                                        class="gen-button-topB mb-1"
                                                        style="background-color: #3f9fff;">
                                                        <span *ngIf="!isTileview" i18n="@@header.tiled-view">TILED VIEW</span>
                                                        <span *ngIf="isTileview" i18n="@@header.web-view">WEB VIEW</span></a>
                                                        <a [routerLink]="['/home/rental']"
                                                                class="gen-button-topB rent-service-color mb-1"
                                                                i18n="@@header.rentService">RENT
                                                                SERVICES</a>
                                                </span>

                                                <!-- <span class="menu-item">
                                                     
                                                        <a [routerLink]="['/home/rental']"
                                                                class="gen-button-topB rent-service-color"
                                                                i18n="@@header.rentService">TILED VIEW</a>
                                                </span> -->
                                        </div>
                                </div>
                        </div>
                        <nav class="display-facility navbar navbar-expand-lg navbar-light width-full">
                                <div class="row">
                                        <div class="col-9 facility-language-padding">
                                                <ul id="gen-main-menu" class="navbar-nav ml-auto">
                                                        <li class="menu-item"
                                                                (click)="showFacilityDropDown=!showFacilityDropDown">
                                                                <a href="javascript:void(0)"
                                                                        *ngIf="!selectedFacility"><span
                                                                                *ngIf="demoModeEnabled"
                                                                                i18n="@@header.select-facility">Select
                                                                                Facility</span>
                                                                        <span *ngIf="!demoModeEnabled"
                                                                                i18n="@@header.unknown-hospital">Unknown
                                                                                Hospital</span>
                                                                </a>
                                                                <a href="javascript:void(0)" *ngIf="selectedFacility"
                                                                        class="facility-display">
                                                                        {{selectedFacility?.name}}</a>
                                                                <i class="fa fa-chevron-down gen-submenu-icon pr-0"></i>
                                                                <ul class="sub-menu pl-0"
                                                                        [ngClass]="{'show-dropdown': showFacilityDropDown ,'close-dropdown': !showFacilityDropDown}"
                                                                        style="max-height: 21rem;overflow-y: auto;text-align: left;">
                                                                        <li class="menu-item"
                                                                                *ngIf="facilities.length>0">
                                                                                <a i18n="@@header.select-facility">Select
                                                                                        Facility</a>
                                                                        </li>
                                                                        <li class="menu-item"
                                                                                *ngIf="facilities.length==0">
                                                                                <a i18n="@@header.noFacility">No
                                                                                        Facility to
                                                                                        display!</a>
                                                                        </li>
                                                                        <li class="menu-item"
                                                                                *ngFor="let facility of facilities">
                                                                                <a href="javascript:void(0)"
                                                                                        (click)="!demoModeEnabled && facility.id!= selectedFacility?.id? false : onChangeFacility(facility)"
                                                                                        [ngClass]="{'disabled': !demoModeEnabled && facility.id!=selectedFacility?.id}">{{hasEnCulture?facility.fullName:facility.fullNameFr}}
                                                                                </a>
                                                                        </li>
                                                                </ul>
                                                        </li>
                                                </ul>
                                        </div>
                                        <div class="col-3 facility-language-padding">
                                                <ul id="gen-main-menu" class="navbar-nav ml-auto">
                                                        <li class="menu-item"
                                                                (click)="showLanguageDropdown=!showLanguageDropdown">
                                                                <a href="javascript:void(0)">{{language}}</a>
                                                                <i class="fa fa-chevron-down gen-submenu-icon pr-0"></i>
                                                                <ul class="sub-menu pl-0"
                                                                        [ngClass]="{'show-dropdown': showLanguageDropdown ,'close-dropdown': !showLanguageDropdown}"
                                                                        style="max-height: 21rem;overflow-y: auto;text-align: left;">
                                                                        <li class="menu-item">
                                                                                <a href="javascript:void(0)"
                                                                                        (click)="switchLanguage('en')">English
                                                                                </a>
                                                                        </li>
                                                                        <li class="menu-item">
                                                                                <a href="javascript:void(0)"
                                                                                        (click)="switchLanguage('fr')">Français
                                                                                </a>
                                                                        </li>
                                                                </ul>
                                                </ul>
                                        </div>
                                </div>
                        </nav>

                        <!-- topnavbar -->

                        <div class="row" *ngIf="!isTileview">

                                <div class="col-lg-12">

                                        <nav class="navbar navbar-expand-lg navbar-light">

                                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                                        <div id="gen-menu-contain" class="gen-menu-contain">
                                                                <ul id="gen-main-menu" class="navbar-nav ml-auto">

                                                                        <li class="menu-item"
                                                                                [ngClass]="{'active':home.isActive}"><a
                                                                                        [routerLink]="['/home/landing']"
                                                                                        routerLinkActive
                                                                                        #home="routerLinkActive"
                                                                                        i18n="@@header.home"
                                                                                        data-toggle="collapse"
                                                                                        data-target=".navbar-collapse.show">Home</a>
                                                                        </li>

                                                                        <li class="menu-item"
                                                                                (mouseover)="showInfotainmentDropdown=true"
                                                                                (mouseout)="showInfotainmentDropdown=false"
                                                                                [ngClass]="{'active':iptv.isActive||movies.isActive||music.isActive||radio.isActive||audio.isActive||relaxation.isActive||kids.isActive}">
                                                                                <a href="javascript:void(0)"
                                                                                        i18n="@@header.infotainment">Infotainment</a>
                                                                                <i
                                                                                        class="fa fa-chevron-down gen-submenu-icon"></i>
                                                                                <div
                                                                                        [ngClass]="{'show-dropdown': showInfotainmentDropdown, 'close-dropdown': !showInfotainmentDropdown}">
                                                                                        <ul class="sub-menu"
                                                                                                (click)="closeHeaderDropDown()"
                                                                                                data-toggle="collapse"
                                                                                                data-target=".navbar-collapse.show">
                                                                                                <li
                                                                                                        class="menu-item iptv">
                                                                                                        <a (click)="redirectToIptv()"
                                                                                                                routerLinkActive
                                                                                                                #iptv="routerLinkActive"
                                                                                                                i18n="@@header.iptv">IPTV</a>
                                                                                                </li>
                                                                                                <li
                                                                                                        class="menu-item movie">
                                                                                                        <a [routerLink]="['/home/infotainment/movie']"
                                                                                                                routerLinkActive
                                                                                                                #movies="routerLinkActive"
                                                                                                                i18n="@@header.movies">Movies</a>
                                                                                                </li>
                                                                                                <li
                                                                                                        class="menu-item music">
                                                                                                        <a routerLinkActive
                                                                                                                #music="routerLinkActive"
                                                                                                                [routerLink]="['/home/infotainment/music']"
                                                                                                                i18n="@@header.music">Music</a>
                                                                                                </li>
                                                                                                <li
                                                                                                        class="menu-item radio">
                                                                                                        <a routerLinkActive
                                                                                                                #radio="routerLinkActive"
                                                                                                                [routerLink]="['/home/infotainment/radio']"
                                                                                                                i18n="@@header.radio">Radio</a>
                                                                                                </li>
                                                                                                <li
                                                                                                        class="menu-item audio">
                                                                                                        <a routerLinkActive
                                                                                                                #audio="routerLinkActive"
                                                                                                                [routerLink]="['/home/infotainment/audio-book']"
                                                                                                                i18n="@@header.audio-book">Audio
                                                                                                                Books</a>
                                                                                                </li>
                                                                                                <li
                                                                                                        class="menu-item relaxation">
                                                                                                        <a routerLinkActive
                                                                                                                #relaxation="routerLinkActive"
                                                                                                                [routerLink]="['/home/infotainment/relaxation']"
                                                                                                                i18n="@@header.relaxation"><span>Relaxation</span></a>
                                                                                                </li>

                                                                                                <li
                                                                                                        class="menu-item kids">
                                                                                                        <a routerLinkActive
                                                                                                                #kids="routerLinkActive"
                                                                                                                [routerLink]="['/home/infotainment/kids']">
                                                                                                                <div
                                                                                                                        *ngIf="!hasEnCulture">
                                                                                                                        <span
                                                                                                                                class="red">E</span><span
                                                                                                                                class="blue">n</span><span
                                                                                                                                class="green">f</span><span
                                                                                                                                class="pink">a</span><span
                                                                                                                                class="orange">n</span><span
                                                                                                                                class="white">t</span><span
                                                                                                                                class="yellow">s</span>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                        *ngIf="hasEnCulture">
                                                                                                                        <span
                                                                                                                                class="red">K</span><span
                                                                                                                                class="blue">i</span><span
                                                                                                                                class="green">d</span><span
                                                                                                                                class="yellow">s</span>
                                                                                                                </div>
                                                                                                        </a>
                                                                                                </li>
                                                                                                <li
                                                                                                        class="menu-item relaxation">
                                                                                                        <a routerLinkActive
                                                                                                                #relaxation="routerLinkActive"
                                                                                                                [routerLink]="['/home/infotainment/streaming-services']"
                                                                                                                i18n="@@header.streaming-services"><span>Streaming Services</span></a>
                                                                                                </li>
                                                                                        </ul>
                                                                                </div>
                                                                        </li>

                                                                        <li class="menu-item"
                                                                                (click)="closeHeaderDropDown()"
                                                                                data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"
                                                                                [ngClass]="{'active':playlist.isActive}">
                                                                                <a routerLinkActive
                                                                                        #playlist="routerLinkActive"
                                                                                        [routerLink]="['/home/my-playlist']"
                                                                                        i18n="@@header.playlist">My
                                                                                        Playlist</a>
                                                                        </li>

                                                                        <li class="menu-item"
                                                                                [ngClass]="{'active':videoCall.isActive}"
                                                                                (mouseover)="showCommunications=true"
                                                                                (mouseout)="showCommunications=false">
                                                                                <a href="javascript:void(0)"
                                                                                        i18n="@@header.communications">Communications</a>
                                                                                <i
                                                                                        class="fa fa-chevron-down gen-submenu-icon"></i>
                                                                                <div
                                                                                        [ngClass]="{'show-dropdown': showCommunications, 'close-dropdown': !showCommunications}">
                                                                                        <ul class="sub-menu"
                                                                                                (click)="closeHeaderDropDown()"
                                                                                                data-toggle="collapse"
                                                                                                data-target=".navbar-collapse.show">
                                                                                                <!-- <li
                                                                                                        class="menu-item call">
                                                                                                        <a href="https://hopiface-dev.azurewebsites.net/index.html"
                                                                                                                target="_blank"
                                                                                                                i18n="@@header.videocall">Video
                                                                                                                Call</a>
                                                                                                </li> -->
                                                                                                <li
                                                                                                        class="menu-item videocall">
                                                                                                        <a routerLinkActive
                                                                                                                #videoCall="routerLinkActive"
                                                                                                                [routerLink]="['/home/communications/interactive-whiteboard']"
                                                                                                                i18n="@@header.InteractiveWhiteBoard">Interactive
                                                                                                                Whiteboard</a>
                                                                                                </li>
                                                                                        </ul>
                                                                                </div>
                                                                        </li>

                                                                        <li class="menu-item"
                                                                                (mouseover)="showInfoHealth=true"
                                                                                (mouseout)="showInfoHealth=false"
                                                                                [ngClass]="{'active':health.isActive||onDemand.isActive||scheduledEducation.isActive}">
                                                                                <a href="javascript:void(0)"
                                                                                        i18n="@@header.infohealth">InfoHealth</a>
                                                                                <i
                                                                                        class="fa fa-chevron-down gen-submenu-icon"></i>
                                                                                <div
                                                                                        [ngClass]="{'show-dropdown': showInfoHealth, 'close-dropdown': !showInfoHealth}">
                                                                                        <ul class="sub-menu"
                                                                                                data-toggle="collapse"
                                                                                                data-target=".navbar-collapse.show"
                                                                                                (click)="closeHeaderDropDown()">
                                                                                                <li class="menu-item">
                                                                                                        <a routerLinkActive
                                                                                                                #health="routerLinkActive"
                                                                                                                [routerLink]="['/home/info-health/health-education']"
                                                                                                                i18n="@@header.health-education">Health
                                                                                                                Education
                                                                                                                - VOD
                                                                                                                Groups
                                                                                                        </a>
                                                                                                </li>
                                                                                                <li class="menu-item">
                                                                                                        <a routerLinkActive
                                                                                                                #onDemand="routerLinkActive"
                                                                                                                [routerLink]="['/home/info-health/video-on-demand']"
                                                                                                                i18n="@@header.video-on-demand">Health
                                                                                                                Education
                                                                                                                -
                                                                                                                VOD</a>
                                                                                                </li>
                                                                                                <li class="menu-item">
                                                                                                        <a routerLinkActive
                                                                                                                #scheduledEducation="routerLinkActive"
                                                                                                                [routerLink]="['/home/info-health/scheduled-education']"
                                                                                                                i18n="@@header.scheduled-education">Health
                                                                                                                Education
                                                                                                                -
                                                                                                                Scheduled</a>
                                                                                                </li>
                                                                                        </ul>
                                                                                </div>
                                                                        </li>


                                                                        <li class="menu-item"
                                                                                (mouseover)="showPatientInfo=true"
                                                                                (mouseout)="showPatientInfo=false"
                                                                                [ngClass]="{'active':prescribed.isActive||followup.isActive||survey.isActive||document.isActive,'nofication-margin-right':totalUnseenPrescription>0}">
                                                                                <a href="javascript:void(0)"
                                                                                        i18n="@@header.prescriptions">
                                                                                        Prescriptions
                                                                                </a>
                                                                                <i
                                                                                        class="fa fa-chevron-down gen-submenu-icon"></i>
                                                                                <span class="prescription-number-holder"
                                                                                        *ngIf="totalUnseenPrescription>0"><a
                                                                                                href="javascript:void(0)"
                                                                                                id="gen-num-btn"
                                                                                                style="padding: 0px;"
                                                                                                data-toggle="collapse"
                                                                                                data-target=".navbar-collapse.show"
                                                                                                (click)="gotoNotification()">{{totalUnseenPrescription}}</a></span>
                                                                                <div
                                                                                        [ngClass]="{'show-dropdown': showPatientInfo , 'close-dropdown': !showPatientInfo}">
                                                                                        <ul class="sub-menu"
                                                                                                data-toggle="collapse"
                                                                                                data-target=".navbar-collapse.show"
                                                                                                (click)="closeHeaderDropDown()">
                                                                                                <li class="menu-item">
                                                                                                        <a routerLinkActive
                                                                                                                #prescribed="routerLinkActive"
                                                                                                                [routerLink]="['/home/prescriptions/prescribed-video']"
                                                                                                                i18n="@@header.prescribed-video">Prescribed
                                                                                                                Videos
                                                                                                        </a>
                                                                                                </li>
                                                                                                <li class="menu-item">
                                                                                                        <a routerLinkActive
                                                                                                                #document="routerLinkActive"
                                                                                                                [routerLink]="['/home/prescriptions/document']"
                                                                                                                i18n="@@header.document">Prescribed
                                                                                                                Documents
                                                                                                        </a>
                                                                                                </li>
                                                                                                <li class="menu-item">
                                                                                                        <a routerLinkActive
                                                                                                                #followup="routerLinkActive"
                                                                                                                [routerLink]="['/home/prescriptions/followup']"
                                                                                                                i18n="@@header.followup">Prescribed
                                                                                                                Follow-Up
                                                                                                                Questionnaires</a>
                                                                                                </li>
                                                                                               
                                                                                                <li class="menu-item">
                                                                                                        <a routerLinkActive
                                                                                                                #survey="routerLinkActive"
                                                                                                                [routerLink]="['/home/prescriptions/survey']"
                                                                                                                i18n="@@header.survey">Prescribed
                                                                                                                Surveys</a>
                                                                                                </li>

                                                                                        </ul>
                                                                                </div>
                                                                        </li>
                                                                        <li class="menu-item"
                                                                                (mouseover)="showHospital=true"
                                                                                (mouseout)="showHospital=false">
                                                                                <a href="javascript:void(0)"
                                                                                        i18n="@@header.hospital-info"
                                                                                        (click)="showHospitalDropdown()">Hospital
                                                                                        Info</a>
                                                                                <i
                                                                                        class="fa fa-chevron-down gen-submenu-icon"></i>
                                                                                <div
                                                                                        [ngClass]="{'show-dropdown': showHospital , 'close-dropdown': !showHospital}">
                                                                                        <ul class="sub-menu"
                                                                                                data-toggle="collapse"
                                                                                                data-target=".navbar-collapse.show">
                                                                                                <li *ngFor="let hospitalInfo of hospitalInfos"
                                                                                                        class="menu-item">
                                                                                                        <a
                                                                                                                (click)="openViewerPopUp(hospitalInfo.LinkUrl)">{{hospitalInfo.title}}</a>
                                                                                                </li>
                                                                                        </ul>
                                                                                </div>
                                                                        </li>
                                                                        <li class="menu-item"
                                                                                [ngClass]="{'active':roomService.isActive}"
                                                                                (mouseover)="showRooms=true"
                                                                                (mouseout)="showRooms=false">
                                                                                <a href="javascript:void(0)"
                                                                                        i18n="@@header.roomService">Room
                                                                                        Services</a>
                                                                                <i
                                                                                        class="fa fa-chevron-down gen-submenu-icon"></i>
                                                                                <div
                                                                                        [ngClass]="{'show-dropdown': showRooms , 'close-dropdown': !showRooms}">
                                                                                        <ul class="sub-menu"
                                                                                                data-toggle="collapse"
                                                                                                data-target=".navbar-collapse.show"
                                                                                                (click)="closeHeaderDropDown()">
                                                                                                <li class="menu-item">
                                                                                                        <a routerLinkActive
                                                                                                                #roomService="routerLinkActive"
                                                                                                                [routerLink]="['/home/room-service/nurse-call']"
                                                                                                                i18n="@@header.nurse-call">Nurse
                                                                                                                Call</a>
                                                                                                </li>
                                                                                                <li class="menu-item">
                                                                                                        <a routerLinkActive
                                                                                                                #roomService="routerLinkActive"
                                                                                                                [routerLink]="['/home/room-service/concierge-service']"
                                                                                                                i18n="@@header.concierge-service">Concierge
                                                                                                                Service</a>
                                                                                                </li>
                                                                                                <li class="menu-item">
                                                                                                        <a routerLinkActive
                                                                                                                #roomService="routerLinkActive"
                                                                                                                [routerLink]="['/home/room-service/meal-menu']"
                                                                                                                i18n="@@header.meal-menu">Meal
                                                                                                                Menu</a>
                                                                                                </li>
                                                                                                <li class="menu-item">
                                                                                                        <a routerLinkActive
                                                                                                                #roomService="routerLinkActive"
                                                                                                                [routerLink]="['/home/room-service/room-control']"
                                                                                                                i18n="@@header.room-control">Room
                                                                                                                Controls</a>
                                                                                                </li>
                                                                                        </ul>
                                                                                </div>
                                                                        </li>
                                                                        <li class="menu-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"
                                                                                (click)="closeHeaderDropDown()"
                                                                                [ngClass]="{'active':callCentre.isActive}">
                                                                                <a routerLinkActive
                                                                                        #callCentre="routerLinkActive"
                                                                                        [routerLink]="['/home/call-centre']"
                                                                                        i18n="@@header.call-centre">Call
                                                                                        Centre</a>
                                                                        </li>
                                                                        <!-- <li class="menu-item"
                                                                        [ngClass]="{'active':advertisement.isActive}"><a
                                                                                [routerLink]="['/home/advertisement']"
                                                                                routerLinkActive
                                                                                #advertisement="routerLinkActive"
                                                                                data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show">Advertisement</a>
                                                                </li> -->
                                                                </ul>
                                                        </div>
                                                </div>
                                        </nav>
                                </div>
                        </div>
                </div>
             <div class="row" *ngIf="facilityTicker?.tickerMessages &&  facilityTicker?.activated">
                        <marquee style="width: 100%;"
                                [ngStyle]="{'background-color': facilityTicker.backgroundColor, 'color': facilityTicker.fontColor}">
                                <span *ngFor="let tickerMessage of facilityTicker.tickerMessages"
                                style="margin-right: 3rem;">{{tickerMessage.displayMessage}}</span>
                        </marquee>
                </div> 
        </div>
</header>