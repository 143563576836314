import { Observable } from "rxjs";

export interface Rental { }

export abstract class RentalData {
    abstract getServices(ceFacilityId: number): Observable<any>;
    abstract getServicePrices(ceFacilityId: number): Observable<any>;
    abstract getServiceUnits(ceFacilityId: number): Observable<any>;
    abstract getServicePackages(ceFacilityId: number): Observable<any>;
    abstract getRentalPrice(rentalPrice: any): Observable<any>;
    abstract submitOrder(order: any): Observable<any>;
    abstract getRentedServices(ceFacilityId: number, userEmail: string): Observable<any>;
    abstract getUserTransactions(userName: string, pageIndex: number, pageSize: number): Observable<any>;
    abstract sendEmail(emailDto: any): Observable<any>;
    abstract getAllRentedServices(userEmail: string): Observable<Array<any>>
}
