import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MusicAlbum, MusicAlbums, MusicData } from '../data/musicAlbum';
import { ApiUrlService, CommonService } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class MusicService implements MusicData {

  constructor(private urlServices: ApiUrlService, private httpClient: HttpClient, private commonService: CommonService) { }

  getMusicAlbums(ceFacilityId: number, pageSize: number, pageIndex: number, album: string): Observable<MusicAlbums> {
    const url = `${this.urlServices.musicAlbumUrl}/${ceFacilityId}/paged-music-albums/${album}?sortBy=title&pageSize=${pageSize}&pageIndex=${pageIndex}`;
    return this.httpClient.get<MusicAlbums>(url)
      .pipe(
        map((returnData: MusicAlbums) => {
          const musics = this.setPatientPlaylist(returnData.data, ceFacilityId);
          returnData.data = musics;
          return returnData;
        }),
        catchError(this.handleError<MusicAlbums>('getMusicAlbums'))
      );
  }

  getRecommendedMusicAlbums(ceFacilityId: number): Observable<Array<MusicAlbum>> {
    const url = `${this.urlServices.musicAlbumUrl}/${ceFacilityId}/recommended-music-albums`;
    return this.httpClient.get<Array<MusicAlbum>>(url)
      .pipe(
        map((returnData: Array<MusicAlbum>) => {
          let musicAlbums: Array<MusicAlbum> = []
          if (returnData != null || returnData != undefined)
            musicAlbums = this.setPatientPlaylist(returnData, ceFacilityId)
          return musicAlbums;
        }),
        catchError(this.handleError<Array<MusicAlbum>>('getRecommendedMusicAlbums'))
      );
  }

  getMusicPlayList(videoIds: any, ceFacilityId: number): Observable<Array<MusicAlbum>> {
    const url = `${this.urlServices.musicAlbumUrl}/${ceFacilityId}/music-album-list?idList=[${videoIds}]`
    return this.httpClient.get<Array<MusicAlbum>>(url)
      .pipe(
        map((returnData: Array<MusicAlbum>) => {
          let musicAlbums: Array<MusicAlbum> = []
          if (returnData != null || undefined != null)
          musicAlbums = this.setPatientPlaylist(returnData, ceFacilityId);
          return musicAlbums;
        }),
        catchError(this.handleError<Array<MusicAlbum>>(`getMusicPlayList`))
      );
  }

  setPatientPlaylist(musics: Array<MusicAlbum>, ceFacilityId: number) {
    let jsonpatientPlaylist = this.commonService.getLSKey("patientPlaylist");
    if (jsonpatientPlaylist != '' && jsonpatientPlaylist != undefined) {
      const patientPlaylist = JSON.parse(jsonpatientPlaylist);
      if (patientPlaylist?.music.length > 0) {
        musics.map(music => {
          let index = patientPlaylist.music.findIndex((x: any) => x.ceFacilityId == ceFacilityId && x.videoId == music.id);
          if (index >= 0)
            music.playlist = true;
          else
            music.playlist = false;
        });
      }
    }
    return musics;
  }
  
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
