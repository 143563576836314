import { Observable } from "rxjs";

export interface Audiobook {
    id: number;
    label: string;
    thumbnailUrl: string;
    lang: string;
    facilityId: number;
    playlistNbr: string;
    token: string;
    genre: string;
    genreFr: string;
    synopsis: string;
    duration: number;
    playlist:boolean;
}

export interface Audiobooks {
    data: Audiobook[];
    count: number;
}

export abstract class AudiobookData {
    abstract getAllAudiobooks(orderBy: string, ceFacilityId: number, pageIndex: number, pageSize: number, filters: string): Observable<Audiobooks>;
    abstract getRecommendedAudiobooks(ceFacilityId: number): Observable<Array<Audiobook>>
    abstract getAudiobookById(id: number): Observable<Audiobook>;
    abstract getAudiobookPlayList(videoIds: any, ceFacilityId: number): Observable<Array<Audiobook>>;
}
