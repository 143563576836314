<div class="row" *ngIf="!isForCarousel">
    <ng-container [ngTemplateOutlet]="movieNoRecords" [ngTemplateOutletContext]="{movies:movies}"></ng-container>
    <div class="grid-lg-width col-md-3 col-sm-4 col-6" *ngFor="let movie of movies" [hidden]="movies.length==0">
        <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{movie:movie}"></ng-container>
    </div>
</div>

<div class="carousel" *ngIf="isForCarousel">
    <ng-container [ngTemplateOutlet]="movieNoRecords" [ngTemplateOutletContext]="{movies:movies}"></ng-container>
    <owl-carousel-o [options]="customOptions" *ngIf="movies.length>0">
        <ng-container *ngFor="let movie of movies">
            <ng-template carouselSlide [width]="170" [id]="movie.title">
                <div
                    class="movie type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                    <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{movie:movie}"></ng-container>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>

<ng-template #movieNoRecords let-movie='movies'>
    <strong *ngIf="movies?.length==0" i18n="@@movies-card.noMovies">No Movies to display!</strong>
</ng-template>

<ng-template #cardRef let-movie='movie'>
    <div class="gen-carousel-movies-style-2 movie-grid style-2">
        <div class="gen-movie-contain">
            <div class="gen-movie-img">
                <img [src]="movie?.thumbnailName?movieBlobStorage+movie?.thumbnailName:defaultImagePath"
                    alt="owl-carousel-video-image">
                <ngx-icons [video]="movie" [patientPlaylistItem]="patientPlaylistItem" (newPlayList)="getUpdatedPlayList($event)"></ngx-icons>
                <div class="gen-movie-action">
                    <a href="javascript:void(0)" (click)="gotoMovieDetail(movie.id)" class="gen-button moviebg">
                        <i class="fa fa-play"></i>
                    </a>
                </div>
            </div>
            <div class="gen-info-contain">
                <div class="gen-movie-info">
                    <h3><a href="javascript:void(0)" (click)="gotoMovieDetail(movie.id)">{{movie?.title}}</a>
                    </h3>
                </div>
                <div class="gen-movie-meta-holder">
                    <ul>
                        <li>{{movie?.duration |timing}}</li>
                        <li *ngIf="movie?.genreNames!=undefined && movie?.genreNames!=null">
                            <a href="javascript:void(0)">
                                <span class="movie" style="cursor: default;">{{movie?.genreNames}}</span>
                            </a>
                        </li>
                    </ul>
                    <br>
                    <div
                        style="width:70px;height:39px;background-image: url('../../../assets/images/preview/channel-ico.png');">
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>