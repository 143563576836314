import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService, ConstantsService } from 'src/app/@core/utils';

@Component({
  selector: 'ngx-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  bcLoadedData: any = [];
  @Input() dynamicColor: string = '';
  hasEnCulture: boolean;
  isTileview: boolean = false;
  domainUrl: string = '';

  constructor(private router: Router, private commonService: CommonService, private constantsService: ConstantsService, private activatedRoute: ActivatedRoute) {
    this.hasEnCulture = this.commonService.getLanguage() === 'en';
    if (this.dynamicColor == '')
      this.dynamicColor = '#3F9FFF';
    const breadcrumbs = this.router.url.split('/').filter(data => data)
    this.isTileview = this.commonService.getUserView();
    this.domainUrl = this.isTileview ? '/home/tileview/' : '/home/';
    if (breadcrumbs)
      this.filterBreadcrumb(breadcrumbs);
  }

  ngOnInit(): void { }

  ngOnDestroy() {}

  filterBreadcrumb(breadcrumbs: any = []) {
    this.bcLoadedData = [];
    let titles = this.constantsService.validationResult.breadcrumb.find((x: any) => x.code == breadcrumbs[0]);
    const code = this.commonService.getLanguage();
    let title: any;
    breadcrumbs.forEach((data: any) => {
      if (data != breadcrumbs[0])
        titles = titles?.children?.find((x: any) => x.code == data);
      if (titles) {
        const breadcrumbTitle = titles.message.find((x: any) => x.languageCode === code);
        this.bcLoadedData.push({ text: breadcrumbTitle.text, code: data, routerUrl: `${this.domainUrl}/${titles.routerUrl ? titles.routerUrl : data}` });
        title = titles;
      }
      else
        titles = title;
    });
    const albumName = this.activatedRoute.snapshot.paramMap.get('groupName');
    if (albumName && albumName != '')
      this.bcLoadedData.push({ text: decodeURIComponent(albumName), code: albumName });
  }
}
