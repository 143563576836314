<div class="row">
   <div class="col-sm-2 col-1"></div>
   <div class="col-sm-8  col-10">
      <nb-card>
         <nb-card-header class="d-flex justify-content-between">
            <span class="iconCenter">
               <nb-icon class="iconOutletColor" icon="bell-outline"></nb-icon>
               <span i18n="@@notification.title">Notification</span>
            </span>
            <a href="javascript:void(0)" (click)="closePopup() ">
               <nb-icon icon="close-outline" style="font-size:x-large"></nb-icon>
            </a>
         </nb-card-header>
         <nb-card-body>
            <div class="row nb-body-height">
               <div class="col-lg-3 col-sm-6 mb-2">
                  <nb-card class="nb-card-height">
                     <nb-card-header class="d-flex justify-content-between">
                        <span i18n="@@notification.youHave">
                           You Have</span>

                     </nb-card-header>
                     <nb-card-body class="p-2 body-height">
                        <span> <b style="color:red">{{prescribedEducationCount}}</b><span i18n="@@notification.prescribedVideo"> Prescribed Health Education Video(s) to view. Would you like to start watching the video(s)?
                           </span></span>
                     </nb-card-body>
                     <nb-card-footer class="d-flex justify-content-center">
                        <button nbButton status="success" size="small" (click)="closePopup()"
                           [routerLink]="['/home/prescribed-video']" i18n="@@notification.viewNow">VIEW NOW</button>
                     </nb-card-footer>
                  </nb-card>
               </div>
               <div class="col-lg-3 col-sm-6 mb-2">
                  <nb-card class="nb-card-height">
                     <nb-card-header class="d-flex justify-content-between">
                        <span i18n="@@notification.youHave">
                           You Have</span>

                     </nb-card-header>
                     <nb-card-body class="p-2 body-height">
                        <span> <b style="color:red">{{prescribedDocumentCount}}</b><span i18n="@@notification.document"> Prescribed PDF Document(s) to read. Would you like to start reading the document(s)?</span></span>
                     </nb-card-body>
                     <nb-card-footer class="d-flex justify-content-center">
                        <button nbButton status="success" (click)="closePopup()" [routerLink]="['/home/document']"
                           size="small" i18n="@@notification.readNow">READ NOW</button>
                     </nb-card-footer>
                  </nb-card>
               </div>
               <div class="col-lg-3 col-sm-6 mb-2">
                  <nb-card class="nb-card-height">
                     <nb-card-header class="d-flex justify-content-between">
                        <span i18n="@@notification.youHave">
                           You Have</span>

                     </nb-card-header>
                     <nb-card-body class="p-2 body-height">
                        <span> <b style="color:red">{{prescribedSurveyCount}}</b><span i18n="@@notification.survey">  Prescribed Satisfaction Survey(s) to complete. Would you like to start completing the survey(s)?</span></span>
                     </nb-card-body>
                     <nb-card-footer class="d-flex justify-content-center">
                        <button nbButton status="success" size="small" (click)="closePopup()"
                           [routerLink]="['/home/survey']" i18n="@@notification.answerNow">ANSWER NOW</button>
                     </nb-card-footer>
                  </nb-card>
               </div>
               <div class="col-lg-3 col-sm-6 mb-2">
                  <nb-card class="nb-card-height">
                     <nb-card-header class="d-flex justify-content-between">
                        <span i18n="@@notification.youHave">
                           You Have</span>

                     </nb-card-header>
                     <nb-card-body class="p-2 body-height">
                        <span>
                           <b style="color:red">{{followUpQuestionnaireCount}}</b><span i18n="@@notification.followUp"> Prescribed Video Follow-Up Questionnaire(s) to complete. Would you like to start completing the questionnaire(s)?</span></span>
                     </nb-card-body>
                     <nb-card-footer class="d-flex justify-content-center">
                        <button nbButton status="success" (click)="closePopup()" size="small"
                           [routerLink]="['/home/followup']" i18n="@@notification.answerNow" style="text-align: center;">ANSWER NOW</button>
                     </nb-card-footer>
                  </nb-card>
               </div>

            </div>
         </nb-card-body>
      </nb-card>
   </div>
   <div class="col-sm-2 col-1"></div>
</div>