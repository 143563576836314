import { Component, OnInit } from '@angular/core';
import { CommonService } from '../@core/utils';

@Component({
  selector: 'app-my-loader',
  templateUrl: './my-loader.component.html',
  styleUrls: ['./my-loader.component.scss']
})
export class MyLoaderComponent implements OnInit {
  hasEnCulture:boolean;
  
  constructor(private commonService:CommonService) {
    this.hasEnCulture = this.commonService.getLanguage() === 'en';
   }

  ngOnInit(): void {
  }

}
