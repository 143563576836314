<div class="row" *ngIf="!isForCarousel">
   <ng-container [ngTemplateOutlet]="patientInfoNoRecords" [ngTemplateOutletContext]="{patientInfos:patientInfos}"></ng-container>
   <div class="grid-lg-width col-md-3 col-sm-4 col-6" *ngFor="let patientInfo of patientInfos" [hidden]="patientInfos.length==0">
       <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{patientInfo:patientInfo}"></ng-container>
   </div>
</div>

<div class="carousel" *ngIf="isForCarousel">
   <ng-container [ngTemplateOutlet]="patientInfoNoRecords" [ngTemplateOutletContext]="{patientInfos:patientInfos}"></ng-container>
   <owl-carousel-o [options]="customOptions" *ngIf="patientInfos.length>0">
       <ng-container *ngFor="let patientInfo of patientInfos">
           <ng-template carouselSlide [width]="170" [id]="patientInfo.title">
               <div class="type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                   <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{patientInfo:patientInfo}"></ng-container>
               </div>
           </ng-template>
       </ng-container>
   </owl-carousel-o>
</div>

<ng-template #patientInfoNoRecords let-movie='patientInfos'>
   <strong *ngIf="patientInfos?.length==0" i18n="@@patientInfoCard.noPatientInfo">No Patient-Info to display!</strong>
</ng-template>

<ng-template #cardRef let-patientInfo='patientInfo'>
   <div class="gen-carousel-movies-style-2 movie-grid style-2">
      <div class="gen-movie-contain">
         <div class="gen-movie-img">
            <img  [src]="patientInfo.thumbnailUrl" alt="owl-carousel-video-image">
            <ngx-icons></ngx-icons>
            <div class="gen-movie-action">
               <a href="javascript:void(0)" [routerLink]="patientInfo.url" class="gen-button">
                  <i class="fa fa-play"></i>
               </a>
            </div>
         </div>
         <div class="gen-info-contain">
            <div class="gen-movie-info">
               <h3><a href="javascript:void(0)" [routerLink]="patientInfo.url">{{patientInfo.title}}</a>
               </h3>
            </div>
            <div class="gen-movie-meta-holder">
               <ul>
                  <li>
                     <a href="javascript:void(0)"><span style="cursor: default;">{{patientInfo.description}}</span></a>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</ng-template>