<div class="row" *ngIf="!isForCarousel">
   <ng-container [ngTemplateOutlet]="kidsNoRecords" [ngTemplateOutletContext]="{kidsVideos:kidsVideos}"></ng-container>
   <div class="grid-lg-width col-md-3 col-sm-4 col-6" *ngFor="let kidsVideo of kidsVideos" [hidden]="kidsVideos.length==0">
       <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{kidsVideo:kidsVideo}"></ng-container>
   </div>
</div>

<div class="carousel" *ngIf="isForCarousel">
   <ng-container [ngTemplateOutlet]="kidsNoRecords" [ngTemplateOutletContext]="{kidsVideos:kidsVideos}"></ng-container>
   <owl-carousel-o [options]="customOptions" *ngIf="kidsVideos.length>0">
       <ng-container *ngFor="let kidsVideo of kidsVideos">
           <ng-template carouselSlide [width]="170">
               <div class="kids type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                   <ng-container [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{kidsVideo:kidsVideo}"></ng-container>
               </div>
           </ng-template>
       </ng-container>
   </owl-carousel-o>
</div>

<ng-template #kidsNoRecords let-movie='kidsVideos'>
   <strong *ngIf="kidsVideos?.length==0" i18n="@@kids-card.noKids">No KIDS to display!</strong>
</ng-template>

<ng-template #cardRef let-kidsVideo='kidsVideo'>
   <div class="gen-carousel-movies-style-2 movie-grid style-2">
      <div class="gen-movie-contain">
         <div class="gen-movie-img">
            <img  [src]="kidsVideo?.thumbnailName?movieBlobStorage+kidsVideo?.thumbnailName:defaultImagePath"alt="owl-carousel">
            <ngx-icons [video]="kidsVideo" [patientPlaylistItem]="patientPlaylistItem" (newPlayList)="getUpdatedPlayList($event)"></ngx-icons>
            <div class="gen-movie-action">
               <a href="javascript:void(0)" (click)="watchMovie(kidsVideo)" class="gen-button">
                  <i class="fa fa-play"></i>
               </a>
            </div>
         </div>
         <div class="gen-info-contain">
            <div class="gen-movie-info">
               <h3><a href="javascript:void(0)" (click)="watchMovie(kidsVideo)">{{kidsVideo.title}}</a>
               </h3>
            </div>
            <div class="gen-movie-meta-holder">
               <ul>
                  <li>{{kidsVideo.duration|timing}}</li>
                  <li *ngIf="kidsVideo?.genreNames!=undefined && kidsVideo?.genreNames!=null">
                     <a href="javascript:void(0)"><span class="kidscolor" style="cursor: default;">{{kidsVideo.genreNames}}</span></a>
                  </li>
               </ul>
               <br>
               <div
               style="width:70px;height:39px;background-image: url('../../../assets/images/preview/channel-ico.png');">
           </div>
               <!-- <div [style.background-image]="'url('+kidsVideo.iconImage+')'" style="width:70px;height:39px">
               </div> -->
            </div>
         </div>
      </div>
   </div>
</ng-template>