<div class="gen-pagination">
        <nav aria-label="Page navigation" class="row">
                <ul class="page-numbers col-xl-10 col-lg-9 col-md-9 col-sm-12 col-12" style="float:left;">
                        <li><a class="next page-numbers" *ngIf="activePage>1"
                                        (click)="gotoBack(activePage)"><nb-icon
                                                class="fa fa-arrow-left font-size"></nb-icon></a></li>
                        <li *ngFor="let item of pages"><a [ngClass]="{'current': activePage === item}"
                                        (click)="onClickPage(item)" class="page-numbers font-size"
                                        href="javascript:void(0)">{{item}}</a></li>
                        <span *ngIf="totalPages>4 && this.totalPages>paginationSize" class="page-numbers">......
                                <li><a class="page-numbers font-size" href="javascript:void(0)"
                                                [ngClass]="{'current': activePage === totalPages}"
                                                (click)="gotoLastPage(totalPages)">{{totalPages}}</a></li>
                        </span>
                        <li *ngIf='totalPages > activePage'><a class="next page-numbers" (click)="gotoNext(activePage)"
                                        ><nb-icon
                                                class="fa fa-arrow-right font-size"></nb-icon></a></li>
                </ul>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-2" *ngIf="pages.length>0">
                        <span>{{activePage==1?activePage:(activePage-1)*20}} - {{totaItems>(activePage*20)?
                                activePage*20:totaItems}} of {{totaItems}} items</span></div>
        </nav>
</div>