import { Injectable } from '@angular/core';
import { ApiUrlService, CommonService } from '../utils';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { FacilityData } from '../data/facility';
import { FacilityAlert } from '../data/facilityAlert';
import { FacilityTicker } from '../data/facilityTicker';
import { ToasterService } from '../utils/toaster.service';
import { ConciergeService } from '../data/conciergeService';

@Injectable({
  providedIn: 'root'
})
export class FacilityService implements FacilityData {
  hasEnCulture: boolean = false;
  constructor(private apiUrlService: ApiUrlService, private httpClient: HttpClient, private commonService: CommonService, private toasterService: ToasterService) {
  }

  getFacilities(): Observable<any> {
    const url = `${this.apiUrlService.getFacilityUrl}/byod-facilities`
    return this.httpClient.get(url)
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError<any[]>('getFacilities'))
      );

  }

  getFacilityLogo(): Observable<any> {
    const ceFacilityId = this.commonService.getLSKey('ceFacilityId');
    const url = `${this.apiUrlService.getFacilityUrl}/facilities/${ceFacilityId}/logo`;
    return this.httpClient.get(url, { responseType: 'text' })
      .pipe(
        map((returnData: any) => {
          return returnData;
        }),
        catchError(this.handleError<any>('getFacilityLogo'))
      );

  }

  getActivatedFacilityTickers(facilityId: number): Observable<FacilityTicker> {
    const url = `${this.apiUrlService.getFacilityUrl}/facilities/${facilityId}/activated-facility-tickers`;
    return this.httpClient.get<FacilityTicker>(url).pipe(
      map((returnData: FacilityTicker) => {
        this.hasEnCulture = this.commonService.getLanguage() === 'en';
        if (returnData) {
          let currentTime: any = new Date();
          let tickerMessages = returnData.tickerMessages.filter((x: any) => {
            let expireDate: any = new Date(x.expireDate);
            if (x.enabled && ((expireDate - currentTime)) / (1000 * 60) > 0) {
              x.displayMessage = (!this.hasEnCulture && x.messageFr != null) ? x.messageFr : x.message;
              return x;
            }
          });
          tickerMessages = tickerMessages.sort((a: any, b: any) => (Number((a.displayPriority)) < Number((b.displayPriority))) ? 1 : -1);
          returnData.tickerMessages = tickerMessages;
        }
        return returnData
      }),
      catchError(this.handleError<any>(`getActivatedFacilityTickers`)))
  }

  getActivatedFacilityAlerts(facilityId: number): Observable<FacilityAlert> {
    const url = `${this.apiUrlService.getFacilityUrl}/facilities/${facilityId}/activated-facility-alerts`;
    return this.httpClient.get<FacilityAlert>(url).pipe(
      map((returnData: FacilityAlert) => {
        return returnData
      }),
      catchError(this.handleError<FacilityAlert>(`getActivatedFacilityAlerts`)))
  }

  getAdvertisementForFacility(facilityCode: string): Observable<any> {
    const url = `${this.apiUrlService.advertisementUrl}/facilities/${facilityCode}/ad-orders?platform=HOPIFLIX`;
    return this.httpClient.get<any>(url).pipe(
      map((returnData: any) => {
        return returnData
      }),
      catchError(this.handleError<any>(`getAdvertisementForFacility`)))
  }

  getInteractiveWhiteboardsForFacility(facilityId: number, cardNumber: string): Observable<any> {
    const url = `${this.apiUrlService.hospitalInfoUrl}/facilities/${facilityId}/alive-interactive-whiteboards/${cardNumber}`;
    return this.httpClient.get<any>(url).pipe(
      map((returnData: any) => {
        returnData?.sections.map((x: any) => x.templatePayload =this.isValidJSON(x.templatePayload)? JSON.parse(x.templatePayload):null)
        returnData?.sections
          .filter((x: any) => x.templateName == 'HealthcareTeam_Template1' && x.templatePayload)
          .map((section: any) => section.templatePayload.HealthcareTeamMembers.sort((a: any, b: any) => (a.DisplayPriority < b.DisplayPriority) ? 1 : -1));
        returnData.sections = returnData?.sections.sort((a: any, b: any) => (a.displayPriority < b.displayPriority) ? 1 : -1);
        return returnData
      }),
      catchError(this.handleError<any>(`getInteractiveWhiteboardsForFacility`)))
  }

  setGeneralCommentForWhiteboard(data: any): Observable<any> {
    const url = `${this.apiUrlService.hospitalInfoUrl}/interactive-whiteboards/general-comments`;
    return this.httpClient.post<any>(url, data).pipe(
      map((returnData: any) => {
        if (returnData) {
          this.toasterService.showSuccessMessage('RequestSuccess');
        }
        return returnData
      }),
      catchError(this.handleError<any>(`setGenralCommentForWhiteboard`)))
  }

  setConciergeRequest(data: any): Observable<any> {
    let url = `${this.apiUrlService.hospitalInfoUrl}/interactive-whiteboards/concierge-requests`;
    return this.httpClient.post<any>(url, data, { observe: 'response' }).pipe(
      map((returnData: any) => {
        if (returnData.status == '201') {
          this.toasterService.showSuccessMessage('RequestSuccess');
        }
        return returnData
      }),
      catchError(this.handleError<any>(`setConciergeRequest`)))
  }

  setPainScaleSubmition(data: any): Observable<any> {
    const url = `${this.apiUrlService.hospitalInfoUrl}/interactive-whiteboards/pain-scale-submitions`;
    return this.httpClient.post<any>(url, data).pipe(
      map((returnData: any) => {
        if (returnData) {
          this.toasterService.showSuccessMessage('RequestSuccess');
        }
        return returnData
      }),
      catchError(this.handleError<any>(`setPainScaleSubmition`)))
  }

  getConciergeService(facilityId: number): Observable<Array<ConciergeService>> {
    const url = `${this.apiUrlService.hospitalInfoUrl}/facilities/${facilityId}/interactive-whiteboards/concierge-services`;
    return this.httpClient.get<Array<ConciergeService>>(url).pipe(
      map((returnData: Array<ConciergeService>) => {
        returnData = returnData?.sort((a: any, b: any) => (Number((a.displayPriority)) < Number((b.displayPriority))) ? 1 : -1);
        return returnData
      }),
      catchError(this.handleError<Array<ConciergeService>>(`getConciergeService`)))
  }

  private isValidJSON(str:any) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
