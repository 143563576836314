import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'uri'
})
export class UriPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }

  transform(value: string) {
    var url = "https://www.youtube.com/embed/" + value + "?autoplay=0&showinfo=0&controls=0&modestbranding=1";//+"?controls=1autoplay=1&modestbranding=0";
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
