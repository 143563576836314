import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { NbDialogRef } from '@nebular/theme';
import { RentalService } from 'src/app/@core/services/rental.service';
import { CommonService } from 'src/app/@core/utils';
import { NotificationsComponent } from '../notifications/notifications.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-sevice',
  templateUrl: './my-sevice.component.html',
  styleUrls: ['./my-sevice.component.scss']
})
export class MySeviceComponent implements OnInit {
  rentedServices: Array<any> = [];
  hasEnCulture: boolean;

  constructor(private rentalService: RentalService, private commonService: CommonService,
    private authService: MsalService, private dialogRef: NbDialogRef<NotificationsComponent>, private router: Router) {
    this.hasEnCulture = this.commonService.getLanguage() === 'en';
    this.getRentedServices();
  }

  ngOnInit(): void {
  }

  getRentedServices() {
    const facilityId = this.commonService.getLSKey("ceFacilityId");
    const userName = this.authService.instance.getAllAccounts()[0]?.username;
    this.rentalService.getRentedServices(facilityId, userName).subscribe(response => {
      this.rentedServices = []
      if (response)
        this.rentedServices = response;
    })
  };

  closePopup() {
    this.dialogRef.close()
  }

  openRentalMenu() {
    this.closePopup();
    this.router.navigate(['/home/rental'])
  }
}
