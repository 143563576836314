import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiUrlService } from '../utils/api-url.service';
import { MsalService } from '@azure/msal-angular';
import { CommonService } from '../utils';
import { PatientPlaylist, PatientPlaylistData } from '../data/patientPlaylist';

@Injectable({
  providedIn: 'root'
})
export class PatientPlaylistService implements PatientPlaylistData{

  constructor(private httpClient: HttpClient, private apiUrlService: ApiUrlService,
    private authService: MsalService, private commonService: CommonService) { }

  getPatientPlaylist(): Observable<PatientPlaylist> {
    const account = this.authService.instance.getAllAccounts()[0];
    const email: string = account.username; //"gurpreet.singh@mailinator.com" 
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorPrescription}/PatientPlaylist/get/${email}`;
    return this.httpClient.get<PatientPlaylist>(url)
      .pipe(
        map((returnData: PatientPlaylist) => {
          this.commonService.setLSKey('patientPlaylist', JSON.stringify(returnData));
          return returnData;
        }),
        catchError(this.handleError<PatientPlaylist>(`getPatientPlaylist`))
      );
  }

  createPatientPlaylist(patientPlaylistItem: any): Observable<boolean> {
    const account = this.authService.instance.getAllAccounts()[0];
    patientPlaylistItem.userName = account.username; //"gurpreet.singh@mailinator.com" 
    patientPlaylistItem.ceFacilityId = this.commonService.getLSKey("ceFacilityId");
    const url = `${this.apiUrlService.phoenixUrl}${this.apiUrlService.ocelotSelectorPrescription}/patientPlaylist/createReplace`;
    return this.httpClient.post<boolean>(url, patientPlaylistItem)
      .pipe(
        map((returnData: boolean) => {
          return returnData;
        }),
        catchError(this.handleError<boolean>('createPatientPlaylist'))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message} `);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
