import { Directive, Input, ElementRef, OnInit } from '@angular/core';

declare var streamlabCore: any;
@Directive({
  selector: '[appCarouselDirective]',
})
export class CarouselDirective implements OnInit {
  @Input('last') last: boolean = false;
  elmRef: ElementRef<any>;

  constructor(public elementRef: ElementRef) {
    this.elmRef = this.elementRef;
  }

  ngOnInit() {
    if (this.last) {
        const element = this.elmRef.nativeElement.parentElement.parentElement.parentNode;
        streamlabCore.owlCarousel(element);
    }
  }
}
